import React from 'react';
import type * as Polymorphic from '@radix-ui/react-polymorphic';
import { styled, resolveTokens } from '@fis/util-theme';

const NAME = 'ResponsiveGrid';

/* --------------------------------------------------------------------------------------------
 * OverflowContext
 * ------------------------------------------------------------------------------------------*/
interface IOverflowContext {
  hidden: boolean;
  toggleHidden: () => void;
}

const OVERFLOW_CONTEXT_DEFAULT: IOverflowContext = {
  hidden: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleHidden: () => {},
};

const OverflowContext = React.createContext(OVERFLOW_CONTEXT_DEFAULT);
OverflowContext.displayName = 'ResponsiveGrid.OverflowContext';

/* --------------------------------------------------------------------------------------------
 * StyledSeeMore
 * ------------------------------------------------------------------------------------------*/

const StyledResponsiveGridOverflowToggle = styled('button', {
  background: 'transparent',
  border: 'none',
  color: '$gray200',
  cursor: 'pointer',
  fontFamily: '$firaCode',
  justifySelf: 'start',
});

StyledResponsiveGridOverflowToggle.displayName =
  'ResponsiveGrid.StyledOverflowToggle';

/* --------------------------------------------------------------------------------------------
 * ResponsiveGridOverflowToggle
 * ------------------------------------------------------------------------------------------*/

export const ResponsiveGridOverflowToggle = React.forwardRef(
  (props, forwardedRef) => {
    const { hidden, toggleHidden } = React.useContext(OverflowContext);

    return (
      <StyledResponsiveGridOverflowToggle
        onClick={toggleHidden}
        ref={forwardedRef}
        {...props}
      >
        {hidden ? 'See More' : 'See Less'}
      </StyledResponsiveGridOverflowToggle>
    );
  }
) as Polymorphic.ForwardRefComponent<
  'button',
  Polymorphic.OwnProps<typeof StyledResponsiveGridOverflowToggle>
>;

/* --------------------------------------------------------------------------------------------
 * StyledResponsiveGridOverflow
 * ------------------------------------------------------------------------------------------*/

const StyledResponsiveGridOverflow = styled('div', {
  display: 'grid',
  gap: '$7',
  position: 'relative',

  variants: {
    hidden: {
      true: {
        '&:after': {
          backgroundImage:
            'linear-gradient(to right, transparent, $colors$gray900);',
          content: ' ',
          display: 'block',
          height: '100%',
          position: 'absolute',
          right: 0,
          width: '1.5rem',
          zIndex: '$aboveStdElements',
        },
      },
    },
  },
});

StyledResponsiveGridOverflow.displayName = 'ResponsiveGrid.StyledOverflow';

/* --------------------------------------------------------------------------------------------
 * ResponsiveGridOverflow
 * ------------------------------------------------------------------------------------------*/

export const ResponsiveGridOverflow = React.forwardRef(
  ({ children, ...rest }, forwardedRef) => {
    const [hidden, setHidden] = React.useState(true);
    const toggleHidden = () => setHidden(!hidden);

    return (
      <OverflowContext.Provider value={{ hidden, toggleHidden }}>
        <StyledResponsiveGridOverflow
          ref={forwardedRef}
          hidden={hidden}
          {...rest}
        >
          {children}
        </StyledResponsiveGridOverflow>
      </OverflowContext.Provider>
    );
  }
) as Polymorphic.ForwardRefComponent<
  'div',
  Polymorphic.OwnProps<typeof StyledResponsiveGridOverflow>
>;

/* --------------------------------------------------------------------------------------------
 * Container
 * ------------------------------------------------------------------------------------------*/

const Container = styled('div', {
  display: 'grid',
});

Container.displayName = 'ResponsiveGridContainer';

/* --------------------------------------------------------------------------------------------
 * ResponsiveGrid
 * ------------------------------------------------------------------------------------------*/

export const ResponsiveGrid = React.forwardRef(
  ({ children, itemWidth, min, max = '1fr', gap = '$8', ...rest }, ref) => {
    const { hidden } = React.useContext(OverflowContext);

    const overflowOpts = hidden
      ? {
          overflow: 'hidden',
          gridAutoColumns: 'max-content',
          gridAutoFlow: 'column',
        }
      : {};

    return (
      <Container
        {...rest}
        css={{
          gap,
          gridTemplateColumns:
            min || itemWidth
              ? `repeat(auto-fit, minmax(${min || itemWidth}, ${
                  itemWidth || max
                }))`
              : max,
          ...overflowOpts,
        }}
        ref={ref}
      >
        {children}
      </Container>
    );
  }
) as Polymorphic.ForwardRefComponent<
  'div',
  Polymorphic.OwnProps<typeof Container> & {
    readonly min?: resolveTokens<'space'> | string;
    readonly max?: resolveTokens<'space'> | string;
    readonly itemWidth?: resolveTokens<'space'> | string;
    readonly gap?: resolveTokens<'space'> | 0;
  }
>;

ResponsiveGrid.displayName = NAME;
