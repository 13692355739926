import { ReactNode } from 'react';
import { X } from 'phosphor-react';

import { styled } from '@fis/util-theme';
import { Button } from '@fis/ui-buttons';

import { PrimaryNavLink, SecondaryNavLink } from '../types';
import { NavLink } from './ui-nav-link';
import { SubMenuItem } from './ui-header-dropdown';
import { LogOutMenuItem } from './ui-logout-menu-item';

const MobileNavMenuContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$7',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: '$gray900',
  padding: '$9',
  zIndex: '$modal',

  variants: {
    hidden: {
      false: {
        display: 'flex',
      },
      true: {
        display: 'none',
      },
    },
  },
});

const MobileNavHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const MobileNavLinkGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$5',
});

const XButton = styled(Button, {
  marginLeft: 'auto',
});

type MobileNavMenuProps = {
  appLogo: ReactNode;
  primaryNavLinks: PrimaryNavLink[];
  secondaryNavLinks?: SecondaryNavLink[];
  hidden: boolean;
  toggleMobileMenu: () => void;
};

export const MobileNavMenu = (props: MobileNavMenuProps) => {
  const {
    appLogo,
    primaryNavLinks,
    secondaryNavLinks,
    hidden,
    toggleMobileMenu,
  } = props;

  return (
    <MobileNavMenuContainer aria-label="mobile menu" hidden={hidden}>
      <MobileNavHeader>
        {appLogo}
        <XButton secondary onClick={toggleMobileMenu}>
          <X size={20} />
        </XButton>
      </MobileNavHeader>
      <MobileNavLinkGroup aria-label="mobile navigation links">
        {primaryNavLinks.map((primaryLink) => {
          return (
            <NavLink
              key={primaryLink.label}
              path={primaryLink.path}
              title={primaryLink.title}
              label={primaryLink.label}
            />
          );
        })}
        {secondaryNavLinks &&
          secondaryNavLinks.map((secondaryLink) => {
            return (
              <SubMenuItem
                key={`submenu-item-${secondaryLink.title}`}
                {...secondaryLink}
              />
            );
          })}
        <LogOutMenuItem />
      </MobileNavLinkGroup>
    </MobileNavMenuContainer>
  );
};
