import { ReactNode } from 'react';
import { List } from 'phosphor-react';

import { styled } from '@fis/util-theme';
import { Button } from '@fis/ui-buttons';

import { PrimaryNavLinks } from './components';
import { ProfileMenu } from './components/ui-profile-menu';
import { MobileNavMenu } from './components/ui-mobile-nav-menu';
import { PrimaryNavLink, SecondaryNavLink } from './types';

const HeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '5.5rem',
  gap: '$11',
  padding: '$9',
  width: '100%',
});

const HamburgerMenu = styled(Button, {
  display: 'none',
  marginLeft: 'auto',

  '@md': {
    display: 'flex',
  },
});

interface UserProps {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
}

interface HeaderProps {
  appLogo: ReactNode;
  user: UserProps;
  primaryNavLinks: PrimaryNavLink[];
  secondaryNavLinks?: SecondaryNavLink[];
  showMobileMenu?: boolean;
  toggleOverlay: () => void;
}

export function UiHeader(props: HeaderProps) {
  const {
    appLogo,
    user,
    primaryNavLinks,
    secondaryNavLinks,
    showMobileMenu,
    toggleOverlay,
  } = props;

  const closeMobileMenu = () => {
    toggleOverlay();
  };

  return (
    <HeaderContainer data-testid="feature-header">
      {!showMobileMenu ? appLogo : null}
      <PrimaryNavLinks primaryNavLinks={primaryNavLinks} />
      <ProfileMenu
        data-testid="profile-menu"
        user={user}
        dropdownMenuItems={secondaryNavLinks}
      />
      <HamburgerMenu
        aria-label="hamburger menu icon"
        secondary
        onClick={toggleOverlay}
      >
        <List size={20} />
      </HamburgerMenu>
      {showMobileMenu ? (
        <MobileNavMenu
          appLogo={appLogo}
          primaryNavLinks={primaryNavLinks}
          secondaryNavLinks={secondaryNavLinks}
          hidden={!showMobileMenu}
          toggleMobileMenu={closeMobileMenu}
        />
      ) : null}
    </HeaderContainer>
  );
}

export default UiHeader;
