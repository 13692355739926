import { styled } from '@fis/util-theme';
import { CaretLeft, BookmarkSimple } from 'phosphor-react';
import Link from 'next/link';

export const Button = styled('button', {
  fontFamily: '$firaSans',
  borderRadius: '$2',
  backgroundColor: '$blue800',
  border: 'none',
  color: '$blue200',
  cursor: 'pointer',
  lineHeight: '1.25rem',
  fontSize: '$20',
  display: 'flex',
  gap: '.5rem',
  padding: '.5rem .75rem',
  textDecoration: 'none',
  alignItems: 'center',
  transitionDuration: '150ms',
  maxWidth: 'max-content',
  minWidth: 'max-content',

  '&:focus': {
    background: '$lightblue700',
    boxShadow: '$focusRing',
    outline: 'none',
  },

  // sets focus style for webkit browsers
  '@supports selector(:focus-visible)': {
    // negates :focus rule for browsers that do not support :focus-visible selector
    '&:focus': {
      background: '$blue800',
      boxShadow: 'none',
    },

    '&:focus-visible': {
      background: '$lightblue700',
      boxShadow: '$focusRing',
      outline: 'none',
    },
  },

  '&:hover': {
    background: '$lightblue700',
    color: '$lightblue100',
  },

  '&:active': {
    background: '$lightblue600',
    transitionDuration: '0ms',
  },

  '.lightMode &': {
    backgroundColor: '$lightblue600',
    color: '$blue200',

    '&:focus': {
      background: '$blue800',
      boxShadow: '$focusRing',
      outline: 'none',
    },

    // sets focus style for webkit browsers
    '@supports selector(:focus-visible)': {
      // negates :focus rule for browsers that do not support :focus-visible selector
      '&:focus': {
        background: '$blue800',
        boxShadow: 'none',
      },

      '&:focus-visible': {
        background: '$blue800',
        boxShadow: '$focusRing',
        outline: 'none',
      },
    },

    '&:hover': {
      background: '$blue800',
      color: '$lightblue100',
    },

    '&:active': {
      background: '$lightblue600',
      transitionDuration: '0ms',
    },
  },

  variants: {
    disable: {
      true: {
        backgroundColor: '$gray700',
        color: '$gray500',
        cursor: 'default',

        '&:hover': {
          backgroundColor: '$gray700',
          color: '$gray500',
        },
      },
    },
    secondary: {
      true: {
        backgroundColor: '$tintDark8',
        color: '$lightblue300',

        '&:focus': {
          background: '$tintDark16',
          boxShadow: '$focusRing',
          outline: 'none',
          color: '$lightblue200',
        },

        // sets focus style for webkit browsers
        '@supports selector(:focus-visible)': {
          // negates :focus rule for browsers that do not support :focus-visible selector
          '&:focus': {
            background: '$tintDark8',
            boxShadow: 'none',
            color: '$lightblue200',
          },

          // sets focus style for webkit browsers
          '&:focus-visible': {
            background: '$tintDark16',
            boxShadow: '$focusRing',
            outline: 'none',
            color: '$lightblue200',
          },
        },

        '&:hover': {
          backgroundColor: '$tintDark16',
          color: '$lightblue200',
        },

        '&:active': {
          backgroundColor: '$gray700',
          color: '$blue600',
          transitionDuration: '0ms',
        },

        '.lightMode &': {
          backgroundColor: '$tintLight8',
          color: '$blue700',

          '&:focus': {
            background: '$tintLight16',
            boxShadow: '$focusRing',
          },

          '@supports selector(:focus-visible)': {
            // negates :focus rule for browsers that do not support :focus-visible selector
            '&:focus': {
              background: '$tintLight8',
              boxShadow: 'none',
            },

            // sets focus style for webkit browsers
            '&:focus-visible': {
              background: '$tintLight16',
              boxShadow: '$focusRing',
            },
          },

          '&:hover': {
            backgroundColor: '$tintLight16',
            color: '$blue800',
          },

          '&:active': {
            backgroundColor: '$lightblue300',
            color: '$blue600',
          },
        },
      },
    },
    btnType: {
      dark: {
        backgroundColor: '$gray900',
        color: 'white',
      },
      radio: {
        alignItems: 'middle',
        display: 'grid',
        gridGap: '$3',
        gridTemplateColumns: '1fr max-content',
        paddingRight: '$5',

        '& label': {
          cursor: 'pointer',
        },
      },
      text: {
        fontSize: '$30',
        background: 'transparent',
        height: '$9',
        border: 'none',
        borderBottom: '1px solid white',
        borderRadius: '0',
        display: 'inline',
        color: 'white',
        padding: '0',
        '&:hover': {
          cursor: 'pointer',
          borderBottom: '1px solid #F9C51A',
          backgroundColor: '#F9C51A', // TODO: make this a theme variable (main yellow isn't in our theme)
          color: '$gray900',
          transition: '150ms ease-in',
        },
      },
      circle: {
        display: 'flex',
        justifyContent: 'center',
        width: '32px',
        height: '32px',
        padding: 0,
        borderRadius: '100%',
      },
      square: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '$2',
        padding: '10px',
      },
    },
    hasIcon: {
      left: {
        alignItems: 'center',
        padding: '6px 12px 6px 8px',
      },
      right: {
        alignItems: 'center',
        padding: '6px 8px 6px 12px',
      },
      iconOnly: {
        padding: '10px',
      },
    },
    bookmark: {
      true: {
        '&.bookmarked': {
          color: '$yellow500',
          background: '$tintDark16',

          '.lightMode &': {
            color: '$yellow600',
            background: '$tintLight16',
          },
        },
      },
    },
    size: {
      xs: {
        padding: '$3',
      },
      medium: {
        padding: '.625rem 1rem',
      },
      lg: {
        padding: '.875rem 1.5rem',
      },
    },
    fullWidth: {
      true: {
        maxWidth: '100%',
        width: '100%',
        justifyContent: 'center',
      },
    },
  },

  compoundVariants: [
    {
      secondary: true,
      btnType: 'bookmark',
      css: {
        backgroundColor: '$tintDark8',
      },
    },
    {
      secondary: true,
      disable: true,
      css: {
        backgroundColor: '$tintDark16',
        color: '$gray500',
      },
    },
    {
      hasIcon: 'iconOnly',
      size: 'sm',
      css: {
        padding: '8px',
      },
    },
    {
      hasIcon: 'right',
      size: 'medium',
      css: {
        padding: '.625rem .75rem .625rem 1rem',
      },
    },
    {
      hasIcon: 'left',
      size: 'medium',
      css: {
        padding: '.625rem 1rem .625rem .75rem',
      },
    },
    {
      hasIcon: 'right',
      size: 'lg',
      css: {
        padding: '.875rem 1rem .875rem 1.5rem',
      },
    },
    {
      hasIcon: 'left',
      size: 'lg',
      css: {
        padding: '.875rem 1.5rem .875rem 1rem',
      },
    },
  ],
});

export const ButtonGroup = styled('div', {
  display: 'grid',
  gridGap: '$3',
  gridTemplateColumns: 'max-content max-content',
});

/* --------------------------------------------------------
 * Internal Link + Text + Icon Button
 * <IconButton buttonText={'View All Courses'} href={'/courses'} secondary>
 *    <ArrowRight size={16} />
 * </IconButton>
 * ----------------------------------------------------- */
type IconButtonProps = {
  buttonText?: string;
  href?: string;
  children: unknown;
  secondary?: boolean;
  orientation?: 'left' | 'right';
  onClick?: () => void;
};

export const IconButton = (props: IconButtonProps) => {
  const { buttonText, href, children, secondary, onClick, orientation } = props;
  return orientation === 'left' ? (
    <Link href={href ? href : ''} passHref>
      <Button
        data-testid="IconBtn"
        secondary={secondary}
        onClick={onClick}
        hasIcon="left"
      >
        {children}
        {buttonText}
      </Button>
    </Link>
  ) : (
    <Link href={href ? href : ''} passHref>
      <Button
        data-testid="IconBtn"
        onClick={onClick}
        secondary={secondary}
        hasIcon="right"
      >
        {buttonText}
        {children}
      </Button>
    </Link>
  );
};

/* --------------------------------------------------------
 * Back Button/Linked Button
 * these two buttons are stylistically identical, but one you can pass an onClick to and the other you can specify the link
 * example usage:
    <BackButton buttonText={'Test'} buttonClick={() => router.back()} />
    <LinkedButton buttonText={'Test'} href={'/events'} />
 * ------------------------------------------------------*/

export const BackButton = (props: {
  buttonText: string;
  buttonClick: () => void | undefined;
  secondary?: boolean;
}) => {
  const { buttonText, buttonClick, secondary } = props;

  return (
    <IconButton
      secondary={secondary}
      onClick={buttonClick}
      buttonText={buttonText}
      orientation="left"
    >
      <CaretLeft size="16" weight="bold" />
    </IconButton>
  );
};

/* This can be removed and use IconButton instead once we update
   all buttons in the monorepo to match the new design system OR
   we can rename since we're using it specifically to navigate between pages.
   Linked and IconButton are essentially identical, except the Icon button allows
   you to insert any icon for use
*/
export const LinkedButton = (props: { buttonText: string; href: string }) => {
  const { buttonText, href } = props;

  return (
    <Link href={href} passHref>
      <Button secondary>
        <CaretLeft size="16" weight="bold" />
        {buttonText}
      </Button>
    </Link>
  );
};

/* --------------------------------------------------------
 * Bookmark Button
 * ----------------------------------------------------- */

type BookmarkButtonProps = {
  isBookmarked: boolean;
  onClick: any;
};

export const BookmarkButton = (props: BookmarkButtonProps) => {
  const { isBookmarked, onClick } = props;

  return (
    <Button
      secondary
      hasIcon="iconOnly"
      bookmark
      className={isBookmarked ? 'bookmarked' : ''}
      onClick={onClick}
      data-testid="BookmarkBtn"
    >
      <BookmarkSimple size={20} weight={isBookmarked ? 'fill' : 'bold'} />
    </Button>
  );
};
