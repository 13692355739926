import { signOut } from 'next-auth/client';
import { SignOut } from 'phosphor-react';
import { theme } from '@fis/util-theme';

import { StyledSubMenuItem } from './ui-header-dropdown';

export const handleLogout = () =>
  signOut({ callbackUrl: `${process.env.PORTAL_ENDPOINT}/sign_out` });

export const LogOutMenuItem = () => {
  return (
    <StyledSubMenuItem
      rel="noreferrer"
      data-testid="item-path-logout"
      aria-label="Log Out"
      onClick={handleLogout}
    >
      <SignOut size={20} color={theme.colors.red300.toString()} />
      Logout
    </StyledSubMenuItem>
  );
};
