import { Educator } from '@fis/data-graphql';

export function lastEducatorWithAvailabilitySchedule(
  educators?: Partial<Educator>[]
): Partial<Educator> | null {
  if (!educators) {
    return null;
  }

  const lastEducatorWithAvailabilitySchedule = [...educators]
    .reverse()
    .find((educator) => educator.availabilityScheduleUrl);

  return lastEducatorWithAvailabilitySchedule || null;
}

export default lastEducatorWithAvailabilitySchedule;
