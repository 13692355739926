import * as RadixAvatar from '@radix-ui/react-avatar';
import { styled } from '@fis/util-theme';
import { formatInitials } from '@fis/ui-format';

const StyledAvatar = styled(RadixAvatar.Root, {
  alignItems: 'center',
  borderRadius: '2px',
  display: 'inline-flex',
  justifyContent: 'center',
  overflow: 'hidden',
  userSelect: 'none',
  verticalAlign: 'middle',

  variants: {
    size: {
      large: {
        height: '5rem',
        width: '5rem',
        minWidth: '5rem',
      },
      medium: {
        height: '3.125rem',
        width: '3.125rem',
        minWidth: '3.125rem',
      },
      small: {
        height: '2rem',
        width: '2rem',
        minWidth: '2rem',
      },
    },
    circular: {
      true: {
        borderRadius: '2.5rem',
        border: '1px solid $gray300',
      },
    },
  },

  defaultVariants: {
    size: 'medium',
  },
});

const AvatarImage = styled(RadixAvatar.Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const AvatarFallback = styled(RadixAvatar.Fallback, {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '20px',
  fontFamily: '$firaCode',
  backgroundColor: '$gray400',
  color: '$gray900',
});

export const Avatar = (props: {
  size?: 'small' | 'medium' | 'large';
  avatarImageUrl: string;
  userFirstName?: string;
  userLastName?: string;
  circular?: boolean; // defaults to false
}) => {
  const { size, avatarImageUrl, userFirstName, userLastName, circular } = props;

  const handleAvatarSize =
    size === 'small' || size === 'medium' || size === 'large' ? size : 'medium';

  return (
    <StyledAvatar
      data-testid="avatar-profile"
      size={handleAvatarSize}
      circular={circular === true ? circular : false}
    >
      <AvatarImage
        src={avatarImageUrl}
        alt={`${userFirstName} ${userLastName}'s avatar`}
      />
      <AvatarFallback>
        {formatInitials(userFirstName, userLastName)}
      </AvatarFallback>
    </StyledAvatar>
  );
};
