import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  /**
   * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: any;
  _FieldSet: any;
};

export type AcademicClearance = {
  __typename?: 'AcademicClearance';
  /** The discipline (cohort.disciplineName) clearance is granted to. Relation is academicClearance.disciplineUuid/cohort.disciplineUuid */
  disciplineUuid?: Maybe<Scalars['String']>;
  /** date clearance given */
  completedAt?: Maybe<Scalars['NaiveDateTime']>;
};

/** Attendance data for Notes that are categorized as Accountability Sessions */
export type AccountabilitySession = {
  __typename?: 'AccountabilitySession';
  /** Indicates whether or not the student attended the Accountability Session */
  attended: Scalars['Boolean'];
  /** Date the Accountability Session occurred */
  date: Scalars['String'];
  /** Entity identifer */
  id: Scalars['ID'];
};

/** Aggregated attendance information for Accountability Sessions */
export type AccountabilitySessionAttendanceRecord = {
  __typename?: 'AccountabilitySessionAttendanceRecord';
  /** Total number of Accountability Sessions that were not attended */
  absent: Scalars['Int'];
  /** Total number of Accountability Sessions attended */
  present: Scalars['Int'];
};

/** Accountability Session data */
export type AccountabilitySessionInput = {
  /** Indicates whether or not the Student attended the Accountability Session */
  attended: Scalars['Boolean'];
  /** The date of the Accountability Session in ISO8601 format */
  date: Scalars['String'];
};

/** Autogenerated input type of AddRecipientsToRecipientGroup */
export type AddRecipientsToRecipientGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Recipient Group ID */
  groupUuid: Scalars['ID'];
  /** List of recipients to add */
  recipientIds: Array<Scalars['ID']>;
};

/** Autogenerated input type of AddTemplateToDocumentSet */
export type AddTemplateToDocumentSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentSetId: Scalars['ID'];
  docusignTemplateId: Scalars['ID'];
};

/** Autogenerated input type of AddToConsumerPrework */
export type AddToConsumerPreworkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Name of the discipline as a slug (e.g. "software-engineering") */
  disciplineSlug: Scalars['String'];
  /** Student's email address */
  email?: Maybe<Scalars['String']>;
  /** Student's full name */
  fullName?: Maybe<Scalars['String']>;
  /** aka Learn UUID */
  studentUuid: Scalars['String'];
};

export type Admission = {
  __typename?: 'Admission';
  cancelledAt?: Maybe<Scalars['NaiveDateTime']>;
  cohort?: Maybe<Cohort>;
  status?: Maybe<Scalars['String']>;
  student?: Maybe<Student>;
  uuid?: Maybe<Scalars['ID']>;
};

/** Input for archiving recommendaions */
export type ArchiveRecommendationInput = {
  /** The ID of the recommendation */
  id: Scalars['ID'];
};

/** Autogenerated input type of AssignRequirementFromEnvelope */
export type AssignRequirementFromEnvelopeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  envelopeUuid: Scalars['ID'];
  recipientUuid: Scalars['ID'];
  templateKindId: Scalars['ID'];
};

export type AvailableLiveEvents = {
  __typename?: 'AvailableLiveEvents';
  filters: LiveEventFilters;
  liveEvents?: Maybe<Array<LiveEvent>>;
};

/** All objects inherit from this class. */
export type AvatarPostParams = {
  __typename?: 'AvatarPostParams';
  acl: Scalars['String'];
  key: Scalars['String'];
  policy: Scalars['String'];
  xAmzAlgorithm: Scalars['String'];
  xAmzCredential: Scalars['String'];
  xAmzDate: Scalars['String'];
  xAmzMetaOriginalFilename: Scalars['String'];
  xAmzSignature: Scalars['String'];
};

/** All objects inherit from this class. */
export type AvatarUploadUrl = {
  __typename?: 'AvatarUploadUrl';
  url: Scalars['String'];
  urlFields: AvatarPostParams;
};

/** A record of a user bookmarking content */
export type Bookmark = {
  __typename?: 'Bookmark';
  /** The UUID of the user that bookmarked the content */
  bookmarkedByUuid: Scalars['ID'];
  /** The bookmarked content */
  content: Content;
  /** The bookmarked content's UUID */
  contentUuid: Scalars['ID'];
  /** Timestamp detailing when content was bookmarked */
  createdAt: Scalars['ISO8601DateTime'];
  /** The bookmark record's UUID */
  uuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type CallToAction = {
  __typename?: 'CallToAction';
  buttonText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  subText: Scalars['String'];
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Canvas milestone type */
export type CanvasMilestone = {
  __typename?: 'CanvasMilestone';
  /** ID of the canvas assignment */
  canvasAssignmentExternalId: Scalars['ID'];
  /** Date the assignment was completed */
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** This field returns the date the milestone is due */
  dueOn?: Maybe<Scalars['ISO8601DateTime']>;
  /** URL for the assignment */
  htmlUrl: Scalars['String'];
  /** ID of the assignment */
  id: Scalars['ID'];
  /** Milestone name */
  name: Scalars['String'];
};

/** Categories that can be associated with notes */
export type Category = {
  __typename?: 'Category';
  /** Boolean to show if Category is an Accountability Session */
  accountabilitySession: Scalars['Boolean'];
  /** Entity identifer */
  id: Scalars['ID'];
  /** The name of the category */
  name: Scalars['String'];
};

/** Represents a coaching status. */
export type CoachingStatus = {
  __typename?: 'CoachingStatus';
  /** Name of the coaching status */
  name: Scalars['String'];
};

/** Represents a coaching tier. */
export type CoachingTier = {
  __typename?: 'CoachingTier';
  /** Name of the coaching tier */
  name: Scalars['String'];
};

export type Cohort = {
  __typename?: 'Cohort';
  uuid?: Maybe<Scalars['ID']>;
  canceledAt?: Maybe<Scalars['NaiveDateTime']>;
  /** @deprecated Relies on copied data; use `courseOffering { name }` */
  courseOfferingName?: Maybe<Scalars['String']>;
  /** @deprecated Relies on copied data; use `campus { name }` (NOTE: the campus field may need to be added in service-catalog) */
  campusName: Scalars['String'];
  /** @deprecated Relies on copied data; use `courseOffering { discipline { name } }` */
  disciplineName: Scalars['String'];
  /** @deprecated Relies on copied data; use `courseOffering { discipline { uuid } }` */
  disciplineUuid: Scalars['String'];
  endDate?: Maybe<Scalars['NaiveDateTime']>;
  name?: Maybe<Scalars['String']>;
  openedForEnrollmentAt?: Maybe<Scalars['NaiveDateTime']>;
  /** @deprecated Relies on copied data; use `courseOffering { pacingOption { name } }` */
  paceName: Scalars['String'];
  paceUuid: Scalars['String'];
  startDate?: Maybe<Scalars['NaiveDateTime']>;
  students?: Maybe<Array<Maybe<Student>>>;
  /** The course offering the cohort belongs to */
  courseOffering: CourseOffering;
  /** The start date of the cohort, formatted as mmddyy */
  formattedStartDate: Scalars['String'];
  /** The institution the cohort belongs to */
  institution: Institution;
  /** The market the cohort belongs to, through the campus */
  market: Market;
};

export type CohortStudentsArgs = {
  id?: Maybe<Scalars['Int']>;
};

/** All input objects inherit from this class. */
export type CohortInput = {
  name: Scalars['String'];
  startDate: Scalars['ISO8601DateTime'];
  uuid: Scalars['ID'];
};

/** A simplified cohort (name, and uuid) loaded from Service Documents */
export type CohortSimple = {
  __typename?: 'CohortSimple';
  /** Cohort name, e.g. NYC01-SENG-FT-010114 */
  name?: Maybe<Scalars['String']>;
  /** Cohort UUID */
  uuid: Scalars['ID'];
};

/** Autogenerated input type of CompleteFileUpload */
export type CompleteFileUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileUploadUuid: Scalars['ID'];
  fileUrl: Scalars['String'];
};

/** Autogenerated input type of CompleteRequirement */
export type CompleteRequirementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The envelope associated with this requirement */
  envelopeId: Scalars['ID'];
  /** The recipient uuid. */
  recipientUuid: Scalars['ID'];
  /** Requirement to be marked as completed */
  requirementUuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type ComplianceRequirement = {
  __typename?: 'ComplianceRequirement';
  assignedAt: Scalars['ISO8601DateTime'];
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  envelopeId?: Maybe<Scalars['ID']>;
  kind: Scalars['String'];
  removedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status: Scalars['String'];
  title: Scalars['String'];
  uuid: Scalars['ID'];
};

/** External resources wrapped for use in Resource Library on Base */
export type Content = {
  __typename?: 'Content';
  /** Record of the current user bookmarking the content */
  bookmark?: Maybe<Bookmark>;
  /** HTML with details about the content */
  description?: Maybe<Scalars['String']>;
  /** Associated program disciplines */
  disciplines?: Maybe<Array<ProgramDiscipline>>;
  /** The embed URL to be used in an iframe, etc */
  embedUrl?: Maybe<Scalars['String']>;
  /** The format of the content (video, article, etc.) */
  format?: Maybe<ContentFormatEnum>;
  /** The URL for an uploaded image, otherwise an external image URL from Open Graph data */
  imageUrl?: Maybe<Scalars['String']>;
  /** Associatied program modules */
  modules?: Maybe<Array<ProgramModule>>;
  /** Associated program phases */
  phases?: Maybe<Array<ProgramPhase>>;
  /** Readable(ish), URL-friendly, unique identifier for the content */
  slug: Scalars['String'];
  /** Associated tags */
  tags?: Maybe<Array<Tag>>;
  /** Title of the content */
  title: Scalars['String'];
  /** The type of content (career, community, etc.) */
  type?: Maybe<ContentTypeEnum>;
  /** Source (external) URL of content */
  url: Scalars['String'];
  /** Unique identifier */
  uuid: Scalars['ID'];
  /** Record of the current user viewing the content */
  view?: Maybe<View>;
};

/** Filters for the contents query */
export type ContentFilterInput = {
  /** Limit results to content that has been bookmarked by the user */
  bookmarked?: Maybe<Scalars['Boolean']>;
  /** Limit results to content associated with a specific discipline by its UUID */
  disciplineUuid?: Maybe<Scalars['ID']>;
  /** Limit results to content of a specific formats */
  format?: Maybe<ContentFormatEnum>;
  /** Limit results to content associated with a specific phase by its UUID */
  phaseUuid?: Maybe<Scalars['ID']>;
  /**
   * Limit content to items with specific tags by their UUID (note that multiple
   * tag UUIDs will be queried with an "or" rather than "and")
   */
  tagUuids?: Maybe<Array<Scalars['ID']>>;
  /** Limit results to content of a specific type */
  type?: Maybe<ContentTypeEnum>;
  /** Limit results to content that has been viewed by the user */
  viewed?: Maybe<Scalars['Boolean']>;
};

/** The entities that can be used for filtering content */
export type ContentFilterOptions = {
  __typename?: 'ContentFilterOptions';
  /** Program discipline entities */
  disciplines?: Maybe<Array<ProgramDiscipline>>;
  /** Program phase entities */
  phases?: Maybe<Array<ProgramPhase>>;
  /** Tags (limited by student status & institution) */
  tags?: Maybe<Array<Tag>>;
};

/** Values for content's format */
export enum ContentFormatEnum {
  /** Article content */
  Article = 'ARTICLE',
  /** Blog content */
  Blog = 'BLOG',
  /** Misc content */
  Other = 'OTHER',
  /** Podcast content */
  Podcast = 'PODCAST',
  /** Video content */
  Video = 'VIDEO',
}

/** The categories for Content Highlights */
export type ContentHighlights = {
  __typename?: 'ContentHighlights';
  /** Career related content */
  careerContent?: Maybe<Array<Content>>;
  /** Community related content */
  communityContent?: Maybe<Array<Content>>;
  /** Lecture content based on a student's discipline */
  lectureContent?: Maybe<Array<Content>>;
  /** The discipline used to scope lecture content */
  lectureDiscipline?: Maybe<ProgramDiscipline>;
};

/** Values for content's type */
export enum ContentTypeEnum {
  /** Career-related content */
  Career = 'CAREER',
  /** Community-related content */
  Community = 'COMMUNITY',
  /** Lecture content */
  Lecture = 'LECTURE',
}

export type ContinueLearning = {
  __typename?: 'ContinueLearning';
  links?: Maybe<Array<Maybe<Link>>>;
};

/** Course type */
export type Course = {
  __typename?: 'Course';
  /** Number of completed required tasks in the course */
  completedTasks: Scalars['Int'];
  /** URL for the course */
  courseUrl: Scalars['String'];
  /** URL for the current task in the course */
  currentTaskUrl: Scalars['String'];
  /** This field returns the date the course was started */
  lastAccessDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Course name */
  name: Scalars['String'];
  /** Percentage of completed tasks in the course */
  progressPercentage: Scalars['Int'];
  /** Total number of required tasks in the course */
  totalTasks: Scalars['Int'];
};

/** The connection type for Course. */
export type CourseConnection = {
  __typename?: 'CourseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CourseEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Course>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count of all connected items */
  totalCount: Scalars['Int'];
};

/** All edges inherit from this class. */
export type CourseEdge = {
  __typename?: 'CourseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Course>;
};

/** Joins a discipline and pace, along with an optional modality */
export type CourseOffering = {
  __typename?: 'CourseOffering';
  /** The discipline of the offering */
  discipline: Discipline;
  /** The UUID of the course offering */
  id: Scalars['ID'];
  /** The modality of the offering */
  modality?: Maybe<Modality>;
  /** A display name formatted as "Discipline PACE [modality] */
  name: Scalars['String'];
  /** The pace of the offering */
  pacingOption: PacingOption;
};

/** Autogenerated input type of CreateDocumentSet */
export type CreateDocumentSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateIds: Array<Scalars['ID']>;
};

/** Autogenerated input type of CreateDocusignTemplate */
export type CreateDocusignTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  externalId: Scalars['ID'];
  internalName?: Maybe<Scalars['String']>;
  templateKindId: Scalars['ID'];
  title: Scalars['String'];
};

/** Autogenerated input type of CreateFileUpload */
export type CreateFileUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  studentUuid: Scalars['ID'];
  typeId: Scalars['ID'];
};

/** Autogenerated input type of CreateFileUploadTypes */
export type CreateFileUploadTypesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  visibleToStudent?: Maybe<Scalars['Boolean']>;
};

/** Input for note creation */
export type CreateNoteInput = {
  /** Accountability Session data */
  accountabilitySession?: Maybe<AccountabilitySessionInput>;
  /** Student aptitude score */
  aptitude?: Maybe<Scalars['Int']>;
  /** Student attitude score */
  attitude?: Maybe<Scalars['Int']>;
  /** Id of associated Category */
  categoryId?: Maybe<Scalars['Int']>;
  /** The note body as HTML */
  contentHtml: Scalars['String'];
  /** The note body as plain text */
  contentText: Scalars['String'];
  /** AKA Learn UUID */
  studentUuid: Scalars['String'];
};

/** Autogenerated input type of CreateRecipientGroup */
export type CreateRecipientGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Associated Document Set */
  documentSetId: Scalars['ID'];
  /** Unique name for the group */
  groupName: Scalars['String'];
  /** List of recipients to associate with the group */
  recipientIds: Array<Scalars['ID']>;
};

/** Input for creating a recommendaions */
export type CreateRecommendationClickInput = {
  /** ID of recommendation to track click for */
  id: Scalars['ID'];
};

/** Input for creating a recommendaions */
export type CreateRecommendationInput = {
  /** Added information about the recommendation */
  detail?: Maybe<Scalars['String']>;
  /** The UUID of the recommendee */
  recommendedToUuid: Scalars['ID'];
  /** The title of the recommended resource */
  title: Scalars['String'];
  /** The URL of the recommended resource */
  url: Scalars['String'];
};

/** Autogenerated input type of CreateTemplateKind */
export type CreateTemplateKindInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  isReviewOnly: Scalars['Boolean'];
  name: Scalars['String'];
};

/** Subject matter of a course offering */
export type Discipline = {
  __typename?: 'Discipline';
  /** The UUID of the discipline */
  id: Scalars['ID'];
  /** A titleized display name */
  name: Scalars['String'];
  /** The short name of the discipline */
  shortName: Scalars['String'];
  /** A short name for display to students */
  studentFacingAbbreviation?: Maybe<Scalars['String']>;
};

/** All objects inherit from this class. */
export type DocumentSet = {
  __typename?: 'DocumentSet';
  /** Simple Cohort (name and uuid). Temp until we can hook up full cohort */
  cohorts: Array<CohortSimple>;
  createdAt: Scalars['ISO8601DateTime'];
  documentSetTemplates: Array<DocumentSetTemplate>;
  fileUploadTypes: Array<FileUploadType>;
  name: Scalars['String'];
  /** List of recipient groups using this document set */
  recipientGroups: Array<RecipientGroup>;
  retiredAt?: Maybe<Scalars['ISO8601DateTime']>;
  uuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type DocumentSetTemplate = {
  __typename?: 'DocumentSetTemplate';
  createdAt: Scalars['ISO8601DateTime'];
  docusignTemplate: DocusignTemplate;
  retiredAt?: Maybe<Scalars['ISO8601DateTime']>;
  uuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type DocusignTemplate = {
  __typename?: 'DocusignTemplate';
  createdAt: Scalars['ISO8601DateTime'];
  externalId: Scalars['ID'];
  /** Name used by compliance to differentiate between versions */
  internalName?: Maybe<Scalars['String']>;
  retiredAt?: Maybe<Scalars['ISO8601DateTime']>;
  templateKind: TemplateKind;
  title: Scalars['String'];
  uuid: Scalars['ID'];
};

/** An instructor, coach, or admin */
export type Educator = UserInterface & {
  __typename?: 'Educator';
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Email address */
  email?: Maybe<Scalars['String']>;
  /** First name */
  firstName?: Maybe<Scalars['String']>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** AKA Learn UUID */
  uuid: Scalars['ID'];
  /** Scheduling URL */
  availabilityScheduleUrl?: Maybe<Scalars['String']>;
  /** Students linked to the instructor */
  students: Array<Student>;
};

export type ExpiredStudents = {
  __typename?: 'ExpiredStudents';
  /** Learn uuid.... */
  learnUuid?: Maybe<Scalars['ID']>;
  /** The expiration date */
  expiredAt?: Maybe<Scalars['NaiveDateTime']>;
  /** The uuid of the cohort the student expired from */
  cohortUuid?: Maybe<Scalars['ID']>;
};

/** A fellowship for a student. */
export type Fellowship = {
  __typename?: 'Fellowship';
  /** The ID of the fellowship. */
  id: Scalars['ID'];
  /** The name of the fellowship. */
  name: Scalars['String'];
};

/** All objects inherit from this class. */
export type FileUpload = {
  __typename?: 'FileUpload';
  assignedAt: Scalars['ISO8601DateTime'];
  assignedBy?: Maybe<Scalars['ID']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  completedBy?: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  fileName?: Maybe<Scalars['String']>;
  fileUploadType: FileUploadType;
  fileUrl?: Maybe<Scalars['String']>;
  removedAt?: Maybe<Scalars['ISO8601DateTime']>;
  removedBy?: Maybe<Scalars['ID']>;
  studentUuid: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  uuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type FileUploadPostParams = {
  __typename?: 'FileUploadPostParams';
  acl: Scalars['String'];
  key: Scalars['String'];
  policy: Scalars['String'];
  xAmzAlgorithm: Scalars['String'];
  xAmzCredential: Scalars['String'];
  xAmzDate: Scalars['String'];
  xAmzMetaOriginalFilename: Scalars['String'];
  xAmzSignature: Scalars['String'];
};

/** All objects inherit from this class. */
export type FileUploadType = {
  __typename?: 'FileUploadType';
  associatedCohorts: Array<CohortSimple>;
  associatedRecipientGroups: Array<RecipientGroup>;
  createdAt: Scalars['ISO8601DateTime'];
  name: Scalars['String'];
  retiredAt?: Maybe<Scalars['ISO8601DateTime']>;
  studentCount: Scalars['Int'];
  uuid: Scalars['ID'];
  visibleToStudent: Scalars['Boolean'];
};

/** All objects inherit from this class. */
export type FileUploadUrl = {
  __typename?: 'FileUploadUrl';
  url: Scalars['String'];
  urlFields: FileUploadPostParams;
};

export type Graduated = {
  __typename?: 'Graduated';
  graduatedAt?: Maybe<Scalars['NaiveDateTime']>;
};

export type GraduatingStudent = {
  __typename?: 'GraduatingStudent';
  /** Learn uuid.... */
  learnUuid?: Maybe<Scalars['ID']>;
  /** The Graduation Date */
  graduatedAt?: Maybe<Scalars['NaiveDateTime']>;
  /** The uuid of the cohort the student graduated from */
  cohortUuid?: Maybe<Scalars['ID']>;
};

/** All objects inherit from this class. */
export type HomeAddress = {
  __typename?: 'HomeAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  studentUuid: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

/** Huntr member type */
export type HuntrMember = {
  __typename?: 'HuntrMember';
  /** Timestamp */
  createdAt: Scalars['ISO8601DateTime'];
  /** External id on Huntr */
  externalMemberId: Scalars['String'];
};

/** All objects inherit from this class. */
export type IdentityTerm = {
  __typename?: 'IdentityTerm';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** All objects inherit from this class. */
export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID'];
  /**
   * Name of the institution
   * @deprecated moving to use standardized fieldname, use name instead
   */
  institutionName: Scalars['String'];
  /**
   * The kind of institution
   * @deprecated moving to use standardized fieldname, use type instead
   */
  institutionType: Scalars['String'];
  /** Whether it is the default consumer institution, i.e. Flatiron School */
  isDefault: Scalars['Boolean'];
  /** Name of the institution */
  name: Scalars['String'];
  /** Products offered by the institution */
  productOfferings: ProductOfferingConnection;
  /** The id of the SFDC institution object */
  sfdcId?: Maybe<Scalars['String']>;
  /** The kind of institution */
  type: InstitutionTypeEnum;
  /** The UUID of the institution */
  uuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type InstitutionProductOfferingsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Values for institution type */
export enum InstitutionTypeEnum {
  /** Primary consumer institution (Flatiron School) */
  Default = 'DEFAULT',
  /** Third-party companies */
  Enterprise = 'ENTERPRISE',
  /** Third-party schools */
  University = 'UNIVERSITY',
}

/**
 * Service-to-service requests only (SYSTEM_BOT): Provides direct access to
 * profile data in Ironboard. Favor using these fields on Student.
 */
export type IronboardProfile = {
  __typename?: 'IronboardProfile';
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']>;
  /** First name */
  firstName?: Maybe<Scalars['String']>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** A note about a student left by an admin in the Portal */
export type LegacyNote = {
  __typename?: 'LegacyNote';
  author: Educator;
  /** @deprecated Prefer entity relationships (i.e. `author`) over proxies */
  authorUuid: Scalars['ID'];
  contentHtml: Scalars['String'];
  contentText: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  studentUuid: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Link = {
  __typename?: 'Link';
  source?: Maybe<Scalars['String']>;
  lastAccessDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  progressPercentage?: Maybe<Scalars['Int']>;
  totalTasks?: Maybe<Scalars['Int']>;
  completedTasks?: Maybe<Scalars['Int']>;
  currentTaskUrl?: Maybe<Scalars['String']>;
  courseUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of LinkCohortsWithDocumentSet */
export type LinkCohortsWithDocumentSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cohorts: Array<CohortInput>;
  documentSetId: Scalars['ID'];
};

export type LiveEvent = {
  __typename?: 'LiveEvent';
  description?: Maybe<Scalars['String']>;
  inPersonLocation?: Maybe<Scalars['String']>;
  liveEventTypes: Array<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mainImageUrl?: Maybe<Scalars['String']>;
  programDisciplines?: Maybe<Array<Scalars['String']>>;
  programModules?: Maybe<Array<Scalars['String']>>;
  programPhases?: Maybe<Array<Scalars['String']>>;
  remoteMeetingLink?: Maybe<Scalars['String']>;
  saved?: Maybe<Scalars['Boolean']>;
  scheduledDate: Scalars['ISO8601DateTime'];
  scheduledDurationSeconds: Scalars['Int'];
  slug: Scalars['String'];
  speaker?: Maybe<Scalars['String']>;
  /** @deprecated Renaming to Description */
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  uuid: Scalars['ID'];
};

export type LiveEventFilter = {
  __typename?: 'LiveEventFilter';
  className: Scalars['String'];
  displayName: Scalars['String'];
  options: Array<LiveEventFilterOption>;
};

export type LiveEventFilterInput = {
  disciplineUuid?: Maybe<Scalars['ID']>;
  liveEventTypeUuids?: Maybe<Array<Scalars['ID']>>;
  phaseUuid?: Maybe<Scalars['ID']>;
  recommended?: Maybe<Scalars['Boolean']>;
};

export type LiveEventFilterOption = {
  __typename?: 'LiveEventFilterOption';
  name: Scalars['String'];
  uuid: Scalars['ID'];
};

export type LiveEventFilters = {
  __typename?: 'LiveEventFilters';
  disciplineFilter: LiveEventFilter;
  eventTypeFilter: LiveEventFilter;
  liveEventTypeFilter: LiveEventFilter;
  phaseFilter: LiveEventFilter;
};

/** Autogenerated input type of MarkContentViewed */
export type MarkContentViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The UUID of the content viewed. */
  contentUuid: Scalars['String'];
};

/** Subject matter of a market */
export type Market = {
  __typename?: 'Market';
  /** The UUID of the market */
  id: Scalars['ID'];
  /** The name of the market */
  name: Scalars['String'];
  /** The short name of the market */
  shortName: Scalars['String'];
  /** The student facing abbreviation of the market */
  studentFacingAbbreviation?: Maybe<Scalars['String']>;
};

/** Mode of a course offering */
export type Modality = {
  __typename?: 'Modality';
  /** The UUID of the modality */
  id: Scalars['ID'];
  /** A titleized display name */
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteHomeAddress: Scalars['Boolean'];
  deleteShippingAddress: Scalars['Boolean'];
  trackEngagement: Scalars['Boolean'];
  updateHomeAddress: HomeAddress;
  updateProfileFromEnvelope: StudentProfile;
  updateShippingAddress: ShippingAddress;
  updateStudentLightMode: StudentProfile;
  updateStudentProfile: StudentProfile;
  /** Archive a note about a student (logically delete) */
  archiveNote: Note;
  /** Archive a recommendation made to a student (logically delete) */
  archiveRecommendation: Recommendation;
  /** Add a note about a student */
  createNote: Note;
  /** Recommend a URL resource to a student */
  createRecommendation: Recommendation;
  /** Tracks that a student clicked a recommendation */
  createRecommendationClick: RecommendationClick;
  /** Update an existing note about a student */
  updateNote: Note;
  addRecipientsToRecipientGroup?: Maybe<RecipientGroup>;
  addTemplateToDocumentSet?: Maybe<DocumentSet>;
  assignRequirementFromEnvelope?: Maybe<ComplianceRequirement>;
  completeFileUpload?: Maybe<FileUpload>;
  completeRequirement?: Maybe<ComplianceRequirement>;
  createDocumentSet?: Maybe<DocumentSet>;
  createDocusignTemplate?: Maybe<DocusignTemplate>;
  createFileUpload?: Maybe<FileUpload>;
  createFileUploadType?: Maybe<FileUploadType>;
  createRecipientGroup?: Maybe<RecipientGroup>;
  createTemplateKind?: Maybe<TemplateKind>;
  linkCohortsWithDocumentSet?: Maybe<DocumentSet>;
  /** Mark a compliance requirement as pending. A state between the student signing and the service recognizing the webhook */
  pendingRequirement?: Maybe<Scalars['Boolean']>;
  removeFileFromRequest?: Maybe<FileUpload>;
  removeFileUploadRequest?: Maybe<FileUpload>;
  removeRequirement?: Maybe<ComplianceRequirement>;
  retireDocumentSet?: Maybe<DocumentSet>;
  retireDocusignTemplate?: Maybe<DocusignTemplate>;
  retireFileUploadType?: Maybe<FileUploadType>;
  setDocumentSetsForCohort?: Maybe<CohortSimple>;
  setDocumentSetsForRecipientGroup?: Maybe<RecipientGroup>;
  setFileUploadTypesForDocumentSet?: Maybe<DocumentSet>;
  uploadFile?: Maybe<FileUpload>;
  /** Mark content as viewed by the user. */
  markContentViewed?: Maybe<View>;
  /** Toggle the bookmark state of content for the user. */
  toggleContentBookmarked?: Maybe<Content>;
  /** Toggle the save state of a live event for a student. */
  toggleSaveLiveEvent?: Maybe<ToggleSaveLiveEventPayload>;
  /**
   * Service-to-service requests only (SYSTEM_BOT): Provides a way to update
   * profile data in Ironboard.
   */
  updateIronboardProfile?: Maybe<IronboardProfile>;
  updateStudent?: Maybe<UpdateStudentPayload>;
  /** Find or create a prework enrollment for the given discipline on the consumer Canvas instance */
  addToConsumerPrework: PreworkEnrollment;
};

export type MutationDeleteHomeAddressArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteShippingAddressArgs = {
  id: Scalars['ID'];
};

export type MutationTrackEngagementArgs = {
  callToActionId: Scalars['ID'];
};

export type MutationUpdateHomeAddressArgs = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type MutationUpdateProfileFromEnvelopeArgs = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  emergencyContactEmail?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postSecondaryEducation?: Maybe<Scalars['JSON']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  studentUuid: Scalars['String'];
  zipcode?: Maybe<Scalars['String']>;
};

export type MutationUpdateShippingAddressArgs = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type MutationUpdateStudentLightModeArgs = {
  lightMode?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateStudentProfileArgs = {
  avatarUrl?: Maybe<Scalars['String']>;
  blogUrl?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  daylightSavingsTime?: Maybe<Scalars['Boolean']>;
  educationalBackground?: Maybe<Scalars['String']>;
  emergencyContactEmail?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  identities?: Maybe<Array<Scalars['ID']>>;
  lastName?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  militaryStatus?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  portfolioSiteUrl?: Maybe<Scalars['String']>;
  postSecondaryEducation?: Maybe<Scalars['JSON']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredPronouns?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  races?: Maybe<Array<Scalars['String']>>;
  skilledInterviewFeedbackUrl?: Maybe<Scalars['String']>;
  skilledMentorFeedbackUrl?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  tshirtSize?: Maybe<Scalars['String']>;
};

export type MutationArchiveNoteArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveRecommendationArgs = {
  input: ArchiveRecommendationInput;
};

export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

export type MutationCreateRecommendationArgs = {
  input: CreateRecommendationInput;
};

export type MutationCreateRecommendationClickArgs = {
  input: CreateRecommendationClickInput;
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationAddRecipientsToRecipientGroupArgs = {
  input: AddRecipientsToRecipientGroupInput;
};

export type MutationAddTemplateToDocumentSetArgs = {
  input: AddTemplateToDocumentSetInput;
};

export type MutationAssignRequirementFromEnvelopeArgs = {
  input: AssignRequirementFromEnvelopeInput;
};

export type MutationCompleteFileUploadArgs = {
  input: CompleteFileUploadInput;
};

export type MutationCompleteRequirementArgs = {
  input: CompleteRequirementInput;
};

export type MutationCreateDocumentSetArgs = {
  input: CreateDocumentSetInput;
};

export type MutationCreateDocusignTemplateArgs = {
  input: CreateDocusignTemplateInput;
};

export type MutationCreateFileUploadArgs = {
  input: CreateFileUploadInput;
};

export type MutationCreateFileUploadTypeArgs = {
  input: CreateFileUploadTypesInput;
};

export type MutationCreateRecipientGroupArgs = {
  input: CreateRecipientGroupInput;
};

export type MutationCreateTemplateKindArgs = {
  input: CreateTemplateKindInput;
};

export type MutationLinkCohortsWithDocumentSetArgs = {
  input: LinkCohortsWithDocumentSetInput;
};

export type MutationPendingRequirementArgs = {
  input: PendingRequirementInput;
};

export type MutationRemoveFileFromRequestArgs = {
  input: RemoveFileFromRequestInput;
};

export type MutationRemoveFileUploadRequestArgs = {
  input: RemoveFileUploadRequestInput;
};

export type MutationRemoveRequirementArgs = {
  input: RemoveRequirementInput;
};

export type MutationRetireDocumentSetArgs = {
  input: RetireDocumentSetInput;
};

export type MutationRetireDocusignTemplateArgs = {
  input: RetireDocusignTemplateInput;
};

export type MutationRetireFileUploadTypeArgs = {
  input: RetireFileUploadInput;
};

export type MutationSetDocumentSetsForCohortArgs = {
  input: SetDocumentSetsForCohortInput;
};

export type MutationSetDocumentSetsForRecipientGroupArgs = {
  input: SetDocumentSetsForRecipientGroupInput;
};

export type MutationSetFileUploadTypesForDocumentSetArgs = {
  input: SetFileUploadTypesForDocumentSetInput;
};

export type MutationUploadFileArgs = {
  input: UploadFileInput;
};

export type MutationMarkContentViewedArgs = {
  input: MarkContentViewedInput;
};

export type MutationToggleContentBookmarkedArgs = {
  input: ToggleContentBookmarkedInput;
};

export type MutationToggleSaveLiveEventArgs = {
  input: ToggleSaveLiveEventInput;
};

export type MutationUpdateIronboardProfileArgs = {
  input: UpdateIronboardProfileInput;
};

export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
};

export type MutationAddToConsumerPreworkArgs = {
  input: AddToConsumerPreworkInput;
};

/** Notes about a student authored by an educator in Guide */
export type Note = {
  __typename?: 'Note';
  /** Accountability Session data for Notes categorized as Accountability Sessions */
  accountabilitySession?: Maybe<AccountabilitySession>;
  /** Student aptitude score */
  aptitude?: Maybe<Scalars['Int']>;
  /** Student attitude score */
  attitude?: Maybe<Scalars['Int']>;
  /** The note's author */
  author: Educator;
  /** AKA Learn UUID */
  authorUuid: Scalars['ID'];
  /** Category associated with Note */
  category?: Maybe<Category>;
  /** Id of associated Category */
  categoryId?: Maybe<Scalars['Int']>;
  /** The note body as HTML */
  contentHtml: Scalars['String'];
  /** The note body as plain text */
  contentText: Scalars['String'];
  /** Timestamp */
  createdAt: Scalars['ISO8601DateTime'];
  /** Entity identifer */
  id: Scalars['ID'];
  /** AKA Learn UUID */
  studentUuid: Scalars['ID'];
  /** Timestamp */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Pace of a course offering */
export type PacingOption = {
  __typename?: 'PacingOption';
  /** The UUID of the pacing option */
  id: Scalars['ID'];
  /** A titleized display name */
  name: Scalars['String'];
  /** An abbreviated name */
  shortName: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PendingRequirement */
export type PendingRequirementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The docusign envelope uuid */
  envelopeUuid: Scalars['ID'];
};

/** Represents a user permission. */
export type Permission = {
  __typename?: 'Permission';
  /** Name of the permission. */
  name: Scalars['String'];
};

/** A prework course offered externally through Canvas */
export type PreworkCourse = {
  __typename?: 'PreworkCourse';
  /** URL for the course on Canvas */
  externalUrl: Scalars['String'];
};

/** A student's enrollment in prework for a particular discipline */
export type PreworkEnrollment = {
  __typename?: 'PreworkEnrollment';
  /** Indicates when a "complete" or "passing" grade was detected */
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Associatied prework course */
  course: PreworkCourse;
  /** Identifier of the associated discipline */
  disciplineUuid: Scalars['String'];
  /** aka Learn UUID */
  studentUuid: Scalars['String'];
  /** Unique identifier */
  uuid: Scalars['ID'];
};

/** Features, or third-party products, offered by an institution */
export type ProductOffering = {
  __typename?: 'ProductOffering';
  /** UUID */
  id: Scalars['ID'];
  /** Display name of the feature or third-party product */
  name: Scalars['String'];
  /** Immutable codename (use this for programmatic checks) */
  slug: Scalars['String'];
};

/** The connection type for ProductOffering. */
export type ProductOfferingConnection = {
  __typename?: 'ProductOfferingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductOfferingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductOffering>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count of all connected items */
  totalCount: Scalars['Int'];
};

/** All edges inherit from this class. */
export type ProductOfferingEdge = {
  __typename?: 'ProductOfferingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductOffering>;
};

/** Flatiron School discipline entities */
export type ProgramDiscipline = {
  __typename?: 'ProgramDiscipline';
  /** The full name of the discipline */
  name: Scalars['String'];
  /** The discipline's UUID */
  uuid: Scalars['ID'];
};

/** Flatiron School module entities */
export type ProgramModule = {
  __typename?: 'ProgramModule';
  /** The name of the module */
  name: Scalars['String'];
  /** The module's phase entity */
  phase: ProgramPhase;
  /** The UUID of the module's phase */
  phaseUuid: Scalars['ID'];
  /** The module's UUID */
  uuid: Scalars['ID'];
};

/** Flatiron School phase entities */
export type ProgramPhase = {
  __typename?: 'ProgramPhase';
  /** The phase's discipline entity */
  discipline: ProgramDiscipline;
  /** The UUID of the phase's discipline */
  disciplineUuid: Scalars['ID'];
  /** The name of the phase */
  name: Scalars['String'];
  /** The phase's UUID */
  uuid: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** Fetches avatar upload url */
  getAvatarUploadUrl: AvatarUploadUrl;
  /** Find a fellowship by ID */
  fellowship: Fellowship;
  /** Returns all fellowships */
  fellowships: Array<Fellowship>;
  /** Fetch permissions for a user. */
  permissions: Array<Permission>;
  /** All categories for notes */
  allCategories: Array<Category>;
  /** Fetch note by id */
  getNote?: Maybe<Note>;
  /** Link to sign a compliance requirement */
  complianceRequirementEnvelopeUrl: Scalars['String'];
  /** Retrieve a single document set */
  documentSet: DocumentSet;
  /** All active document sets */
  documentSets: Array<DocumentSet>;
  /** All DocuSign template kinds */
  docusignTemplateKinds: Array<TemplateKind>;
  /** All DocuSign templates */
  docusignTemplates: Array<DocusignTemplate>;
  /** All file upload types */
  fileUploadTypes: Array<FileUploadType>;
  /** Fetches file upload url */
  getFileUploadUrl: FileUploadUrl;
  /** Recently completed file uploads */
  recentlyCompletedFileUploads: Array<FileUpload>;
  /** Compliance Recipient Groups */
  recipientGroup: RecipientGroup;
  /** List of all Recipient Groups */
  recipientGroups: Array<RecipientGroup>;
  /** Get a piece of content by slug. */
  content: Content;
  /** Filtering options for content */
  contentFilterOptions: ContentFilterOptions;
  /** Retrieve content related to the user */
  contentHighlights: ContentHighlights;
  /** Fetch all content available to the user */
  contents?: Maybe<Array<Content>>;
  /** Get a live event by slug. */
  getLiveEvent: LiveEvent;
  /** All disciplines */
  disciplines: Array<Discipline>;
  /** Retrieve a cohort */
  fetchCohort: Cohort;
  /** A specific institution */
  institution: Institution;
  /** All Institutions */
  institutions: Array<Institution>;
  /** All markets */
  markets: Array<Market>;
  cohorts?: Maybe<Array<Maybe<Cohort>>>;
  /** @deprecated Relies on a copied data; use `fetchCohort` */
  cohort?: Maybe<Cohort>;
  recentlyGraduatedStudents?: Maybe<Array<Maybe<GraduatingStudent>>>;
  recentlyExpiredStudents?: Maybe<Array<Maybe<ExpiredStudents>>>;
  recentlyWithdrawnAdmissions?: Maybe<Array<Maybe<Admission>>>;
  recentlyCancelledAdmissions?: Maybe<Array<Maybe<Admission>>>;
  /** Find an educator by UUID */
  educator: Educator;
  /**
   * Service-to-service requests only (SYSTEM_BOT): Provides direct access to
   * profile data in Ironboard. Favor using a student query instead.
   */
  ironboardProfile: IronboardProfile;
  me: Student;
  student: Student;
  /** Retrieve a Huntr member if one exists */
  huntrMember?: Maybe<HuntrMember>;
  /** Returns all prework completions for list of students */
  preworkCompletions: Array<PreworkEnrollment>;
  /** Finds a student by their Canvas user ID if an association is known */
  studentByCanvasId?: Maybe<Student>;
  /** Finds a student by their Discord user ID if an association is known */
  studentByDiscordId?: Maybe<Student>;
  /** Retrieve a Huntr member if one exists */
  studentHuntrMember?: Maybe<StudentHuntrMember>;
};

export type QueryGetAvatarUploadUrlArgs = {
  filename: Scalars['String'];
};

export type QueryFellowshipArgs = {
  uuid: Scalars['ID'];
};

export type QueryPermissionsArgs = {
  uuid: Scalars['ID'];
};

export type QueryGetNoteArgs = {
  id: Scalars['ID'];
};

export type QueryComplianceRequirementEnvelopeUrlArgs = {
  uuid: Scalars['ID'];
};

export type QueryDocumentSetArgs = {
  uuid: Scalars['ID'];
};

export type QueryDocumentSetsArgs = {
  cohortId?: Maybe<Scalars['ID']>;
};

export type QueryGetFileUploadUrlArgs = {
  filename: Scalars['String'];
};

export type QueryRecentlyCompletedFileUploadsArgs = {
  fileUploadTypeId: Scalars['ID'];
};

export type QueryRecipientGroupArgs = {
  uuid: Scalars['ID'];
};

export type QueryContentArgs = {
  slug: Scalars['String'];
};

export type QueryContentsArgs = {
  filters?: Maybe<ContentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetLiveEventArgs = {
  slug: Scalars['String'];
};

export type QueryFetchCohortArgs = {
  uuid: Scalars['ID'];
};

export type QueryInstitutionArgs = {
  uuid: Scalars['ID'];
};

export type QueryCohortArgs = {
  uuid?: Maybe<Scalars['ID']>;
};

export type QueryRecentlyWithdrawnAdmissionsArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type QueryRecentlyCancelledAdmissionsArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type QueryEducatorArgs = {
  uuid: Scalars['ID'];
};

export type QueryIronboardProfileArgs = {
  uuid: Scalars['ID'];
};

export type QueryStudentArgs = {
  uuid: Scalars['ID'];
};

export type QueryPreworkCompletionsArgs = {
  studentUuids: Array<Scalars['String']>;
};

export type QueryStudentByCanvasIdArgs = {
  canvasUserId: Scalars['Int'];
  domainRootAccountId: Scalars['String'];
};

export type QueryStudentByDiscordIdArgs = {
  discordUserId: Scalars['String'];
};

export type QueryStudentHuntrMemberArgs = {
  learnUuid: Scalars['String'];
};

/** All objects inherit from this class. */
export type RecipientGroup = {
  __typename?: 'RecipientGroup';
  /** When the group was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** List of associated document sets */
  documentSets: Array<DocumentSet>;
  /** Recipient Group Name */
  name: Scalars['String'];
  /** List of recipients in this group */
  recipients: StudentConnection;
  /** DEPRECATED List of recipients in this group */
  recipientsDeprecated: Array<Student>;
  /** Recipient Group UUID */
  uuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type RecipientGroupRecipientsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Resources recommended from an educator to a student */
export type Recommendation = {
  __typename?: 'Recommendation';
  /** Timestamp */
  createdAt: Scalars['ISO8601DateTime'];
  /** Added information. E.g. due date, description... */
  detail?: Maybe<Scalars['String']>;
  /** Entity identifer */
  id: Scalars['ID'];
  /** Most recent ISO date time that the student clicked the link */
  mostRecentClick?: Maybe<Scalars['String']>;
  /** The educator that recommended the resource */
  recommendedBy: Educator;
  /** AKA Learn UUID */
  recommendedByUuid: Scalars['ID'];
  /** AKA Learn UUID */
  recommendedToUuid: Scalars['ID'];
  /** The title of the recommended resource */
  title: Scalars['String'];
  /** Timestamp */
  updatedAt: Scalars['ISO8601DateTime'];
  /** The URL of the recommended resource */
  url: Scalars['String'];
};

/** Student clicks on a recommendation */
export type RecommendationClick = {
  __typename?: 'RecommendationClick';
  /** Time of click event */
  clickedAt: Scalars['ISO8601DateTime'];
  /** Entity identifer */
  id: Scalars['ID'];
  /** ID of the recommendation clicked */
  recommendationId: Scalars['Int'];
  /** Unique identifier for the click */
  uuid: Scalars['String'];
};

/** Autogenerated input type of RemoveFileFromRequest */
export type RemoveFileFromRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileUploadUuid: Scalars['ID'];
};

/** Autogenerated input type of RemoveFileUploadRequest */
export type RemoveFileUploadRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileUploadUuid: Scalars['ID'];
};

/** Autogenerated input type of RemoveRequirement */
export type RemoveRequirementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The recipient uuid. */
  recipientUuid: Scalars['ID'];
  /** Requirement to be marked as completed */
  requirementUuid: Scalars['ID'];
};

/** Autogenerated input type of RetireDocumentSet */
export type RetireDocumentSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

/** Autogenerated input type of RetireDocusignTemplate */
export type RetireDocusignTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

/** Autogenerated input type of RetireFileUpload */
export type RetireFileUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

/** Autogenerated input type of SetDocumentSetsForCohort */
export type SetDocumentSetsForCohortInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cohortId: Scalars['ID'];
  documentSetIds: Array<Scalars['ID']>;
};

/** Autogenerated input type of SetDocumentSetsForRecipientGroup */
export type SetDocumentSetsForRecipientGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentSetIds: Array<Scalars['ID']>;
  recipientGroupId: Scalars['ID'];
};

/** Autogenerated input type of SetFileUploadTypesForDocumentSet */
export type SetFileUploadTypesForDocumentSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentSetId: Scalars['ID'];
  fileUploadTypeIds: Array<Scalars['ID']>;
};

/** All objects inherit from this class. */
export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  studentUuid: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

/** All objects inherit from this class. */
export type Student = UserInterface & {
  __typename?: 'Student';
  dataAccessCode: Scalars['String'];
  /** Email address */
  email?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  /** Notes about the student from the Portal */
  legacyNotes: Array<LegacyNote>;
  oauthCode: Scalars['String'];
  /** @deprecated Learn username should not be used */
  username?: Maybe<Scalars['String']>;
  /** AKA Learn UUID */
  uuid: Scalars['ID'];
  continueLearning?: Maybe<ContinueLearning>;
  /** Scheduled admission interview date */
  admissionsInterviewDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Current calls to action for the student */
  callsToAction: Array<CallToAction>;
  /** Completed student tasks */
  completedStudentTasks: Array<TaskOption>;
  /** First name */
  firstName?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<HomeAddress>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  profile: StudentProfile;
  /** Resume URL */
  resumeUrl?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<ShippingAddress>;
  /** The date the student stopped coaching */
  coachingEndDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date the student started coaching */
  coachingStartDate?: Maybe<Scalars['ISO8601Date']>;
  /** The student's current coaching status */
  coachingStatus?: Maybe<CoachingStatus>;
  /** The student's current coaching tier */
  coachingTier?: Maybe<CoachingTier>;
  /** List of fellowships associated with the student */
  fellowships: Array<Fellowship>;
  /** The reason the student withdrew */
  withdrawalReason?: Maybe<Scalars['String']>;
  /** The student's attendance record for Accountability Sessions categorized as Advisor Check-in */
  advisorCheckInAttendanceRecord?: Maybe<AccountabilitySessionAttendanceRecord>;
  /** The student's attendance record for all Accountability Sessions */
  attendanceRecord?: Maybe<AccountabilitySessionAttendanceRecord>;
  /** The student's attendance record for Career Coach Accountability Sessions */
  coachAccountabilityAttendanceRecord?: Maybe<AccountabilitySessionAttendanceRecord>;
  /** The student's assigned educator */
  educator?: Maybe<Educator>;
  /** The student's educators */
  educators: Array<Educator>;
  /** Most recent note about the student */
  mostRecentNote?: Maybe<Note>;
  /** Notes about the student */
  notes: Array<Note>;
  /** Resources recommended to the student */
  recommendations: Array<Recommendation>;
  complianceRequirements: Array<ComplianceRequirement>;
  fileUploads: Array<FileUpload>;
  availableLiveEvents: AvailableLiveEvents;
  savedLiveEvents: Array<LiveEvent>;
  /** The student's admission record */
  admission?: Maybe<Admission>;
  /** The student's active institution */
  institution?: Maybe<Institution>;
  /** The student's active cohort. */
  cohort?: Maybe<Cohort>;
  /** The student's gross tuition */
  grossTuition?: Maybe<Scalars['String']>;
  /** The student's location of study (ie. state) */
  locationOfStudy?: Maybe<Scalars['String']>;
  /** The student's most recent graduation (really just the graduation date) */
  graduated?: Maybe<Graduated>;
  /** A list of academic clearances for the student */
  academicClearances?: Maybe<Array<Maybe<AcademicClearance>>>;
  /** The students program status, e.g. prospective, committed, active, alumni */
  programStatus?: Maybe<Scalars['String']>;
  chatLink?: Maybe<Scalars['String']>;
  courses: CourseConnection;
  milestones: Array<CanvasMilestone>;
  startedPrework: Scalars['Boolean'];
};

/** All objects inherit from this class. */
export type StudentAvailableLiveEventsArgs = {
  filters: LiveEventFilterInput;
  limit?: Maybe<Scalars['Int']>;
};

/** All objects inherit from this class. */
export type StudentSavedLiveEventsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

/** All objects inherit from this class. */
export type StudentCoursesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Student. */
export type StudentConnection = {
  __typename?: 'StudentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StudentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Student>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count of all connected items */
  totalCount: Scalars['Int'];
};

/** All edges inherit from this class. */
export type StudentEdge = {
  __typename?: 'StudentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Student>;
};

/** Authorized huntr member type */
export type StudentHuntrMember = {
  __typename?: 'StudentHuntrMember';
  /** Timestamp */
  createdAt: Scalars['ISO8601DateTime'];
  /** External id on Huntr */
  externalMemberId: Scalars['String'];
};

/** All objects inherit from this class. */
export type StudentProfile = {
  __typename?: 'StudentProfile';
  /** A link to the student's blog */
  blogUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** Date of birth */
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  /** Boolean for participation in Daylight Savings Time */
  daylightSavingsTime: Scalars['Boolean'];
  /** Highest level of education compeleted */
  educationalBackground?: Maybe<Scalars['String']>;
  /** Emergency contact's email */
  emergencyContactEmail?: Maybe<Scalars['String']>;
  /** Full name of emergency contact */
  emergencyContactName?: Maybe<Scalars['String']>;
  /** Emergency contact's phone number */
  emergencyContactPhone?: Maybe<Scalars['String']>;
  /** Emergency contact's relationship to student */
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  /** Ethnicity according to U.S. Census identifiers */
  ethnicity?: Maybe<Scalars['String']>;
  /** Emergency contact's relationship to student */
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Additional identity terms */
  identityTerms?: Maybe<Array<IdentityTerm>>;
  /** Gender identity */
  legalName?: Maybe<Scalars['String']>;
  /** Light Mode setting */
  lightMode?: Maybe<Scalars['Boolean']>;
  /** A link to the student's LinkedIn profile */
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  /** Military Status */
  militaryStatus?: Maybe<Scalars['String']>;
  /** A link to the student's portfolio site */
  portfolioSiteUrl?: Maybe<Scalars['String']>;
  /** List of Post-secondary education information */
  postSecondaryEducation?: Maybe<Scalars['JSON']>;
  /** Preferred name */
  preferredFirstName?: Maybe<Scalars['String']>;
  /** Preferred pronouns */
  preferredPronouns?: Maybe<Scalars['String']>;
  /** @deprecated Use races field instead */
  race?: Maybe<Scalars['String']>;
  /** Racial identity */
  races?: Maybe<Array<Scalars['String']>>;
  /** A link to the student's Skilled mock interview feedback */
  skilledInterviewFeedbackUrl?: Maybe<Scalars['String']>;
  /** A link to the student's Skilled mentor session feedback */
  skilledMentorFeedbackUrl?: Maybe<Scalars['String']>;
  studentUuid: Scalars['String'];
  /** Timezone */
  timezone: Scalars['String'];
  /** T-shirt size */
  tshirtSize?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Named tags for content */
export type Tag = {
  __typename?: 'Tag';
  /** The name of the tag */
  name: Scalars['String'];
  /** The tag's UUID */
  uuid: Scalars['ID'];
};

/** All objects inherit from this class. */
export type TaskOption = {
  __typename?: 'TaskOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** All objects inherit from this class. */
export type TemplateKind = {
  __typename?: 'TemplateKind';
  isReviewOnly: Scalars['Boolean'];
  name: Scalars['String'];
  uuid: Scalars['ID'];
};

/** Autogenerated input type of ToggleContentBookmarked */
export type ToggleContentBookmarkedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The UUID of the content being toggled. */
  contentUuid: Scalars['String'];
};

/** Autogenerated input type of ToggleSaveLiveEvent */
export type ToggleSaveLiveEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The UUID of the live event being toggled. */
  liveEventUuid: Scalars['String'];
};

/** Autogenerated return type of ToggleSaveLiveEvent */
export type ToggleSaveLiveEventPayload = {
  __typename?: 'ToggleSaveLiveEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  liveEvent: LiveEvent;
};

/** Autogenerated input type of UpdateIronboardProfile */
export type UpdateIronboardProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/** Input for updating note */
export type UpdateNoteInput = {
  /** Accountability Session data */
  accountabilitySession?: Maybe<AccountabilitySessionInput>;
  /** Student aptitude score */
  aptitude?: Maybe<Scalars['Int']>;
  /** Student attitude score */
  attitude?: Maybe<Scalars['Int']>;
  /** Id of associated Category */
  categoryId?: Maybe<Scalars['Int']>;
  /** The note body as HTML */
  contentHtml: Scalars['String'];
  /** The note body as plain text */
  contentText: Scalars['String'];
  /** Entity identifer */
  id: Scalars['ID'];
  /** AKA Learn UUID */
  studentUuid: Scalars['String'];
};

/** Autogenerated input type of UpdateStudent */
export type UpdateStudentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  passwordConfirmation?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateStudent */
export type UpdateStudentPayload = {
  __typename?: 'UpdateStudentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  me?: Maybe<Student>;
};

/** Autogenerated input type of UploadFile */
export type UploadFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileUrl: Scalars['String'];
  studentUuid: Scalars['ID'];
  typeId: Scalars['ID'];
};

/** Generic user profile information */
export type UserInterface = {
  /** Email address */
  email?: Maybe<Scalars['String']>;
  /** AKA Learn UUID */
  uuid: Scalars['ID'];
};

/** A record of a user viewing content */
export type View = {
  __typename?: 'View';
  /** The viewed content */
  content: Content;
  /** The viewed content's UUID */
  contentUuid: Scalars['ID'];
  /** Timestamp of first viewed. */
  createdAt: Scalars['ISO8601DateTime'];
  /** The view record's UUID */
  uuid: Scalars['ID'];
  /** The viewer's UUID */
  viewedByUuid: Scalars['ID'];
};

export type AddRecipientsToRecipientGroupMutationVariables = Exact<{
  input: AddRecipientsToRecipientGroupInput;
}>;

export type AddRecipientsToRecipientGroupMutation = {
  __typename?: 'Mutation';
} & {
  addRecipientsToRecipientGroup?: Maybe<
    { __typename?: 'RecipientGroup' } & Pick<
      RecipientGroup,
      'uuid' | 'name'
    > & {
        recipientsDeprecated: Array<
          { __typename?: 'Student' } & Pick<
            Student,
            'uuid' | 'firstName' | 'lastName' | 'avatarUrl'
          > & {
              complianceRequirements: Array<
                {
                  __typename?: 'ComplianceRequirement';
                } & ComplianceRequirementFragmentFragment
              >;
              fileUploads: Array<
                { __typename?: 'FileUpload' } & FileUploadFragmentFragment
              >;
            }
        >;
      }
  >;
};

export type AddTemplateToDocumentSetMutationVariables = Exact<{
  input: AddTemplateToDocumentSetInput;
}>;

export type AddTemplateToDocumentSetMutation = { __typename?: 'Mutation' } & {
  addTemplateToDocumentSet?: Maybe<
    { __typename?: 'DocumentSet' } & DocumentSetFragmentFragment
  >;
};

export type ArchiveNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ArchiveNoteMutation = { __typename?: 'Mutation' } & {
  archiveNote: { __typename?: 'Note' } & NoteFragmentFragment;
};

export type ArchiveRecommendationMutationVariables = Exact<{
  input: ArchiveRecommendationInput;
}>;

export type ArchiveRecommendationMutation = { __typename?: 'Mutation' } & {
  archiveRecommendation: {
    __typename?: 'Recommendation';
  } & RecommendationFragmentFragment;
};

export type AssignRequirementFromEnvelopeMutationVariables = Exact<{
  input: AssignRequirementFromEnvelopeInput;
}>;

export type AssignRequirementFromEnvelopeMutation = {
  __typename?: 'Mutation';
} & {
  assignRequirementFromEnvelope?: Maybe<
    {
      __typename?: 'ComplianceRequirement';
    } & ComplianceRequirementFragmentFragment
  >;
};

export type CompleteFileUploadMutationVariables = Exact<{
  input: CompleteFileUploadInput;
}>;

export type CompleteFileUploadMutation = { __typename?: 'Mutation' } & {
  completeFileUpload?: Maybe<
    { __typename?: 'FileUpload' } & FileUploadFragmentFragment
  >;
};

export type CompleteRequirementMutationVariables = Exact<{
  input: CompleteRequirementInput;
}>;

export type CompleteRequirementMutation = { __typename?: 'Mutation' } & {
  completeRequirement?: Maybe<
    {
      __typename?: 'ComplianceRequirement';
    } & ComplianceRequirementFragmentFragment
  >;
};

export type CreateDocumentSetMutationVariables = Exact<{
  input: CreateDocumentSetInput;
}>;

export type CreateDocumentSetMutation = { __typename?: 'Mutation' } & {
  createDocumentSet?: Maybe<
    { __typename?: 'DocumentSet' } & DocumentSetFragmentFragment
  >;
};

export type CreateDocusignTemplateMutationVariables = Exact<{
  input: CreateDocusignTemplateInput;
}>;

export type CreateDocusignTemplateMutation = { __typename?: 'Mutation' } & {
  createDocusignTemplate?: Maybe<
    { __typename?: 'DocusignTemplate' } & DocusignTemplateFragmentFragment
  >;
};

export type CreateTemplateKindMutationVariables = Exact<{
  input: CreateTemplateKindInput;
}>;

export type CreateTemplateKindMutation = { __typename?: 'Mutation' } & {
  createTemplateKind?: Maybe<
    { __typename?: 'TemplateKind' } & Pick<
      TemplateKind,
      'uuid' | 'name' | 'isReviewOnly'
    >
  >;
};

export type CreateFileUploadMutationVariables = Exact<{
  input: CreateFileUploadInput;
}>;

export type CreateFileUploadMutation = { __typename?: 'Mutation' } & {
  createFileUpload?: Maybe<
    { __typename?: 'FileUpload' } & Pick<FileUpload, 'uuid' | 'assignedAt'> & {
        fileUploadType: { __typename?: 'FileUploadType' } & Pick<
          FileUploadType,
          'uuid' | 'name' | 'visibleToStudent'
        >;
      }
  >;
};

export type CreateFileUploadTypeMutationVariables = Exact<{
  input: CreateFileUploadTypesInput;
}>;

export type CreateFileUploadTypeMutation = { __typename?: 'Mutation' } & {
  createFileUploadType?: Maybe<
    { __typename?: 'FileUploadType' } & Pick<
      FileUploadType,
      'name' | 'uuid' | 'visibleToStudent' | 'studentCount'
    > & {
        associatedCohorts: Array<
          { __typename?: 'CohortSimple' } & Pick<CohortSimple, 'name' | 'uuid'>
        >;
        associatedRecipientGroups: Array<
          { __typename?: 'RecipientGroup' } & RecipientGroupFramgmentFragment
        >;
      }
  >;
};

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;

export type CreateNoteMutation = { __typename?: 'Mutation' } & {
  createNote: { __typename?: 'Note' } & NoteFragmentFragment;
};

export type CreateRecipientGroupMutationVariables = Exact<{
  input: CreateRecipientGroupInput;
}>;

export type CreateRecipientGroupMutation = { __typename?: 'Mutation' } & {
  createRecipientGroup?: Maybe<
    { __typename?: 'RecipientGroup' } & RecipientGroupFramgmentFragment
  >;
};

export type CreateRecommendationMutationVariables = Exact<{
  input: CreateRecommendationInput;
}>;

export type CreateRecommendationMutation = { __typename?: 'Mutation' } & {
  createRecommendation: {
    __typename?: 'Recommendation';
  } & RecommendationFragmentFragment;
};

export type CreateRecommendationClickMutationVariables = Exact<{
  input: CreateRecommendationClickInput;
}>;

export type CreateRecommendationClickMutation = { __typename?: 'Mutation' } & {
  createRecommendationClick: { __typename?: 'RecommendationClick' } & Pick<
    RecommendationClick,
    'id'
  >;
};

export type LinkCohortsWithDocumentSetMutationVariables = Exact<{
  input: LinkCohortsWithDocumentSetInput;
}>;

export type LinkCohortsWithDocumentSetMutation = { __typename?: 'Mutation' } & {
  linkCohortsWithDocumentSet?: Maybe<
    { __typename?: 'DocumentSet' } & DocumentSetFragmentFragment
  >;
};

export type MarkContentViewedMutationVariables = Exact<{
  input: MarkContentViewedInput;
}>;

export type MarkContentViewedMutation = { __typename?: 'Mutation' } & {
  markContentViewed?: Maybe<
    { __typename?: 'View' } & Pick<View, 'uuid'> & {
        content: { __typename?: 'Content' } & Pick<Content, 'uuid'> & {
            view?: Maybe<{ __typename?: 'View' } & Pick<View, 'uuid'>>;
          };
      }
  >;
};

export type PendingRequirementMutationVariables = Exact<{
  input: PendingRequirementInput;
}>;

export type PendingRequirementMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'pendingRequirement'
>;

export type RemoveFileFromRequestMutationVariables = Exact<{
  input: RemoveFileFromRequestInput;
}>;

export type RemoveFileFromRequestMutation = { __typename?: 'Mutation' } & {
  removeFileFromRequest?: Maybe<
    { __typename?: 'FileUpload' } & FileUploadFragmentFragment
  >;
};

export type RemoveFileUploadRequestMutationVariables = Exact<{
  input: RemoveFileUploadRequestInput;
}>;

export type RemoveFileUploadRequestMutation = { __typename?: 'Mutation' } & {
  removeFileUploadRequest?: Maybe<
    { __typename?: 'FileUpload' } & FileUploadFragmentFragment
  >;
};

export type RemoveRequirementMutationVariables = Exact<{
  input: RemoveRequirementInput;
}>;

export type RemoveRequirementMutation = { __typename?: 'Mutation' } & {
  removeRequirement?: Maybe<
    {
      __typename?: 'ComplianceRequirement';
    } & ComplianceRequirementFragmentFragment
  >;
};

export type RetireDocumentSetMutationVariables = Exact<{
  input: RetireDocumentSetInput;
}>;

export type RetireDocumentSetMutation = { __typename?: 'Mutation' } & {
  retireDocumentSet?: Maybe<
    { __typename?: 'DocumentSet' } & Pick<DocumentSet, 'uuid' | 'retiredAt'>
  >;
};

export type RetireDocusignTemplateMutationVariables = Exact<{
  input: RetireDocusignTemplateInput;
}>;

export type RetireDocusignTemplateMutation = { __typename?: 'Mutation' } & {
  retireDocusignTemplate?: Maybe<
    { __typename?: 'DocusignTemplate' } & DocusignTemplateFragmentFragment
  >;
};

export type RetireFileUploadTypeMutationVariables = Exact<{
  input: RetireFileUploadInput;
}>;

export type RetireFileUploadTypeMutation = { __typename?: 'Mutation' } & {
  retireFileUploadType?: Maybe<
    { __typename?: 'FileUploadType' } & Pick<
      FileUploadType,
      'uuid' | 'retiredAt'
    >
  >;
};

export type SetDocumentSetsForCohortMutationVariables = Exact<{
  input: SetDocumentSetsForCohortInput;
}>;

export type SetDocumentSetsForCohortMutation = { __typename?: 'Mutation' } & {
  setDocumentSetsForCohort?: Maybe<
    { __typename?: 'CohortSimple' } & Pick<CohortSimple, 'uuid'>
  >;
};

export type SetDocumentSetsForRecipientGroupMutationVariables = Exact<{
  input: SetDocumentSetsForRecipientGroupInput;
}>;

export type SetDocumentSetsForRecipientGroupMutation = {
  __typename?: 'Mutation';
} & {
  setDocumentSetsForRecipientGroup?: Maybe<
    { __typename?: 'RecipientGroup' } & Pick<RecipientGroup, 'uuid'>
  >;
};

export type SetFileUploadTypesForDocumentSetMutationVariables = Exact<{
  input: SetFileUploadTypesForDocumentSetInput;
}>;

export type SetFileUploadTypesForDocumentSetMutation = {
  __typename?: 'Mutation';
} & {
  setFileUploadTypesForDocumentSet?: Maybe<
    { __typename?: 'DocumentSet' } & Pick<DocumentSet, 'uuid'> & {
        fileUploadTypes: Array<
          { __typename?: 'FileUploadType' } & Pick<
            FileUploadType,
            'uuid' | 'name'
          >
        >;
      }
  >;
};

export type ToggleContentBookmarkedMutationVariables = Exact<{
  input: ToggleContentBookmarkedInput;
}>;

export type ToggleContentBookmarkedMutation = { __typename?: 'Mutation' } & {
  toggleContentBookmarked?: Maybe<
    { __typename?: 'Content' } & Pick<Content, 'uuid'> & {
        bookmark?: Maybe<{ __typename?: 'Bookmark' } & Pick<Bookmark, 'uuid'>>;
      }
  >;
};

export type ToggleSaveLiveEventMutationVariables = Exact<{
  input: ToggleSaveLiveEventInput;
}>;

export type ToggleSaveLiveEventMutation = { __typename?: 'Mutation' } & {
  toggleSaveLiveEvent?: Maybe<
    { __typename?: 'ToggleSaveLiveEventPayload' } & Pick<
      ToggleSaveLiveEventPayload,
      'errors'
    > & {
        liveEvent: { __typename?: 'LiveEvent' } & Pick<
          LiveEvent,
          'uuid' | 'saved'
        >;
      }
  >;
};

export type TrackEngagementMutationVariables = Exact<{
  callToActionId: Scalars['ID'];
}>;

export type TrackEngagementMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'trackEngagement'
>;

export type UpdateAccountInformationMutationVariables = Exact<{
  studentInput: UpdateStudentInput;
  avatarUrl?: Maybe<Scalars['String']>;
  blogUrl?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  daylightSavingsTime: Scalars['Boolean'];
  educationalBackground?: Maybe<Scalars['String']>;
  emergencyContactEmail?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  identities?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  militaryStatus?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  portfolioSiteUrl?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredPronouns?: Maybe<Scalars['String']>;
  races?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  timezone: Scalars['String'];
  tshirtSize?: Maybe<Scalars['String']>;
  homeAddressLine1?: Maybe<Scalars['String']>;
  homeAddressLine2?: Maybe<Scalars['String']>;
  homeCity?: Maybe<Scalars['String']>;
  homeState?: Maybe<Scalars['String']>;
  homePostalCode?: Maybe<Scalars['String']>;
  shippingAddressLine1?: Maybe<Scalars['String']>;
  shippingAddressLine2?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingState?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  skilledInterviewFeedbackUrl?: Maybe<Scalars['String']>;
  skilledMentorFeedbackUrl?: Maybe<Scalars['String']>;
}>;

export type UpdateAccountInformationMutation = { __typename?: 'Mutation' } & {
  updateStudent?: Maybe<
    { __typename?: 'UpdateStudentPayload' } & Pick<
      UpdateStudentPayload,
      'errors'
    > & {
        me?: Maybe<
          { __typename?: 'Student' } & Pick<
            Student,
            'avatarUrl' | 'email' | 'uuid'
          >
        >;
      }
  >;
  updateStudentProfile: { __typename?: 'StudentProfile' } & Pick<
    StudentProfile,
    | 'daylightSavingsTime'
    | 'educationalBackground'
    | 'gender'
    | 'id'
    | 'militaryStatus'
    | 'preferredFirstName'
    | 'preferredPronouns'
    | 'races'
    | 'studentUuid'
    | 'timezone'
    | 'tshirtSize'
  > & {
      identityTerms?: Maybe<
        Array<
          { __typename?: 'IdentityTerm' } & Pick<
            IdentityTerm,
            'id' | 'category'
          >
        >
      >;
    };
  updateHomeAddress: { __typename?: 'HomeAddress' } & Pick<
    HomeAddress,
    'city' | 'id' | 'line1' | 'line2' | 'state' | 'zipcode'
  >;
  updateShippingAddress: { __typename?: 'ShippingAddress' } & Pick<
    ShippingAddress,
    'city' | 'id' | 'line1' | 'line2' | 'state' | 'zipcode'
  >;
};

export type UpdateNoteMutationVariables = Exact<{
  input: UpdateNoteInput;
}>;

export type UpdateNoteMutation = { __typename?: 'Mutation' } & {
  updateNote: { __typename?: 'Note' } & NoteFragmentFragment;
};

export type UpdateShippingAddressMutationVariables = Exact<{
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['String'];
}>;

export type UpdateShippingAddressMutation = { __typename?: 'Mutation' } & {
  updateShippingAddress: { __typename?: 'ShippingAddress' } & Pick<
    ShippingAddress,
    'id' | 'city' | 'country' | 'line1' | 'line2' | 'state' | 'zipcode'
  >;
};

export type UpdateStudentMutationVariables = Exact<{
  input: UpdateStudentInput;
}>;

export type UpdateStudentMutation = { __typename?: 'Mutation' } & {
  updateStudent?: Maybe<
    { __typename?: 'UpdateStudentPayload' } & {
      me?: Maybe<
        { __typename?: 'Student' } & Pick<
          Student,
          'uuid' | 'avatarUrl' | 'email' | 'phoneNumber'
        >
      >;
    }
  >;
};

export type UpdateStudentLightModeMutationVariables = Exact<{
  lightMode: Scalars['Boolean'];
}>;

export type UpdateStudentLightModeMutation = { __typename?: 'Mutation' } & {
  updateStudentLightMode: { __typename?: 'StudentProfile' } & Pick<
    StudentProfile,
    'lightMode'
  >;
};

export type UploadFileMutationVariables = Exact<{
  input: UploadFileInput;
}>;

export type UploadFileMutation = { __typename?: 'Mutation' } & {
  uploadFile?: Maybe<
    { __typename?: 'FileUpload' } & FileUploadFragmentFragment
  >;
};

export type CategoryFragmentFragment = { __typename?: 'Category' } & Pick<
  Category,
  'id' | 'accountabilitySession' | 'name'
>;

export type ComplianceRequirementFragmentFragment = {
  __typename?: 'ComplianceRequirement';
} & Pick<
  ComplianceRequirement,
  | 'uuid'
  | 'title'
  | 'kind'
  | 'envelopeId'
  | 'assignedAt'
  | 'completedAt'
  | 'removedAt'
  | 'status'
>;

export type ContentFragmentFragment = { __typename?: 'Content' } & Pick<
  Content,
  | 'uuid'
  | 'embedUrl'
  | 'imageUrl'
  | 'format'
  | 'type'
  | 'slug'
  | 'title'
  | 'url'
> & {
    bookmark?: Maybe<{ __typename?: 'Bookmark' } & Pick<Bookmark, 'uuid'>>;
    disciplines?: Maybe<
      Array<
        { __typename?: 'ProgramDiscipline' } & Pick<
          ProgramDiscipline,
          'uuid' | 'name'
        >
      >
    >;
    phases?: Maybe<
      Array<
        { __typename?: 'ProgramPhase' } & Pick<
          ProgramPhase,
          'uuid' | 'disciplineUuid' | 'name'
        >
      >
    >;
    tags?: Maybe<Array<{ __typename?: 'Tag' } & Pick<Tag, 'uuid' | 'name'>>>;
    view?: Maybe<{ __typename?: 'View' } & Pick<View, 'uuid'>>;
  };

export type DocumentSetFragmentFragment = { __typename?: 'DocumentSet' } & Pick<
  DocumentSet,
  'uuid' | 'name' | 'createdAt'
> & {
    documentSetTemplates: Array<
      { __typename?: 'DocumentSetTemplate' } & Pick<
        DocumentSetTemplate,
        'uuid' | 'retiredAt' | 'createdAt'
      > & {
          docusignTemplate: { __typename?: 'DocusignTemplate' } & Pick<
            DocusignTemplate,
            'uuid' | 'title' | 'internalName' | 'externalId' | 'createdAt'
          > & {
              templateKind: { __typename?: 'TemplateKind' } & Pick<
                TemplateKind,
                'uuid' | 'name'
              >;
            };
        }
    >;
  };

export type DocusignTemplateFragmentFragment = {
  __typename?: 'DocusignTemplate';
} & Pick<
  DocusignTemplate,
  'uuid' | 'title' | 'internalName' | 'externalId' | 'createdAt'
> & {
    templateKind: { __typename?: 'TemplateKind' } & Pick<
      TemplateKind,
      'uuid' | 'name' | 'isReviewOnly'
    >;
  };

export type EducatorFragmentFragment = { __typename?: 'Educator' } & Pick<
  Educator,
  'avatarUrl' | 'firstName' | 'lastName' | 'uuid' | 'availabilityScheduleUrl'
>;

export type LegacyNoteFragmentFragment = { __typename?: 'LegacyNote' } & Pick<
  LegacyNote,
  'id' | 'contentHtml' | 'createdAt'
> & { author: { __typename?: 'Educator' } & EducatorFragmentFragment };

export type LiveEventFragmentFragment = { __typename?: 'LiveEvent' } & Pick<
  LiveEvent,
  | 'uuid'
  | 'liveEventTypes'
  | 'inPersonLocation'
  | 'location'
  | 'mainImageUrl'
  | 'programDisciplines'
  | 'programModules'
  | 'programPhases'
  | 'remoteMeetingLink'
  | 'saved'
  | 'scheduledDate'
  | 'scheduledDurationSeconds'
  | 'speaker'
  | 'description'
  | 'title'
  | 'slug'
>;

export type LmsCourseLinksFragmentFragment = { __typename?: 'Student' } & {
  courses: { __typename?: 'CourseConnection' } & {
    edges?: Maybe<
      Array<
        Maybe<
          { __typename?: 'CourseEdge' } & Pick<CourseEdge, 'cursor'> & {
              node?: Maybe<
                { __typename?: 'Course' } & Pick<
                  Course,
                  | 'name'
                  | 'progressPercentage'
                  | 'totalTasks'
                  | 'completedTasks'
                  | 'lastAccessDate'
                  | 'courseUrl'
                >
              >;
            }
        >
      >
    >;
  };
};

export type NoteFragmentFragment = { __typename?: 'Note' } & Pick<
  Note,
  | 'id'
  | 'aptitude'
  | 'attitude'
  | 'categoryId'
  | 'contentHtml'
  | 'contentText'
  | 'createdAt'
> & {
    accountabilitySession?: Maybe<
      { __typename?: 'AccountabilitySession' } & Pick<
        AccountabilitySession,
        'id' | 'attended' | 'date'
      >
    >;
    author: { __typename?: 'Educator' } & Pick<
      Educator,
      'avatarUrl' | 'firstName' | 'lastName' | 'uuid'
    >;
  };

export type RecommendationFragmentFragment = {
  __typename?: 'Recommendation';
} & Pick<
  Recommendation,
  | 'createdAt'
  | 'detail'
  | 'id'
  | 'mostRecentClick'
  | 'recommendedByUuid'
  | 'recommendedToUuid'
  | 'title'
  | 'updatedAt'
  | 'url'
> & {
    recommendedBy: { __typename?: 'Educator' } & Pick<
      Educator,
      'uuid' | 'avatarUrl' | 'firstName' | 'lastName'
    >;
  };

export type FileUploadFragmentFragment = { __typename?: 'FileUpload' } & Pick<
  FileUpload,
  | 'assignedAt'
  | 'assignedBy'
  | 'completedAt'
  | 'completedBy'
  | 'fileName'
  | 'fileUrl'
  | 'removedAt'
  | 'removedBy'
  | 'createdAt'
  | 'updatedAt'
  | 'uuid'
> & {
    fileUploadType: { __typename?: 'FileUploadType' } & Pick<
      FileUploadType,
      'uuid' | 'name' | 'visibleToStudent'
    >;
  };

export type MilestonesQueryFragment = { __typename?: 'Student' } & Pick<
  Student,
  'uuid' | 'firstName'
> & {
    milestones: Array<
      { __typename?: 'CanvasMilestone' } & Pick<
        CanvasMilestone,
        | 'dueOn'
        | 'id'
        | 'name'
        | 'completedAt'
        | 'htmlUrl'
        | 'canvasAssignmentExternalId'
      >
    >;
    cohort?: Maybe<
      { __typename?: 'Cohort' } & Pick<Cohort, 'startDate' | 'endDate'>
    >;
  };

export type RecipientGroupFramgmentFragment = {
  __typename?: 'RecipientGroup';
} & Pick<RecipientGroup, 'uuid' | 'name'>;

export type RecommendedContentFragmentFragment = { __typename?: 'Student' } & {
  recommendations: Array<
    { __typename?: 'Recommendation' } & Pick<
      Recommendation,
      'createdAt' | 'detail' | 'id' | 'title' | 'url'
    > & {
        recommendedBy: { __typename?: 'Educator' } & EducatorFragmentFragment;
      }
  >;
};

export type ShippingAddressFragmentFragment = { __typename?: 'Student' } & {
  shippingAddress?: Maybe<
    { __typename?: 'ShippingAddress' } & Pick<
      ShippingAddress,
      'id' | 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'
    >
  >;
};

export type StudentFragmentFragment = { __typename?: 'Student' } & Pick<
  Student,
  | 'avatarUrl'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'programStatus'
  | 'uuid'
> & {
    cohort?: Maybe<
      { __typename?: 'Cohort' } & Pick<
        Cohort,
        'name' | 'startDate' | 'endDate' | 'uuid' | 'disciplineName'
      > & {
          courseOffering: { __typename?: 'CourseOffering' } & {
            discipline: { __typename?: 'Discipline' } & Pick<
              Discipline,
              'name'
            >;
            pacingOption: { __typename?: 'PacingOption' } & Pick<
              PacingOption,
              'name'
            >;
          };
        }
    >;
    profile: { __typename?: 'StudentProfile' } & Pick<
      StudentProfile,
      'id' | 'preferredPronouns' | 'timezone'
    >;
  };

export type FeatureAccountInformationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FeatureAccountInformationQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    | 'uuid'
    | 'email'
    | 'avatarUrl'
    | 'firstName'
    | 'lastName'
    | 'phoneNumber'
    | 'programStatus'
  > & {
      cohort?: Maybe<
        { __typename?: 'Cohort' } & Pick<Cohort, 'name' | 'uuid'> & {
            courseOffering: { __typename?: 'CourseOffering' } & Pick<
              CourseOffering,
              'name'
            >;
          }
      >;
      profile: { __typename?: 'StudentProfile' } & Pick<
        StudentProfile,
        | 'blogUrl'
        | 'dateOfBirth'
        | 'daylightSavingsTime'
        | 'educationalBackground'
        | 'ethnicity'
        | 'emergencyContactEmail'
        | 'emergencyContactName'
        | 'emergencyContactPhone'
        | 'emergencyContactRelationship'
        | 'gender'
        | 'id'
        | 'linkedInProfileUrl'
        | 'militaryStatus'
        | 'portfolioSiteUrl'
        | 'preferredFirstName'
        | 'preferredPronouns'
        | 'races'
        | 'skilledInterviewFeedbackUrl'
        | 'skilledMentorFeedbackUrl'
        | 'timezone'
        | 'tshirtSize'
      > & {
          identityTerms?: Maybe<
            Array<
              { __typename?: 'IdentityTerm' } & Pick<
                IdentityTerm,
                'category' | 'id'
              >
            >
          >;
        };
      shippingAddress?: Maybe<
        { __typename?: 'ShippingAddress' } & Pick<
          ShippingAddress,
          'id' | 'line1' | 'line2' | 'city' | 'state' | 'zipcode'
        >
      >;
      homeAddress?: Maybe<
        { __typename?: 'HomeAddress' } & Pick<
          HomeAddress,
          'id' | 'line1' | 'line2' | 'city' | 'state' | 'zipcode'
        >
      >;
    };
};

export type FeatureContinueLearningQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FeatureContinueLearningQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> &
    LmsCourseLinksFragmentFragment;
};

export type FeatureDiscoverContentDetailsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type FeatureDiscoverContentDetailsQuery = { __typename?: 'Query' } & {
  content: { __typename?: 'Content' } & Pick<
    Content,
    'description' | 'embedUrl'
  > &
    ContentFragmentFragment;
};

export type FeatureDiscoverContentsQueryVariables = Exact<{
  filters: ContentFilterInput;
  limit?: Maybe<Scalars['Int']>;
}>;

export type FeatureDiscoverContentsQuery = { __typename?: 'Query' } & {
  contents?: Maybe<Array<{ __typename?: 'Content' } & ContentFragmentFragment>>;
};

export type FeatureDiscoverFilterOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FeatureDiscoverFilterOptionsQuery = { __typename?: 'Query' } & {
  contentFilterOptions: { __typename?: 'ContentFilterOptions' } & {
    disciplines?: Maybe<
      Array<
        { __typename?: 'ProgramDiscipline' } & Pick<
          ProgramDiscipline,
          'uuid' | 'name'
        >
      >
    >;
    phases?: Maybe<
      Array<
        { __typename?: 'ProgramPhase' } & Pick<
          ProgramPhase,
          'uuid' | 'name' | 'disciplineUuid'
        >
      >
    >;
    tags?: Maybe<Array<{ __typename?: 'Tag' } & Pick<Tag, 'uuid' | 'name'>>>;
  };
};

export type FeatureDiscoverHighlightsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FeatureDiscoverHighlightsQuery = { __typename?: 'Query' } & {
  contentHighlights: { __typename?: 'ContentHighlights' } & {
    careerContent?: Maybe<
      Array<{ __typename?: 'Content' } & ContentFragmentFragment>
    >;
    communityContent?: Maybe<
      Array<{ __typename?: 'Content' } & ContentFragmentFragment>
    >;
    lectureContent?: Maybe<
      Array<{ __typename?: 'Content' } & ContentFragmentFragment>
    >;
    lectureDiscipline?: Maybe<
      { __typename?: 'ProgramDiscipline' } & Pick<
        ProgramDiscipline,
        'name' | 'uuid'
      >
    >;
  };
};

export type FeatureDocumentsQueryVariables = Exact<{ [key: string]: never }>;

export type FeatureDocumentsQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      complianceRequirements: Array<
        {
          __typename?: 'ComplianceRequirement';
        } & ComplianceRequirementFragmentFragment
      >;
      fileUploads: Array<
        { __typename?: 'FileUpload' } & FileUploadFragmentFragment
      >;
    };
};

export type GetFeatureMilestonesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFeatureMilestonesQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> &
    StudentFragmentFragment;
};

export type GetFeatureMilestonesAvailablePacingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFeatureMilestonesAvailablePacingsQuery = {
  __typename?: 'Query';
} & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      cohort?: Maybe<{ __typename?: 'Cohort' } & Pick<Cohort, 'uuid'>>;
    };
};

export type FeatureRecommendedContentQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FeatureRecommendedContentQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> &
    RecommendedContentFragmentFragment;
};

export type GetSavedLiveEventsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetSavedLiveEventsQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      savedLiveEvents: Array<
        { __typename?: 'LiveEvent' } & LiveEventFragmentFragment
      >;
    };
};

export type GetSensitiveInfomationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSensitiveInfomationQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    'uuid' | 'dataAccessCode'
  >;
};

export type FeatureStudentDocumentsQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type FeatureStudentDocumentsQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    'uuid' | 'firstName' | 'lastName'
  > & {
      complianceRequirements: Array<
        {
          __typename?: 'ComplianceRequirement';
        } & ComplianceRequirementFragmentFragment
      >;
      fileUploads: Array<
        { __typename?: 'FileUpload' } & FileUploadFragmentFragment
      >;
    };
};

export type GetFeatureStudentMilestonesQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetFeatureStudentMilestonesQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & StudentFragmentFragment;
};

export type GetFeatureStudentMilestonesAvailablePacingsQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetFeatureStudentMilestonesAvailablePacingsQuery = {
  __typename?: 'Query';
} & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      cohort?: Maybe<{ __typename?: 'Cohort' } & Pick<Cohort, 'uuid'>>;
    };
};

export type GetRecommendationsQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetRecommendationsQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid' | 'firstName'> & {
      recommendations: Array<
        { __typename?: 'Recommendation' } & RecommendationFragmentFragment
      >;
    };
};

export type GetStudentsComplianceRequirementsQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetStudentsComplianceRequirementsQuery = {
  __typename?: 'Query';
} & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    'firstName' | 'lastName'
  > & {
      complianceRequirements: Array<
        {
          __typename?: 'ComplianceRequirement';
        } & ComplianceRequirementFragmentFragment
      >;
    };
};

export type GetAvailableLiveEventsQueryVariables = Exact<{
  filters: LiveEventFilterInput;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetAvailableLiveEventsQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      availableLiveEvents: { __typename?: 'AvailableLiveEvents' } & {
        filters: { __typename?: 'LiveEventFilters' } & {
          disciplineFilter: { __typename?: 'LiveEventFilter' } & Pick<
            LiveEventFilter,
            'className'
          > & {
              options: Array<
                { __typename?: 'LiveEventFilterOption' } & Pick<
                  LiveEventFilterOption,
                  'name' | 'uuid'
                >
              >;
            };
          phaseFilter: { __typename?: 'LiveEventFilter' } & Pick<
            LiveEventFilter,
            'className'
          > & {
              options: Array<
                { __typename?: 'LiveEventFilterOption' } & Pick<
                  LiveEventFilterOption,
                  'name' | 'uuid'
                >
              >;
            };
          liveEventTypeFilter: { __typename?: 'LiveEventFilter' } & Pick<
            LiveEventFilter,
            'className'
          > & {
              options: Array<
                { __typename?: 'LiveEventFilterOption' } & Pick<
                  LiveEventFilterOption,
                  'name' | 'uuid'
                >
              >;
            };
        };
        liveEvents?: Maybe<
          Array<{ __typename?: 'LiveEvent' } & LiveEventFragmentFragment>
        >;
      };
    };
};

export type GetAdmissionDateQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdmissionDateQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    'admissionsInterviewDate'
  >;
};

export type GetAvatarUploadUrlQueryVariables = Exact<{
  filename: Scalars['String'];
}>;

export type GetAvatarUploadUrlQuery = { __typename?: 'Query' } & {
  getAvatarUploadUrl: { __typename?: 'AvatarUploadUrl' } & Pick<
    AvatarUploadUrl,
    'url'
  > & {
      urlFields: { __typename?: 'AvatarPostParams' } & Pick<
        AvatarPostParams,
        | 'acl'
        | 'key'
        | 'policy'
        | 'xAmzAlgorithm'
        | 'xAmzCredential'
        | 'xAmzDate'
        | 'xAmzSignature'
      >;
    };
};

export type GetCanvasMilestonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCanvasMilestonesQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & MilestonesQueryFragment;
};

export type GetCanvasMilestonesForStudentQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetCanvasMilestonesForStudentQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & MilestonesQueryFragment;
};

export type GetCohortQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetCohortQuery = { __typename?: 'Query' } & {
  cohort?: Maybe<
    { __typename?: 'Cohort' } & Pick<
      Cohort,
      'uuid' | 'name' | 'startDate' | 'endDate'
    > & {
        students?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Student' } & Pick<
                Student,
                'uuid' | 'firstName' | 'lastName' | 'avatarUrl'
              > & {
                  complianceRequirements: Array<
                    {
                      __typename?: 'ComplianceRequirement';
                    } & ComplianceRequirementFragmentFragment
                  >;
                  fileUploads: Array<
                    { __typename?: 'FileUpload' } & FileUploadFragmentFragment
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetCohortsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCohortsQuery = { __typename?: 'Query' } & {
  cohorts?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Cohort' } & Pick<Cohort, 'uuid' | 'name' | 'startDate'>
      >
    >
  >;
};

export type GetComplianceRequirementEnvelopeUrlQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetComplianceRequirementEnvelopeUrlQuery = {
  __typename?: 'Query';
} & Pick<Query, 'complianceRequirementEnvelopeUrl'>;

export type GetCtasQueryVariables = Exact<{ [key: string]: never }>;

export type GetCtasQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & {
    callsToAction: Array<
      { __typename?: 'CallToAction' } & Pick<
        CallToAction,
        'id' | 'text' | 'subText' | 'buttonText' | 'url'
      >
    >;
  };
};

export type GetDocumentSetQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetDocumentSetQuery = { __typename?: 'Query' } & {
  documentSet: { __typename?: 'DocumentSet' } & Pick<
    DocumentSet,
    'retiredAt'
  > & {
      cohorts: Array<
        { __typename?: 'CohortSimple' } & Pick<CohortSimple, 'name' | 'uuid'>
      >;
      recipientGroups: Array<
        { __typename?: 'RecipientGroup' } & Pick<
          RecipientGroup,
          'name' | 'uuid'
        >
      >;
      fileUploadTypes: Array<
        { __typename?: 'FileUploadType' } & Pick<
          FileUploadType,
          'name' | 'uuid' | 'createdAt' | 'visibleToStudent' | 'studentCount'
        >
      >;
    } & DocumentSetFragmentFragment;
};

export type GetDocumentSetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDocumentSetsQuery = { __typename?: 'Query' } & {
  documentSets: Array<
    { __typename?: 'DocumentSet' } & {
      fileUploadTypes: Array<
        { __typename?: 'FileUploadType' } & Pick<
          FileUploadType,
          'name' | 'uuid' | 'createdAt' | 'visibleToStudent'
        >
      >;
    } & DocumentSetFragmentFragment
  >;
};

export type GetDocumentSetsByCohortQueryVariables = Exact<{
  cohortId: Scalars['ID'];
}>;

export type GetDocumentSetsByCohortQuery = { __typename?: 'Query' } & {
  documentSets: Array<
    { __typename?: 'DocumentSet' } & DocumentSetFragmentFragment
  >;
};

export type GetDocusignTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDocusignTemplatesQuery = { __typename?: 'Query' } & {
  docusignTemplates: Array<
    { __typename?: 'DocusignTemplate' } & DocusignTemplateFragmentFragment
  >;
};

export type GetEducatorQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetEducatorQuery = { __typename?: 'Query' } & {
  educator: { __typename?: 'Educator' } & EducatorFragmentFragment;
};

export type GetEducatorRecentNotesQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetEducatorRecentNotesQuery = { __typename?: 'Query' } & {
  educator: { __typename?: 'Educator' } & Pick<Educator, 'uuid'> & {
      students: Array<
        { __typename?: 'Student' } & {
          mostRecentNote?: Maybe<
            { __typename?: 'Note' } & Pick<
              Note,
              | 'id'
              | 'aptitude'
              | 'attitude'
              | 'categoryId'
              | 'createdAt'
              | 'contentText'
            >
          >;
        } & StudentFragmentFragment
      >;
    };
};

export type GetFileUploadTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFileUploadTypesQuery = { __typename?: 'Query' } & {
  fileUploadTypes: Array<
    { __typename?: 'FileUploadType' } & Pick<
      FileUploadType,
      'name' | 'uuid' | 'visibleToStudent' | 'studentCount'
    > & {
        associatedCohorts: Array<
          { __typename?: 'CohortSimple' } & Pick<CohortSimple, 'name' | 'uuid'>
        >;
        associatedRecipientGroups: Array<
          { __typename?: 'RecipientGroup' } & RecipientGroupFramgmentFragment
        >;
      }
  >;
};

export type GetFileUploadUrlQueryVariables = Exact<{
  filename: Scalars['String'];
}>;

export type GetFileUploadUrlQuery = { __typename?: 'Query' } & {
  getFileUploadUrl: { __typename?: 'FileUploadUrl' } & Pick<
    FileUploadUrl,
    'url'
  > & {
      urlFields: { __typename?: 'FileUploadPostParams' } & Pick<
        FileUploadPostParams,
        | 'acl'
        | 'key'
        | 'policy'
        | 'xAmzAlgorithm'
        | 'xAmzCredential'
        | 'xAmzDate'
        | 'xAmzSignature'
      >;
    };
};

export type GetHeaderDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetHeaderDataQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    | 'avatarUrl'
    | 'firstName'
    | 'chatLink'
    | 'lastName'
    | 'uuid'
    | 'programStatus'
  > & {
      cohort?: Maybe<
        { __typename?: 'Cohort' } & Pick<
          Cohort,
          'startDate' | 'endDate' | 'uuid'
        > & {
            courseOffering: { __typename?: 'CourseOffering' } & {
              pacingOption: { __typename?: 'PacingOption' } & Pick<
                PacingOption,
                'name'
              >;
            };
          }
      >;
      educator?: Maybe<
        { __typename?: 'Educator' } & Pick<
          Educator,
          'uuid' | 'firstName' | 'lastName' | 'availabilityScheduleUrl'
        >
      >;
      educators: Array<
        { __typename?: 'Educator' } & Pick<
          Educator,
          'uuid' | 'firstName' | 'lastName' | 'availabilityScheduleUrl'
        >
      >;
      institution?: Maybe<
        { __typename?: 'Institution' } & Pick<Institution, 'id'> & {
            productOfferings: { __typename?: 'ProductOfferingConnection' } & {
              nodes?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ProductOffering' } & Pick<
                      ProductOffering,
                      'id' | 'slug'
                    >
                  >
                >
              >;
            };
          }
      >;
    };
};

export type GetHomePageDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomePageDataQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & StudentFragmentFragment;
};

export type GetHuntrMemberQueryVariables = Exact<{ [key: string]: never }>;

export type GetHuntrMemberQuery = { __typename?: 'Query' } & {
  huntrMember?: Maybe<
    { __typename?: 'HuntrMember' } & Pick<
      HuntrMember,
      'externalMemberId' | 'createdAt'
    >
  >;
};

export type GetLiveEventQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetLiveEventQuery = { __typename?: 'Query' } & {
  getLiveEvent: { __typename?: 'LiveEvent' } & LiveEventFragmentFragment;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & StudentFragmentFragment;
};

export type GetMeGuideQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeGuideQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'Student' } & Pick<
    Student,
    'uuid' | 'firstName' | 'lastName' | 'avatarUrl'
  >;
};

export type GetMilestonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMilestonesQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & StudentFragmentFragment;
};

export type GetMyEducatorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyEducatorsQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      educators: Array<{ __typename?: 'Educator' } & EducatorFragmentFragment>;
    };
};

export type GetMyEducatorsAvailabilityScheduleUrlQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyEducatorsAvailabilityScheduleUrlQuery = {
  __typename?: 'Query';
} & {
  student: { __typename?: 'Student' } & {
    educator?: Maybe<
      { __typename?: 'Educator' } & Pick<Educator, 'availabilityScheduleUrl'>
    >;
  };
};

export type GetNoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetNoteQuery = { __typename?: 'Query' } & {
  getNote?: Maybe<
    { __typename?: 'Note' } & Pick<
      Note,
      'id' | 'aptitude' | 'attitude' | 'contentText' | 'contentHtml'
    > & {
        category?: Maybe<
          { __typename?: 'Category' } & CategoryFragmentFragment
        >;
        accountabilitySession?: Maybe<
          { __typename?: 'AccountabilitySession' } & Pick<
            AccountabilitySession,
            'attended' | 'date'
          >
        >;
      }
  >;
};

export type GetNotesCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetNotesCategoriesQuery = { __typename?: 'Query' } & {
  allCategories: Array<{ __typename?: 'Category' } & CategoryFragmentFragment>;
};

export type GetPaceSelectorDataQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetPaceSelectorDataQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      cohort?: Maybe<{ __typename?: 'Cohort' } & Pick<Cohort, 'uuid'>>;
    };
};

export type GetProgressQueryVariables = Exact<{ [key: string]: never }>;

export type GetProgressQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    'firstName' | 'programStatus' | 'uuid' | 'startedPrework'
  > & {
      cohort?: Maybe<
        { __typename?: 'Cohort' } & Pick<
          Cohort,
          'disciplineName' | 'startDate' | 'endDate'
        > & {
            courseOffering: { __typename?: 'CourseOffering' } & Pick<
              CourseOffering,
              'name'
            >;
          }
      >;
      graduated?: Maybe<
        { __typename?: 'Graduated' } & Pick<Graduated, 'graduatedAt'>
      >;
    };
};

export type GetRecipientGroupQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetRecipientGroupQuery = { __typename?: 'Query' } & {
  recipientGroup: { __typename?: 'RecipientGroup' } & Pick<
    RecipientGroup,
    'uuid' | 'name'
  > & {
      documentSets: Array<
        { __typename?: 'DocumentSet' } & Pick<DocumentSet, 'name' | 'uuid'>
      >;
      recipientsDeprecated: Array<
        { __typename?: 'Student' } & Pick<
          Student,
          'uuid' | 'firstName' | 'lastName' | 'avatarUrl'
        > & {
            complianceRequirements: Array<
              {
                __typename?: 'ComplianceRequirement';
              } & ComplianceRequirementFragmentFragment
            >;
            fileUploads: Array<
              { __typename?: 'FileUpload' } & FileUploadFragmentFragment
            >;
          }
      >;
    };
};

export type GetRecipientGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecipientGroupsQuery = { __typename?: 'Query' } & {
  recipientGroups: Array<
    { __typename?: 'RecipientGroup' } & Pick<
      RecipientGroup,
      'uuid' | 'name' | 'createdAt'
    > & {
        documentSets: Array<
          { __typename?: 'DocumentSet' } & Pick<DocumentSet, 'uuid' | 'name'>
        >;
      }
  >;
};

export type GetResumeUrlQueryVariables = Exact<{ [key: string]: never }>;

export type GetResumeUrlQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'resumeUrl'>;
};

export type GetStudentQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetStudentQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<
    Student,
    'coachingStartDate' | 'coachingEndDate'
  > & {
      attendanceRecord?: Maybe<
        { __typename?: 'AccountabilitySessionAttendanceRecord' } & Pick<
          AccountabilitySessionAttendanceRecord,
          'present' | 'absent'
        >
      >;
      advisorCheckInAttendanceRecord?: Maybe<
        { __typename?: 'AccountabilitySessionAttendanceRecord' } & Pick<
          AccountabilitySessionAttendanceRecord,
          'absent' | 'present'
        >
      >;
      coachAccountabilityAttendanceRecord?: Maybe<
        { __typename?: 'AccountabilitySessionAttendanceRecord' } & Pick<
          AccountabilitySessionAttendanceRecord,
          'absent' | 'present'
        >
      >;
      institution?: Maybe<
        { __typename?: 'Institution' } & Pick<Institution, 'id' | 'name'>
      >;
      coachingStatus?: Maybe<
        { __typename?: 'CoachingStatus' } & Pick<CoachingStatus, 'name'>
      >;
      coachingTier?: Maybe<
        { __typename?: 'CoachingTier' } & Pick<CoachingTier, 'name'>
      >;
    } & StudentFragmentFragment;
};

export type GetStudentDisciplineQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetStudentDisciplineQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'Student' } & {
    cohort?: Maybe<{ __typename?: 'Cohort' } & Pick<Cohort, 'disciplineUuid'>>;
  };
};

export type GetStudentHuntrMemberQueryVariables = Exact<{
  learnUuid: Scalars['String'];
}>;

export type GetStudentHuntrMemberQuery = { __typename?: 'Query' } & {
  studentHuntrMember?: Maybe<
    { __typename?: 'StudentHuntrMember' } & Pick<
      StudentHuntrMember,
      'externalMemberId'
    >
  >;
};

export type GetStudentLightModeSettingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetStudentLightModeSettingQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      profile: { __typename?: 'StudentProfile' } & Pick<
        StudentProfile,
        'lightMode'
      >;
    };
};

export type GetStudentMilestonesQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetStudentMilestonesQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'>;
};

export type GetStudentNotesQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetStudentNotesQuery = { __typename?: 'Query' } & {
  student: { __typename?: 'Student' } & Pick<Student, 'uuid'> & {
      notes: Array<
        { __typename?: 'Note' } & Pick<Note, 'contentText'> & {
            category?: Maybe<
              { __typename?: 'Category' } & CategoryFragmentFragment
            >;
          } & NoteFragmentFragment
      >;
      legacyNotes: Array<
        { __typename?: 'LegacyNote' } & LegacyNoteFragmentFragment
      >;
    };
};

export type GetDocusignTemplateKindsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDocusignTemplateKindsQuery = { __typename?: 'Query' } & {
  docusignTemplateKinds: Array<
    { __typename?: 'TemplateKind' } & Pick<
      TemplateKind,
      'uuid' | 'name' | 'isReviewOnly'
    >
  >;
};

export const CategoryFragmentFragmentDoc = gql`
  fragment CategoryFragment on Category {
    id
    accountabilitySession
    name
  }
`;
export const ComplianceRequirementFragmentFragmentDoc = gql`
  fragment ComplianceRequirementFragment on ComplianceRequirement {
    uuid
    title
    kind
    envelopeId
    assignedAt
    completedAt
    removedAt
    status
  }
`;
export const ContentFragmentFragmentDoc = gql`
  fragment ContentFragment on Content {
    uuid
    embedUrl
    imageUrl
    format
    type
    slug
    title
    url
    bookmark {
      uuid
    }
    disciplines {
      uuid
      name
    }
    phases {
      uuid
      disciplineUuid
      name
    }
    tags {
      uuid
      name
    }
    view {
      uuid
    }
  }
`;
export const DocumentSetFragmentFragmentDoc = gql`
  fragment documentSetFragment on DocumentSet {
    uuid
    name
    createdAt
    documentSetTemplates {
      uuid
      retiredAt
      createdAt
      docusignTemplate {
        uuid
        title
        internalName
        externalId
        templateKind {
          uuid
          name
        }
        createdAt
      }
    }
  }
`;
export const DocusignTemplateFragmentFragmentDoc = gql`
  fragment docusignTemplateFragment on DocusignTemplate {
    uuid
    title
    internalName
    externalId
    templateKind {
      uuid
      name
      isReviewOnly
    }
    createdAt
  }
`;
export const EducatorFragmentFragmentDoc = gql`
  fragment EducatorFragment on Educator {
    avatarUrl
    firstName
    lastName
    uuid
    availabilityScheduleUrl
  }
`;
export const LegacyNoteFragmentFragmentDoc = gql`
  fragment LegacyNoteFragment on LegacyNote {
    id
    contentHtml
    createdAt
    author {
      ...EducatorFragment
    }
  }
  ${EducatorFragmentFragmentDoc}
`;
export const LiveEventFragmentFragmentDoc = gql`
  fragment LiveEventFragment on LiveEvent {
    uuid
    liveEventTypes
    inPersonLocation
    location
    mainImageUrl
    programDisciplines
    programModules
    programPhases
    remoteMeetingLink
    saved
    scheduledDate
    scheduledDurationSeconds
    speaker
    description
    title
    slug
  }
`;
export const LmsCourseLinksFragmentFragmentDoc = gql`
  fragment LmsCourseLinksFragment on Student {
    courses {
      edges {
        cursor
        node {
          name
          progressPercentage
          totalTasks
          completedTasks
          lastAccessDate
          courseUrl
        }
      }
    }
  }
`;
export const NoteFragmentFragmentDoc = gql`
  fragment NoteFragment on Note {
    id
    aptitude
    attitude
    categoryId
    contentHtml
    contentText
    createdAt
    accountabilitySession {
      id
      attended
      date
    }
    author {
      avatarUrl
      firstName
      lastName
      uuid
    }
  }
`;
export const RecommendationFragmentFragmentDoc = gql`
  fragment RecommendationFragment on Recommendation {
    createdAt
    detail
    id
    mostRecentClick
    recommendedBy {
      uuid
      avatarUrl
      firstName
      lastName
    }
    recommendedByUuid
    recommendedToUuid
    title
    updatedAt
    url
  }
`;
export const FileUploadFragmentFragmentDoc = gql`
  fragment FileUploadFragment on FileUpload {
    assignedAt
    assignedBy
    completedAt
    completedBy
    fileName
    fileUploadType {
      uuid
      name
      visibleToStudent
    }
    fileUrl
    removedAt
    removedBy
    createdAt
    updatedAt
    uuid
  }
`;
export const MilestonesQueryFragmentDoc = gql`
  fragment MilestonesQuery on Student {
    uuid
    firstName
    milestones {
      dueOn
      id
      name
      completedAt
      htmlUrl
      canvasAssignmentExternalId
    }
    cohort {
      startDate
      endDate
    }
  }
`;
export const RecipientGroupFramgmentFragmentDoc = gql`
  fragment recipientGroupFramgment on RecipientGroup {
    uuid
    name
  }
`;
export const RecommendedContentFragmentFragmentDoc = gql`
  fragment RecommendedContentFragment on Student {
    recommendations {
      createdAt
      detail
      id
      title
      url
      recommendedBy {
        ...EducatorFragment
      }
    }
  }
  ${EducatorFragmentFragmentDoc}
`;
export const ShippingAddressFragmentFragmentDoc = gql`
  fragment ShippingAddressFragment on Student {
    shippingAddress {
      id
      line1
      line2
      city
      state
      zipcode
      country
    }
  }
`;
export const StudentFragmentFragmentDoc = gql`
  fragment StudentFragment on Student {
    cohort {
      courseOffering {
        discipline {
          name
        }
        pacingOption {
          name
        }
      }
      name
      startDate
      endDate
      uuid
      disciplineName
    }
    profile {
      id
      preferredPronouns
      timezone
    }
    avatarUrl
    email
    firstName
    lastName
    phoneNumber
    programStatus
    uuid
  }
`;
export const AddRecipientsToRecipientGroupDocument = gql`
  mutation AddRecipientsToRecipientGroup(
    $input: AddRecipientsToRecipientGroupInput!
  ) {
    addRecipientsToRecipientGroup(input: $input) {
      uuid
      name
      recipientsDeprecated {
        uuid
        firstName
        lastName
        avatarUrl
        complianceRequirements {
          ...ComplianceRequirementFragment
        }
        fileUploads {
          ...FileUploadFragment
        }
      }
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
  ${FileUploadFragmentFragmentDoc}
`;
export type AddRecipientsToRecipientGroupMutationFn = Apollo.MutationFunction<
  AddRecipientsToRecipientGroupMutation,
  AddRecipientsToRecipientGroupMutationVariables
>;

/**
 * __useAddRecipientsToRecipientGroupMutation__
 *
 * To run a mutation, you first call `useAddRecipientsToRecipientGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecipientsToRecipientGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecipientsToRecipientGroupMutation, { data, loading, error }] = useAddRecipientsToRecipientGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRecipientsToRecipientGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRecipientsToRecipientGroupMutation,
    AddRecipientsToRecipientGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRecipientsToRecipientGroupMutation,
    AddRecipientsToRecipientGroupMutationVariables
  >(AddRecipientsToRecipientGroupDocument, options);
}
export type AddRecipientsToRecipientGroupMutationHookResult = ReturnType<
  typeof useAddRecipientsToRecipientGroupMutation
>;
export type AddRecipientsToRecipientGroupMutationResult =
  Apollo.MutationResult<AddRecipientsToRecipientGroupMutation>;
export type AddRecipientsToRecipientGroupMutationOptions =
  Apollo.BaseMutationOptions<
    AddRecipientsToRecipientGroupMutation,
    AddRecipientsToRecipientGroupMutationVariables
  >;
export const AddTemplateToDocumentSetDocument = gql`
  mutation AddTemplateToDocumentSet($input: AddTemplateToDocumentSetInput!) {
    addTemplateToDocumentSet(input: $input) {
      ...documentSetFragment
    }
  }
  ${DocumentSetFragmentFragmentDoc}
`;
export type AddTemplateToDocumentSetMutationFn = Apollo.MutationFunction<
  AddTemplateToDocumentSetMutation,
  AddTemplateToDocumentSetMutationVariables
>;

/**
 * __useAddTemplateToDocumentSetMutation__
 *
 * To run a mutation, you first call `useAddTemplateToDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateToDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateToDocumentSetMutation, { data, loading, error }] = useAddTemplateToDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplateToDocumentSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTemplateToDocumentSetMutation,
    AddTemplateToDocumentSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTemplateToDocumentSetMutation,
    AddTemplateToDocumentSetMutationVariables
  >(AddTemplateToDocumentSetDocument, options);
}
export type AddTemplateToDocumentSetMutationHookResult = ReturnType<
  typeof useAddTemplateToDocumentSetMutation
>;
export type AddTemplateToDocumentSetMutationResult =
  Apollo.MutationResult<AddTemplateToDocumentSetMutation>;
export type AddTemplateToDocumentSetMutationOptions =
  Apollo.BaseMutationOptions<
    AddTemplateToDocumentSetMutation,
    AddTemplateToDocumentSetMutationVariables
  >;
export const ArchiveNoteDocument = gql`
  mutation ArchiveNote($id: ID!) {
    archiveNote(id: $id) {
      ...NoteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;
export type ArchiveNoteMutationFn = Apollo.MutationFunction<
  ArchiveNoteMutation,
  ArchiveNoteMutationVariables
>;

/**
 * __useArchiveNoteMutation__
 *
 * To run a mutation, you first call `useArchiveNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveNoteMutation, { data, loading, error }] = useArchiveNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveNoteMutation,
    ArchiveNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveNoteMutation, ArchiveNoteMutationVariables>(
    ArchiveNoteDocument,
    options
  );
}
export type ArchiveNoteMutationHookResult = ReturnType<
  typeof useArchiveNoteMutation
>;
export type ArchiveNoteMutationResult =
  Apollo.MutationResult<ArchiveNoteMutation>;
export type ArchiveNoteMutationOptions = Apollo.BaseMutationOptions<
  ArchiveNoteMutation,
  ArchiveNoteMutationVariables
>;
export const ArchiveRecommendationDocument = gql`
  mutation ArchiveRecommendation($input: ArchiveRecommendationInput!) {
    archiveRecommendation(input: $input) {
      ...RecommendationFragment
    }
  }
  ${RecommendationFragmentFragmentDoc}
`;
export type ArchiveRecommendationMutationFn = Apollo.MutationFunction<
  ArchiveRecommendationMutation,
  ArchiveRecommendationMutationVariables
>;

/**
 * __useArchiveRecommendationMutation__
 *
 * To run a mutation, you first call `useArchiveRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRecommendationMutation, { data, loading, error }] = useArchiveRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveRecommendationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveRecommendationMutation,
    ArchiveRecommendationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveRecommendationMutation,
    ArchiveRecommendationMutationVariables
  >(ArchiveRecommendationDocument, options);
}
export type ArchiveRecommendationMutationHookResult = ReturnType<
  typeof useArchiveRecommendationMutation
>;
export type ArchiveRecommendationMutationResult =
  Apollo.MutationResult<ArchiveRecommendationMutation>;
export type ArchiveRecommendationMutationOptions = Apollo.BaseMutationOptions<
  ArchiveRecommendationMutation,
  ArchiveRecommendationMutationVariables
>;
export const AssignRequirementFromEnvelopeDocument = gql`
  mutation AssignRequirementFromEnvelope(
    $input: AssignRequirementFromEnvelopeInput!
  ) {
    assignRequirementFromEnvelope(input: $input) {
      ...ComplianceRequirementFragment
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
`;
export type AssignRequirementFromEnvelopeMutationFn = Apollo.MutationFunction<
  AssignRequirementFromEnvelopeMutation,
  AssignRequirementFromEnvelopeMutationVariables
>;

/**
 * __useAssignRequirementFromEnvelopeMutation__
 *
 * To run a mutation, you first call `useAssignRequirementFromEnvelopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRequirementFromEnvelopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRequirementFromEnvelopeMutation, { data, loading, error }] = useAssignRequirementFromEnvelopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRequirementFromEnvelopeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignRequirementFromEnvelopeMutation,
    AssignRequirementFromEnvelopeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignRequirementFromEnvelopeMutation,
    AssignRequirementFromEnvelopeMutationVariables
  >(AssignRequirementFromEnvelopeDocument, options);
}
export type AssignRequirementFromEnvelopeMutationHookResult = ReturnType<
  typeof useAssignRequirementFromEnvelopeMutation
>;
export type AssignRequirementFromEnvelopeMutationResult =
  Apollo.MutationResult<AssignRequirementFromEnvelopeMutation>;
export type AssignRequirementFromEnvelopeMutationOptions =
  Apollo.BaseMutationOptions<
    AssignRequirementFromEnvelopeMutation,
    AssignRequirementFromEnvelopeMutationVariables
  >;
export const CompleteFileUploadDocument = gql`
  mutation CompleteFileUpload($input: CompleteFileUploadInput!) {
    completeFileUpload(input: $input) {
      ...FileUploadFragment
    }
  }
  ${FileUploadFragmentFragmentDoc}
`;
export type CompleteFileUploadMutationFn = Apollo.MutationFunction<
  CompleteFileUploadMutation,
  CompleteFileUploadMutationVariables
>;

/**
 * __useCompleteFileUploadMutation__
 *
 * To run a mutation, you first call `useCompleteFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFileUploadMutation, { data, loading, error }] = useCompleteFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteFileUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteFileUploadMutation,
    CompleteFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteFileUploadMutation,
    CompleteFileUploadMutationVariables
  >(CompleteFileUploadDocument, options);
}
export type CompleteFileUploadMutationHookResult = ReturnType<
  typeof useCompleteFileUploadMutation
>;
export type CompleteFileUploadMutationResult =
  Apollo.MutationResult<CompleteFileUploadMutation>;
export type CompleteFileUploadMutationOptions = Apollo.BaseMutationOptions<
  CompleteFileUploadMutation,
  CompleteFileUploadMutationVariables
>;
export const CompleteRequirementDocument = gql`
  mutation CompleteRequirement($input: CompleteRequirementInput!) {
    completeRequirement(input: $input) {
      ...ComplianceRequirementFragment
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
`;
export type CompleteRequirementMutationFn = Apollo.MutationFunction<
  CompleteRequirementMutation,
  CompleteRequirementMutationVariables
>;

/**
 * __useCompleteRequirementMutation__
 *
 * To run a mutation, you first call `useCompleteRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeRequirementMutation, { data, loading, error }] = useCompleteRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteRequirementMutation,
    CompleteRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteRequirementMutation,
    CompleteRequirementMutationVariables
  >(CompleteRequirementDocument, options);
}
export type CompleteRequirementMutationHookResult = ReturnType<
  typeof useCompleteRequirementMutation
>;
export type CompleteRequirementMutationResult =
  Apollo.MutationResult<CompleteRequirementMutation>;
export type CompleteRequirementMutationOptions = Apollo.BaseMutationOptions<
  CompleteRequirementMutation,
  CompleteRequirementMutationVariables
>;
export const CreateDocumentSetDocument = gql`
  mutation CreateDocumentSet($input: CreateDocumentSetInput!) {
    createDocumentSet(input: $input) {
      ...documentSetFragment
    }
  }
  ${DocumentSetFragmentFragmentDoc}
`;
export type CreateDocumentSetMutationFn = Apollo.MutationFunction<
  CreateDocumentSetMutation,
  CreateDocumentSetMutationVariables
>;

/**
 * __useCreateDocumentSetMutation__
 *
 * To run a mutation, you first call `useCreateDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentSetMutation, { data, loading, error }] = useCreateDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentSetMutation,
    CreateDocumentSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDocumentSetMutation,
    CreateDocumentSetMutationVariables
  >(CreateDocumentSetDocument, options);
}
export type CreateDocumentSetMutationHookResult = ReturnType<
  typeof useCreateDocumentSetMutation
>;
export type CreateDocumentSetMutationResult =
  Apollo.MutationResult<CreateDocumentSetMutation>;
export type CreateDocumentSetMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentSetMutation,
  CreateDocumentSetMutationVariables
>;
export const CreateDocusignTemplateDocument = gql`
  mutation CreateDocusignTemplate($input: CreateDocusignTemplateInput!) {
    createDocusignTemplate(input: $input) {
      ...docusignTemplateFragment
    }
  }
  ${DocusignTemplateFragmentFragmentDoc}
`;
export type CreateDocusignTemplateMutationFn = Apollo.MutationFunction<
  CreateDocusignTemplateMutation,
  CreateDocusignTemplateMutationVariables
>;

/**
 * __useCreateDocusignTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDocusignTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocusignTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocusignTemplateMutation, { data, loading, error }] = useCreateDocusignTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocusignTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocusignTemplateMutation,
    CreateDocusignTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDocusignTemplateMutation,
    CreateDocusignTemplateMutationVariables
  >(CreateDocusignTemplateDocument, options);
}
export type CreateDocusignTemplateMutationHookResult = ReturnType<
  typeof useCreateDocusignTemplateMutation
>;
export type CreateDocusignTemplateMutationResult =
  Apollo.MutationResult<CreateDocusignTemplateMutation>;
export type CreateDocusignTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateDocusignTemplateMutation,
  CreateDocusignTemplateMutationVariables
>;
export const CreateTemplateKindDocument = gql`
  mutation CreateTemplateKind($input: CreateTemplateKindInput!) {
    createTemplateKind(input: $input) {
      uuid
      name
      isReviewOnly
    }
  }
`;
export type CreateTemplateKindMutationFn = Apollo.MutationFunction<
  CreateTemplateKindMutation,
  CreateTemplateKindMutationVariables
>;

/**
 * __useCreateTemplateKindMutation__
 *
 * To run a mutation, you first call `useCreateTemplateKindMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateKindMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateKindMutation, { data, loading, error }] = useCreateTemplateKindMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateKindMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTemplateKindMutation,
    CreateTemplateKindMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTemplateKindMutation,
    CreateTemplateKindMutationVariables
  >(CreateTemplateKindDocument, options);
}
export type CreateTemplateKindMutationHookResult = ReturnType<
  typeof useCreateTemplateKindMutation
>;
export type CreateTemplateKindMutationResult =
  Apollo.MutationResult<CreateTemplateKindMutation>;
export type CreateTemplateKindMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateKindMutation,
  CreateTemplateKindMutationVariables
>;
export const CreateFileUploadDocument = gql`
  mutation CreateFileUpload($input: CreateFileUploadInput!) {
    createFileUpload(input: $input) {
      uuid
      assignedAt
      fileUploadType {
        uuid
        name
        visibleToStudent
      }
    }
  }
`;
export type CreateFileUploadMutationFn = Apollo.MutationFunction<
  CreateFileUploadMutation,
  CreateFileUploadMutationVariables
>;

/**
 * __useCreateFileUploadMutation__
 *
 * To run a mutation, you first call `useCreateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUploadMutation, { data, loading, error }] = useCreateFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFileUploadMutation,
    CreateFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFileUploadMutation,
    CreateFileUploadMutationVariables
  >(CreateFileUploadDocument, options);
}
export type CreateFileUploadMutationHookResult = ReturnType<
  typeof useCreateFileUploadMutation
>;
export type CreateFileUploadMutationResult =
  Apollo.MutationResult<CreateFileUploadMutation>;
export type CreateFileUploadMutationOptions = Apollo.BaseMutationOptions<
  CreateFileUploadMutation,
  CreateFileUploadMutationVariables
>;
export const CreateFileUploadTypeDocument = gql`
  mutation CreateFileUploadType($input: CreateFileUploadTypesInput!) {
    createFileUploadType(input: $input) {
      name
      uuid
      visibleToStudent
      associatedCohorts {
        name
        uuid
      }
      associatedRecipientGroups {
        ...recipientGroupFramgment
      }
      studentCount
    }
  }
  ${RecipientGroupFramgmentFragmentDoc}
`;
export type CreateFileUploadTypeMutationFn = Apollo.MutationFunction<
  CreateFileUploadTypeMutation,
  CreateFileUploadTypeMutationVariables
>;

/**
 * __useCreateFileUploadTypeMutation__
 *
 * To run a mutation, you first call `useCreateFileUploadTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUploadTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUploadTypeMutation, { data, loading, error }] = useCreateFileUploadTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileUploadTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFileUploadTypeMutation,
    CreateFileUploadTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFileUploadTypeMutation,
    CreateFileUploadTypeMutationVariables
  >(CreateFileUploadTypeDocument, options);
}
export type CreateFileUploadTypeMutationHookResult = ReturnType<
  typeof useCreateFileUploadTypeMutation
>;
export type CreateFileUploadTypeMutationResult =
  Apollo.MutationResult<CreateFileUploadTypeMutation>;
export type CreateFileUploadTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateFileUploadTypeMutation,
  CreateFileUploadTypeMutationVariables
>;
export const CreateNoteDocument = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      ...NoteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNoteMutation,
    CreateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options
  );
}
export type CreateNoteMutationHookResult = ReturnType<
  typeof useCreateNoteMutation
>;
export type CreateNoteMutationResult =
  Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const CreateRecipientGroupDocument = gql`
  mutation CreateRecipientGroup($input: CreateRecipientGroupInput!) {
    createRecipientGroup(input: $input) {
      ...recipientGroupFramgment
    }
  }
  ${RecipientGroupFramgmentFragmentDoc}
`;
export type CreateRecipientGroupMutationFn = Apollo.MutationFunction<
  CreateRecipientGroupMutation,
  CreateRecipientGroupMutationVariables
>;

/**
 * __useCreateRecipientGroupMutation__
 *
 * To run a mutation, you first call `useCreateRecipientGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipientGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipientGroupMutation, { data, loading, error }] = useCreateRecipientGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecipientGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecipientGroupMutation,
    CreateRecipientGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRecipientGroupMutation,
    CreateRecipientGroupMutationVariables
  >(CreateRecipientGroupDocument, options);
}
export type CreateRecipientGroupMutationHookResult = ReturnType<
  typeof useCreateRecipientGroupMutation
>;
export type CreateRecipientGroupMutationResult =
  Apollo.MutationResult<CreateRecipientGroupMutation>;
export type CreateRecipientGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateRecipientGroupMutation,
  CreateRecipientGroupMutationVariables
>;
export const CreateRecommendationDocument = gql`
  mutation CreateRecommendation($input: CreateRecommendationInput!) {
    createRecommendation(input: $input) {
      ...RecommendationFragment
    }
  }
  ${RecommendationFragmentFragmentDoc}
`;
export type CreateRecommendationMutationFn = Apollo.MutationFunction<
  CreateRecommendationMutation,
  CreateRecommendationMutationVariables
>;

/**
 * __useCreateRecommendationMutation__
 *
 * To run a mutation, you first call `useCreateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecommendationMutation, { data, loading, error }] = useCreateRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecommendationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecommendationMutation,
    CreateRecommendationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRecommendationMutation,
    CreateRecommendationMutationVariables
  >(CreateRecommendationDocument, options);
}
export type CreateRecommendationMutationHookResult = ReturnType<
  typeof useCreateRecommendationMutation
>;
export type CreateRecommendationMutationResult =
  Apollo.MutationResult<CreateRecommendationMutation>;
export type CreateRecommendationMutationOptions = Apollo.BaseMutationOptions<
  CreateRecommendationMutation,
  CreateRecommendationMutationVariables
>;
export const CreateRecommendationClickDocument = gql`
  mutation CreateRecommendationClick($input: CreateRecommendationClickInput!) {
    createRecommendationClick(input: $input) {
      id
    }
  }
`;
export type CreateRecommendationClickMutationFn = Apollo.MutationFunction<
  CreateRecommendationClickMutation,
  CreateRecommendationClickMutationVariables
>;

/**
 * __useCreateRecommendationClickMutation__
 *
 * To run a mutation, you first call `useCreateRecommendationClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecommendationClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecommendationClickMutation, { data, loading, error }] = useCreateRecommendationClickMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecommendationClickMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecommendationClickMutation,
    CreateRecommendationClickMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRecommendationClickMutation,
    CreateRecommendationClickMutationVariables
  >(CreateRecommendationClickDocument, options);
}
export type CreateRecommendationClickMutationHookResult = ReturnType<
  typeof useCreateRecommendationClickMutation
>;
export type CreateRecommendationClickMutationResult =
  Apollo.MutationResult<CreateRecommendationClickMutation>;
export type CreateRecommendationClickMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRecommendationClickMutation,
    CreateRecommendationClickMutationVariables
  >;
export const LinkCohortsWithDocumentSetDocument = gql`
  mutation LinkCohortsWithDocumentSet(
    $input: LinkCohortsWithDocumentSetInput!
  ) {
    linkCohortsWithDocumentSet(input: $input) {
      ...documentSetFragment
    }
  }
  ${DocumentSetFragmentFragmentDoc}
`;
export type LinkCohortsWithDocumentSetMutationFn = Apollo.MutationFunction<
  LinkCohortsWithDocumentSetMutation,
  LinkCohortsWithDocumentSetMutationVariables
>;

/**
 * __useLinkCohortsWithDocumentSetMutation__
 *
 * To run a mutation, you first call `useLinkCohortsWithDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkCohortsWithDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkCohortsWithDocumentSetMutation, { data, loading, error }] = useLinkCohortsWithDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkCohortsWithDocumentSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkCohortsWithDocumentSetMutation,
    LinkCohortsWithDocumentSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkCohortsWithDocumentSetMutation,
    LinkCohortsWithDocumentSetMutationVariables
  >(LinkCohortsWithDocumentSetDocument, options);
}
export type LinkCohortsWithDocumentSetMutationHookResult = ReturnType<
  typeof useLinkCohortsWithDocumentSetMutation
>;
export type LinkCohortsWithDocumentSetMutationResult =
  Apollo.MutationResult<LinkCohortsWithDocumentSetMutation>;
export type LinkCohortsWithDocumentSetMutationOptions =
  Apollo.BaseMutationOptions<
    LinkCohortsWithDocumentSetMutation,
    LinkCohortsWithDocumentSetMutationVariables
  >;
export const MarkContentViewedDocument = gql`
  mutation MarkContentViewed($input: MarkContentViewedInput!) {
    markContentViewed(input: $input) {
      uuid
      content {
        uuid
        view {
          uuid
        }
      }
    }
  }
`;
export type MarkContentViewedMutationFn = Apollo.MutationFunction<
  MarkContentViewedMutation,
  MarkContentViewedMutationVariables
>;

/**
 * __useMarkContentViewedMutation__
 *
 * To run a mutation, you first call `useMarkContentViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkContentViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markContentViewedMutation, { data, loading, error }] = useMarkContentViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkContentViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkContentViewedMutation,
    MarkContentViewedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkContentViewedMutation,
    MarkContentViewedMutationVariables
  >(MarkContentViewedDocument, options);
}
export type MarkContentViewedMutationHookResult = ReturnType<
  typeof useMarkContentViewedMutation
>;
export type MarkContentViewedMutationResult =
  Apollo.MutationResult<MarkContentViewedMutation>;
export type MarkContentViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkContentViewedMutation,
  MarkContentViewedMutationVariables
>;
export const PendingRequirementDocument = gql`
  mutation PendingRequirement($input: PendingRequirementInput!) {
    pendingRequirement(input: $input)
  }
`;
export type PendingRequirementMutationFn = Apollo.MutationFunction<
  PendingRequirementMutation,
  PendingRequirementMutationVariables
>;

/**
 * __usePendingRequirementMutation__
 *
 * To run a mutation, you first call `usePendingRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePendingRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pendingRequirementMutation, { data, loading, error }] = usePendingRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePendingRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PendingRequirementMutation,
    PendingRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PendingRequirementMutation,
    PendingRequirementMutationVariables
  >(PendingRequirementDocument, options);
}
export type PendingRequirementMutationHookResult = ReturnType<
  typeof usePendingRequirementMutation
>;
export type PendingRequirementMutationResult =
  Apollo.MutationResult<PendingRequirementMutation>;
export type PendingRequirementMutationOptions = Apollo.BaseMutationOptions<
  PendingRequirementMutation,
  PendingRequirementMutationVariables
>;
export const RemoveFileFromRequestDocument = gql`
  mutation RemoveFileFromRequest($input: RemoveFileFromRequestInput!) {
    removeFileFromRequest(input: $input) {
      ...FileUploadFragment
    }
  }
  ${FileUploadFragmentFragmentDoc}
`;
export type RemoveFileFromRequestMutationFn = Apollo.MutationFunction<
  RemoveFileFromRequestMutation,
  RemoveFileFromRequestMutationVariables
>;

/**
 * __useRemoveFileFromRequestMutation__
 *
 * To run a mutation, you first call `useRemoveFileFromRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileFromRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileFromRequestMutation, { data, loading, error }] = useRemoveFileFromRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFileFromRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFileFromRequestMutation,
    RemoveFileFromRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFileFromRequestMutation,
    RemoveFileFromRequestMutationVariables
  >(RemoveFileFromRequestDocument, options);
}
export type RemoveFileFromRequestMutationHookResult = ReturnType<
  typeof useRemoveFileFromRequestMutation
>;
export type RemoveFileFromRequestMutationResult =
  Apollo.MutationResult<RemoveFileFromRequestMutation>;
export type RemoveFileFromRequestMutationOptions = Apollo.BaseMutationOptions<
  RemoveFileFromRequestMutation,
  RemoveFileFromRequestMutationVariables
>;
export const RemoveFileUploadRequestDocument = gql`
  mutation RemoveFileUploadRequest($input: RemoveFileUploadRequestInput!) {
    removeFileUploadRequest(input: $input) {
      ...FileUploadFragment
    }
  }
  ${FileUploadFragmentFragmentDoc}
`;
export type RemoveFileUploadRequestMutationFn = Apollo.MutationFunction<
  RemoveFileUploadRequestMutation,
  RemoveFileUploadRequestMutationVariables
>;

/**
 * __useRemoveFileUploadRequestMutation__
 *
 * To run a mutation, you first call `useRemoveFileUploadRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileUploadRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileUploadRequestMutation, { data, loading, error }] = useRemoveFileUploadRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFileUploadRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFileUploadRequestMutation,
    RemoveFileUploadRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFileUploadRequestMutation,
    RemoveFileUploadRequestMutationVariables
  >(RemoveFileUploadRequestDocument, options);
}
export type RemoveFileUploadRequestMutationHookResult = ReturnType<
  typeof useRemoveFileUploadRequestMutation
>;
export type RemoveFileUploadRequestMutationResult =
  Apollo.MutationResult<RemoveFileUploadRequestMutation>;
export type RemoveFileUploadRequestMutationOptions = Apollo.BaseMutationOptions<
  RemoveFileUploadRequestMutation,
  RemoveFileUploadRequestMutationVariables
>;
export const RemoveRequirementDocument = gql`
  mutation RemoveRequirement($input: RemoveRequirementInput!) {
    removeRequirement(input: $input) {
      ...ComplianceRequirementFragment
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
`;
export type RemoveRequirementMutationFn = Apollo.MutationFunction<
  RemoveRequirementMutation,
  RemoveRequirementMutationVariables
>;

/**
 * __useRemoveRequirementMutation__
 *
 * To run a mutation, you first call `useRemoveRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRequirementMutation, { data, loading, error }] = useRemoveRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRequirementMutation,
    RemoveRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRequirementMutation,
    RemoveRequirementMutationVariables
  >(RemoveRequirementDocument, options);
}
export type RemoveRequirementMutationHookResult = ReturnType<
  typeof useRemoveRequirementMutation
>;
export type RemoveRequirementMutationResult =
  Apollo.MutationResult<RemoveRequirementMutation>;
export type RemoveRequirementMutationOptions = Apollo.BaseMutationOptions<
  RemoveRequirementMutation,
  RemoveRequirementMutationVariables
>;
export const RetireDocumentSetDocument = gql`
  mutation RetireDocumentSet($input: RetireDocumentSetInput!) {
    retireDocumentSet(input: $input) {
      uuid
      retiredAt
    }
  }
`;
export type RetireDocumentSetMutationFn = Apollo.MutationFunction<
  RetireDocumentSetMutation,
  RetireDocumentSetMutationVariables
>;

/**
 * __useRetireDocumentSetMutation__
 *
 * To run a mutation, you first call `useRetireDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetireDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retireDocumentSetMutation, { data, loading, error }] = useRetireDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetireDocumentSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetireDocumentSetMutation,
    RetireDocumentSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetireDocumentSetMutation,
    RetireDocumentSetMutationVariables
  >(RetireDocumentSetDocument, options);
}
export type RetireDocumentSetMutationHookResult = ReturnType<
  typeof useRetireDocumentSetMutation
>;
export type RetireDocumentSetMutationResult =
  Apollo.MutationResult<RetireDocumentSetMutation>;
export type RetireDocumentSetMutationOptions = Apollo.BaseMutationOptions<
  RetireDocumentSetMutation,
  RetireDocumentSetMutationVariables
>;
export const RetireDocusignTemplateDocument = gql`
  mutation RetireDocusignTemplate($input: RetireDocusignTemplateInput!) {
    retireDocusignTemplate(input: $input) {
      ...docusignTemplateFragment
    }
  }
  ${DocusignTemplateFragmentFragmentDoc}
`;
export type RetireDocusignTemplateMutationFn = Apollo.MutationFunction<
  RetireDocusignTemplateMutation,
  RetireDocusignTemplateMutationVariables
>;

/**
 * __useRetireDocusignTemplateMutation__
 *
 * To run a mutation, you first call `useRetireDocusignTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetireDocusignTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retireDocusignTemplateMutation, { data, loading, error }] = useRetireDocusignTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetireDocusignTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetireDocusignTemplateMutation,
    RetireDocusignTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetireDocusignTemplateMutation,
    RetireDocusignTemplateMutationVariables
  >(RetireDocusignTemplateDocument, options);
}
export type RetireDocusignTemplateMutationHookResult = ReturnType<
  typeof useRetireDocusignTemplateMutation
>;
export type RetireDocusignTemplateMutationResult =
  Apollo.MutationResult<RetireDocusignTemplateMutation>;
export type RetireDocusignTemplateMutationOptions = Apollo.BaseMutationOptions<
  RetireDocusignTemplateMutation,
  RetireDocusignTemplateMutationVariables
>;
export const RetireFileUploadTypeDocument = gql`
  mutation RetireFileUploadType($input: RetireFileUploadInput!) {
    retireFileUploadType(input: $input) {
      uuid
      retiredAt
    }
  }
`;
export type RetireFileUploadTypeMutationFn = Apollo.MutationFunction<
  RetireFileUploadTypeMutation,
  RetireFileUploadTypeMutationVariables
>;

/**
 * __useRetireFileUploadTypeMutation__
 *
 * To run a mutation, you first call `useRetireFileUploadTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetireFileUploadTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retireFileUploadTypeMutation, { data, loading, error }] = useRetireFileUploadTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetireFileUploadTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetireFileUploadTypeMutation,
    RetireFileUploadTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetireFileUploadTypeMutation,
    RetireFileUploadTypeMutationVariables
  >(RetireFileUploadTypeDocument, options);
}
export type RetireFileUploadTypeMutationHookResult = ReturnType<
  typeof useRetireFileUploadTypeMutation
>;
export type RetireFileUploadTypeMutationResult =
  Apollo.MutationResult<RetireFileUploadTypeMutation>;
export type RetireFileUploadTypeMutationOptions = Apollo.BaseMutationOptions<
  RetireFileUploadTypeMutation,
  RetireFileUploadTypeMutationVariables
>;
export const SetDocumentSetsForCohortDocument = gql`
  mutation SetDocumentSetsForCohort($input: SetDocumentSetsForCohortInput!) {
    setDocumentSetsForCohort(input: $input) {
      uuid
    }
  }
`;
export type SetDocumentSetsForCohortMutationFn = Apollo.MutationFunction<
  SetDocumentSetsForCohortMutation,
  SetDocumentSetsForCohortMutationVariables
>;

/**
 * __useSetDocumentSetsForCohortMutation__
 *
 * To run a mutation, you first call `useSetDocumentSetsForCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentSetsForCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentSetsForCohortMutation, { data, loading, error }] = useSetDocumentSetsForCohortMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDocumentSetsForCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDocumentSetsForCohortMutation,
    SetDocumentSetsForCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDocumentSetsForCohortMutation,
    SetDocumentSetsForCohortMutationVariables
  >(SetDocumentSetsForCohortDocument, options);
}
export type SetDocumentSetsForCohortMutationHookResult = ReturnType<
  typeof useSetDocumentSetsForCohortMutation
>;
export type SetDocumentSetsForCohortMutationResult =
  Apollo.MutationResult<SetDocumentSetsForCohortMutation>;
export type SetDocumentSetsForCohortMutationOptions =
  Apollo.BaseMutationOptions<
    SetDocumentSetsForCohortMutation,
    SetDocumentSetsForCohortMutationVariables
  >;
export const SetDocumentSetsForRecipientGroupDocument = gql`
  mutation SetDocumentSetsForRecipientGroup(
    $input: SetDocumentSetsForRecipientGroupInput!
  ) {
    setDocumentSetsForRecipientGroup(input: $input) {
      uuid
    }
  }
`;
export type SetDocumentSetsForRecipientGroupMutationFn =
  Apollo.MutationFunction<
    SetDocumentSetsForRecipientGroupMutation,
    SetDocumentSetsForRecipientGroupMutationVariables
  >;

/**
 * __useSetDocumentSetsForRecipientGroupMutation__
 *
 * To run a mutation, you first call `useSetDocumentSetsForRecipientGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentSetsForRecipientGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentSetsForRecipientGroupMutation, { data, loading, error }] = useSetDocumentSetsForRecipientGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDocumentSetsForRecipientGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDocumentSetsForRecipientGroupMutation,
    SetDocumentSetsForRecipientGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDocumentSetsForRecipientGroupMutation,
    SetDocumentSetsForRecipientGroupMutationVariables
  >(SetDocumentSetsForRecipientGroupDocument, options);
}
export type SetDocumentSetsForRecipientGroupMutationHookResult = ReturnType<
  typeof useSetDocumentSetsForRecipientGroupMutation
>;
export type SetDocumentSetsForRecipientGroupMutationResult =
  Apollo.MutationResult<SetDocumentSetsForRecipientGroupMutation>;
export type SetDocumentSetsForRecipientGroupMutationOptions =
  Apollo.BaseMutationOptions<
    SetDocumentSetsForRecipientGroupMutation,
    SetDocumentSetsForRecipientGroupMutationVariables
  >;
export const SetFileUploadTypesForDocumentSetDocument = gql`
  mutation SetFileUploadTypesForDocumentSet(
    $input: SetFileUploadTypesForDocumentSetInput!
  ) {
    setFileUploadTypesForDocumentSet(input: $input) {
      uuid
      fileUploadTypes {
        uuid
        name
      }
    }
  }
`;
export type SetFileUploadTypesForDocumentSetMutationFn =
  Apollo.MutationFunction<
    SetFileUploadTypesForDocumentSetMutation,
    SetFileUploadTypesForDocumentSetMutationVariables
  >;

/**
 * __useSetFileUploadTypesForDocumentSetMutation__
 *
 * To run a mutation, you first call `useSetFileUploadTypesForDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFileUploadTypesForDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFileUploadTypesForDocumentSetMutation, { data, loading, error }] = useSetFileUploadTypesForDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFileUploadTypesForDocumentSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFileUploadTypesForDocumentSetMutation,
    SetFileUploadTypesForDocumentSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetFileUploadTypesForDocumentSetMutation,
    SetFileUploadTypesForDocumentSetMutationVariables
  >(SetFileUploadTypesForDocumentSetDocument, options);
}
export type SetFileUploadTypesForDocumentSetMutationHookResult = ReturnType<
  typeof useSetFileUploadTypesForDocumentSetMutation
>;
export type SetFileUploadTypesForDocumentSetMutationResult =
  Apollo.MutationResult<SetFileUploadTypesForDocumentSetMutation>;
export type SetFileUploadTypesForDocumentSetMutationOptions =
  Apollo.BaseMutationOptions<
    SetFileUploadTypesForDocumentSetMutation,
    SetFileUploadTypesForDocumentSetMutationVariables
  >;
export const ToggleContentBookmarkedDocument = gql`
  mutation ToggleContentBookmarked($input: ToggleContentBookmarkedInput!) {
    toggleContentBookmarked(input: $input) {
      uuid
      bookmark {
        uuid
      }
    }
  }
`;
export type ToggleContentBookmarkedMutationFn = Apollo.MutationFunction<
  ToggleContentBookmarkedMutation,
  ToggleContentBookmarkedMutationVariables
>;

/**
 * __useToggleContentBookmarkedMutation__
 *
 * To run a mutation, you first call `useToggleContentBookmarkedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleContentBookmarkedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleContentBookmarkedMutation, { data, loading, error }] = useToggleContentBookmarkedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleContentBookmarkedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleContentBookmarkedMutation,
    ToggleContentBookmarkedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleContentBookmarkedMutation,
    ToggleContentBookmarkedMutationVariables
  >(ToggleContentBookmarkedDocument, options);
}
export type ToggleContentBookmarkedMutationHookResult = ReturnType<
  typeof useToggleContentBookmarkedMutation
>;
export type ToggleContentBookmarkedMutationResult =
  Apollo.MutationResult<ToggleContentBookmarkedMutation>;
export type ToggleContentBookmarkedMutationOptions = Apollo.BaseMutationOptions<
  ToggleContentBookmarkedMutation,
  ToggleContentBookmarkedMutationVariables
>;
export const ToggleSaveLiveEventDocument = gql`
  mutation ToggleSaveLiveEvent($input: ToggleSaveLiveEventInput!) {
    toggleSaveLiveEvent(input: $input) {
      liveEvent {
        uuid
        saved
      }
      errors
    }
  }
`;
export type ToggleSaveLiveEventMutationFn = Apollo.MutationFunction<
  ToggleSaveLiveEventMutation,
  ToggleSaveLiveEventMutationVariables
>;

/**
 * __useToggleSaveLiveEventMutation__
 *
 * To run a mutation, you first call `useToggleSaveLiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSaveLiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSaveLiveEventMutation, { data, loading, error }] = useToggleSaveLiveEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleSaveLiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleSaveLiveEventMutation,
    ToggleSaveLiveEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleSaveLiveEventMutation,
    ToggleSaveLiveEventMutationVariables
  >(ToggleSaveLiveEventDocument, options);
}
export type ToggleSaveLiveEventMutationHookResult = ReturnType<
  typeof useToggleSaveLiveEventMutation
>;
export type ToggleSaveLiveEventMutationResult =
  Apollo.MutationResult<ToggleSaveLiveEventMutation>;
export type ToggleSaveLiveEventMutationOptions = Apollo.BaseMutationOptions<
  ToggleSaveLiveEventMutation,
  ToggleSaveLiveEventMutationVariables
>;
export const TrackEngagementDocument = gql`
  mutation trackEngagement($callToActionId: ID!) {
    trackEngagement(callToActionId: $callToActionId)
  }
`;
export type TrackEngagementMutationFn = Apollo.MutationFunction<
  TrackEngagementMutation,
  TrackEngagementMutationVariables
>;

/**
 * __useTrackEngagementMutation__
 *
 * To run a mutation, you first call `useTrackEngagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEngagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEngagementMutation, { data, loading, error }] = useTrackEngagementMutation({
 *   variables: {
 *      callToActionId: // value for 'callToActionId'
 *   },
 * });
 */
export function useTrackEngagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackEngagementMutation,
    TrackEngagementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrackEngagementMutation,
    TrackEngagementMutationVariables
  >(TrackEngagementDocument, options);
}
export type TrackEngagementMutationHookResult = ReturnType<
  typeof useTrackEngagementMutation
>;
export type TrackEngagementMutationResult =
  Apollo.MutationResult<TrackEngagementMutation>;
export type TrackEngagementMutationOptions = Apollo.BaseMutationOptions<
  TrackEngagementMutation,
  TrackEngagementMutationVariables
>;
export const UpdateAccountInformationDocument = gql`
  mutation UpdateAccountInformation(
    $studentInput: UpdateStudentInput!
    $avatarUrl: String
    $blogUrl: String
    $dateOfBirth: ISO8601Date
    $daylightSavingsTime: Boolean!
    $educationalBackground: String
    $emergencyContactEmail: String
    $emergencyContactName: String
    $emergencyContactPhone: String
    $emergencyContactRelationship: String
    $ethnicity: String
    $firstName: String!
    $lastName: String!
    $gender: String
    $identities: [ID!]
    $linkedInProfileUrl: String
    $militaryStatus: String
    $phoneNumber: String
    $portfolioSiteUrl: String
    $preferredFirstName: String
    $preferredPronouns: String
    $races: [String!]
    $timezone: String!
    $tshirtSize: String
    $homeAddressLine1: String
    $homeAddressLine2: String
    $homeCity: String
    $homeState: String
    $homePostalCode: String
    $shippingAddressLine1: String
    $shippingAddressLine2: String
    $shippingCity: String
    $shippingState: String
    $shippingPostalCode: String
    $skilledInterviewFeedbackUrl: String
    $skilledMentorFeedbackUrl: String
  ) {
    updateStudent(input: $studentInput) {
      me {
        avatarUrl
        email
        uuid
      }
      errors
    }
    updateStudentProfile(
      avatarUrl: $avatarUrl
      blogUrl: $blogUrl
      dateOfBirth: $dateOfBirth
      daylightSavingsTime: $daylightSavingsTime
      educationalBackground: $educationalBackground
      ethnicity: $ethnicity
      emergencyContactEmail: $emergencyContactEmail
      emergencyContactName: $emergencyContactName
      emergencyContactPhone: $emergencyContactPhone
      emergencyContactRelationship: $emergencyContactRelationship
      firstName: $firstName
      gender: $gender
      identities: $identities
      lastName: $lastName
      linkedInProfileUrl: $linkedInProfileUrl
      militaryStatus: $militaryStatus
      phoneNumber: $phoneNumber
      portfolioSiteUrl: $portfolioSiteUrl
      preferredFirstName: $preferredFirstName
      preferredPronouns: $preferredPronouns
      races: $races
      skilledInterviewFeedbackUrl: $skilledInterviewFeedbackUrl
      skilledMentorFeedbackUrl: $skilledMentorFeedbackUrl
      timezone: $timezone
      tshirtSize: $tshirtSize
    ) {
      daylightSavingsTime
      educationalBackground
      gender
      id
      identityTerms {
        id
        category
      }
      militaryStatus
      preferredFirstName
      preferredPronouns
      races
      studentUuid
      timezone
      tshirtSize
    }
    updateHomeAddress(
      city: $homeCity
      line1: $homeAddressLine1
      line2: $homeAddressLine2
      state: $homeState
      zipcode: $homePostalCode
    ) {
      city
      id
      line1
      line2
      state
      zipcode
    }
    updateShippingAddress(
      city: $shippingCity
      line1: $shippingAddressLine1
      line2: $shippingAddressLine2
      state: $shippingState
      zipcode: $shippingPostalCode
    ) {
      city
      id
      line1
      line2
      state
      zipcode
    }
  }
`;
export type UpdateAccountInformationMutationFn = Apollo.MutationFunction<
  UpdateAccountInformationMutation,
  UpdateAccountInformationMutationVariables
>;

/**
 * __useUpdateAccountInformationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountInformationMutation, { data, loading, error }] = useUpdateAccountInformationMutation({
 *   variables: {
 *      studentInput: // value for 'studentInput'
 *      avatarUrl: // value for 'avatarUrl'
 *      blogUrl: // value for 'blogUrl'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      daylightSavingsTime: // value for 'daylightSavingsTime'
 *      educationalBackground: // value for 'educationalBackground'
 *      emergencyContactEmail: // value for 'emergencyContactEmail'
 *      emergencyContactName: // value for 'emergencyContactName'
 *      emergencyContactPhone: // value for 'emergencyContactPhone'
 *      emergencyContactRelationship: // value for 'emergencyContactRelationship'
 *      ethnicity: // value for 'ethnicity'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      identities: // value for 'identities'
 *      linkedInProfileUrl: // value for 'linkedInProfileUrl'
 *      militaryStatus: // value for 'militaryStatus'
 *      phoneNumber: // value for 'phoneNumber'
 *      portfolioSiteUrl: // value for 'portfolioSiteUrl'
 *      preferredFirstName: // value for 'preferredFirstName'
 *      preferredPronouns: // value for 'preferredPronouns'
 *      races: // value for 'races'
 *      timezone: // value for 'timezone'
 *      tshirtSize: // value for 'tshirtSize'
 *      homeAddressLine1: // value for 'homeAddressLine1'
 *      homeAddressLine2: // value for 'homeAddressLine2'
 *      homeCity: // value for 'homeCity'
 *      homeState: // value for 'homeState'
 *      homePostalCode: // value for 'homePostalCode'
 *      shippingAddressLine1: // value for 'shippingAddressLine1'
 *      shippingAddressLine2: // value for 'shippingAddressLine2'
 *      shippingCity: // value for 'shippingCity'
 *      shippingState: // value for 'shippingState'
 *      shippingPostalCode: // value for 'shippingPostalCode'
 *      skilledInterviewFeedbackUrl: // value for 'skilledInterviewFeedbackUrl'
 *      skilledMentorFeedbackUrl: // value for 'skilledMentorFeedbackUrl'
 *   },
 * });
 */
export function useUpdateAccountInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountInformationMutation,
    UpdateAccountInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountInformationMutation,
    UpdateAccountInformationMutationVariables
  >(UpdateAccountInformationDocument, options);
}
export type UpdateAccountInformationMutationHookResult = ReturnType<
  typeof useUpdateAccountInformationMutation
>;
export type UpdateAccountInformationMutationResult =
  Apollo.MutationResult<UpdateAccountInformationMutation>;
export type UpdateAccountInformationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAccountInformationMutation,
    UpdateAccountInformationMutationVariables
  >;
export const UpdateNoteDocument = gql`
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      ...NoteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNoteMutation,
    UpdateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
    options
  );
}
export type UpdateNoteMutationHookResult = ReturnType<
  typeof useUpdateNoteMutation
>;
export type UpdateNoteMutationResult =
  Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;
export const UpdateShippingAddressDocument = gql`
  mutation UpdateShippingAddress(
    $city: String!
    $country: String!
    $line1: String!
    $line2: String!
    $state: String!
    $zipcode: String!
  ) {
    updateShippingAddress(
      city: $city
      country: $country
      line1: $line1
      line2: $line2
      state: $state
      zipcode: $zipcode
    ) {
      id
      city
      country
      line1
      line2
      state
      zipcode
    }
  }
`;
export type UpdateShippingAddressMutationFn = Apollo.MutationFunction<
  UpdateShippingAddressMutation,
  UpdateShippingAddressMutationVariables
>;

/**
 * __useUpdateShippingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingAddressMutation, { data, loading, error }] = useUpdateShippingAddressMutation({
 *   variables: {
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      line1: // value for 'line1'
 *      line2: // value for 'line2'
 *      state: // value for 'state'
 *      zipcode: // value for 'zipcode'
 *   },
 * });
 */
export function useUpdateShippingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShippingAddressMutation,
    UpdateShippingAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShippingAddressMutation,
    UpdateShippingAddressMutationVariables
  >(UpdateShippingAddressDocument, options);
}
export type UpdateShippingAddressMutationHookResult = ReturnType<
  typeof useUpdateShippingAddressMutation
>;
export type UpdateShippingAddressMutationResult =
  Apollo.MutationResult<UpdateShippingAddressMutation>;
export type UpdateShippingAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateShippingAddressMutation,
  UpdateShippingAddressMutationVariables
>;
export const UpdateStudentDocument = gql`
  mutation UpdateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      me {
        uuid
        avatarUrl
        email
        phoneNumber
      }
    }
  }
`;
export type UpdateStudentMutationFn = Apollo.MutationFunction<
  UpdateStudentMutation,
  UpdateStudentMutationVariables
>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudentMutation,
    UpdateStudentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStudentMutation,
    UpdateStudentMutationVariables
  >(UpdateStudentDocument, options);
}
export type UpdateStudentMutationHookResult = ReturnType<
  typeof useUpdateStudentMutation
>;
export type UpdateStudentMutationResult =
  Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudentMutation,
  UpdateStudentMutationVariables
>;
export const UpdateStudentLightModeDocument = gql`
  mutation UpdateStudentLightMode($lightMode: Boolean!) {
    updateStudentLightMode(lightMode: $lightMode) {
      lightMode
    }
  }
`;
export type UpdateStudentLightModeMutationFn = Apollo.MutationFunction<
  UpdateStudentLightModeMutation,
  UpdateStudentLightModeMutationVariables
>;

/**
 * __useUpdateStudentLightModeMutation__
 *
 * To run a mutation, you first call `useUpdateStudentLightModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentLightModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentLightModeMutation, { data, loading, error }] = useUpdateStudentLightModeMutation({
 *   variables: {
 *      lightMode: // value for 'lightMode'
 *   },
 * });
 */
export function useUpdateStudentLightModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudentLightModeMutation,
    UpdateStudentLightModeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStudentLightModeMutation,
    UpdateStudentLightModeMutationVariables
  >(UpdateStudentLightModeDocument, options);
}
export type UpdateStudentLightModeMutationHookResult = ReturnType<
  typeof useUpdateStudentLightModeMutation
>;
export type UpdateStudentLightModeMutationResult =
  Apollo.MutationResult<UpdateStudentLightModeMutation>;
export type UpdateStudentLightModeMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudentLightModeMutation,
  UpdateStudentLightModeMutationVariables
>;
export const UploadFileDocument = gql`
  mutation UploadFile($input: UploadFileInput!) {
    uploadFile(input: $input) {
      ...FileUploadFragment
    }
  }
  ${FileUploadFragmentFragmentDoc}
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const FeatureAccountInformationDocument = gql`
  query FeatureAccountInformation {
    student: me {
      uuid
      email
      avatarUrl
      firstName
      lastName
      phoneNumber
      programStatus
      cohort {
        courseOffering {
          name
        }
        name
        uuid
      }
      profile {
        blogUrl
        dateOfBirth
        daylightSavingsTime
        educationalBackground
        ethnicity
        emergencyContactEmail
        emergencyContactName
        emergencyContactPhone
        emergencyContactRelationship
        gender
        id
        identityTerms {
          category
          id
        }
        linkedInProfileUrl
        militaryStatus
        portfolioSiteUrl
        preferredFirstName
        preferredPronouns
        races
        skilledInterviewFeedbackUrl
        skilledMentorFeedbackUrl
        timezone
        tshirtSize
      }
      shippingAddress {
        id
        line1
        line2
        city
        state
        zipcode
      }
      homeAddress {
        id
        line1
        line2
        city
        state
        zipcode
      }
    }
  }
`;

/**
 * __useFeatureAccountInformationQuery__
 *
 * To run a query within a React component, call `useFeatureAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAccountInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureAccountInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureAccountInformationQuery,
    FeatureAccountInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureAccountInformationQuery,
    FeatureAccountInformationQueryVariables
  >(FeatureAccountInformationDocument, options);
}
export function useFeatureAccountInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureAccountInformationQuery,
    FeatureAccountInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureAccountInformationQuery,
    FeatureAccountInformationQueryVariables
  >(FeatureAccountInformationDocument, options);
}
export type FeatureAccountInformationQueryHookResult = ReturnType<
  typeof useFeatureAccountInformationQuery
>;
export type FeatureAccountInformationLazyQueryHookResult = ReturnType<
  typeof useFeatureAccountInformationLazyQuery
>;
export type FeatureAccountInformationQueryResult = Apollo.QueryResult<
  FeatureAccountInformationQuery,
  FeatureAccountInformationQueryVariables
>;
export const FeatureContinueLearningDocument = gql`
  query FeatureContinueLearning {
    student: me {
      uuid
      ...LmsCourseLinksFragment
    }
  }
  ${LmsCourseLinksFragmentFragmentDoc}
`;

/**
 * __useFeatureContinueLearningQuery__
 *
 * To run a query within a React component, call `useFeatureContinueLearningQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureContinueLearningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureContinueLearningQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureContinueLearningQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureContinueLearningQuery,
    FeatureContinueLearningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureContinueLearningQuery,
    FeatureContinueLearningQueryVariables
  >(FeatureContinueLearningDocument, options);
}
export function useFeatureContinueLearningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureContinueLearningQuery,
    FeatureContinueLearningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureContinueLearningQuery,
    FeatureContinueLearningQueryVariables
  >(FeatureContinueLearningDocument, options);
}
export type FeatureContinueLearningQueryHookResult = ReturnType<
  typeof useFeatureContinueLearningQuery
>;
export type FeatureContinueLearningLazyQueryHookResult = ReturnType<
  typeof useFeatureContinueLearningLazyQuery
>;
export type FeatureContinueLearningQueryResult = Apollo.QueryResult<
  FeatureContinueLearningQuery,
  FeatureContinueLearningQueryVariables
>;
export const FeatureDiscoverContentDetailsDocument = gql`
  query FeatureDiscoverContentDetails($slug: String!) {
    content(slug: $slug) {
      ...ContentFragment
      description
      embedUrl
    }
  }
  ${ContentFragmentFragmentDoc}
`;

/**
 * __useFeatureDiscoverContentDetailsQuery__
 *
 * To run a query within a React component, call `useFeatureDiscoverContentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDiscoverContentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDiscoverContentDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFeatureDiscoverContentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureDiscoverContentDetailsQuery,
    FeatureDiscoverContentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureDiscoverContentDetailsQuery,
    FeatureDiscoverContentDetailsQueryVariables
  >(FeatureDiscoverContentDetailsDocument, options);
}
export function useFeatureDiscoverContentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureDiscoverContentDetailsQuery,
    FeatureDiscoverContentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureDiscoverContentDetailsQuery,
    FeatureDiscoverContentDetailsQueryVariables
  >(FeatureDiscoverContentDetailsDocument, options);
}
export type FeatureDiscoverContentDetailsQueryHookResult = ReturnType<
  typeof useFeatureDiscoverContentDetailsQuery
>;
export type FeatureDiscoverContentDetailsLazyQueryHookResult = ReturnType<
  typeof useFeatureDiscoverContentDetailsLazyQuery
>;
export type FeatureDiscoverContentDetailsQueryResult = Apollo.QueryResult<
  FeatureDiscoverContentDetailsQuery,
  FeatureDiscoverContentDetailsQueryVariables
>;
export const FeatureDiscoverContentsDocument = gql`
  query FeatureDiscoverContents($filters: ContentFilterInput!, $limit: Int) {
    contents(filters: $filters, limit: $limit) {
      ...ContentFragment
    }
  }
  ${ContentFragmentFragmentDoc}
`;

/**
 * __useFeatureDiscoverContentsQuery__
 *
 * To run a query within a React component, call `useFeatureDiscoverContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDiscoverContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDiscoverContentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFeatureDiscoverContentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureDiscoverContentsQuery,
    FeatureDiscoverContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureDiscoverContentsQuery,
    FeatureDiscoverContentsQueryVariables
  >(FeatureDiscoverContentsDocument, options);
}
export function useFeatureDiscoverContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureDiscoverContentsQuery,
    FeatureDiscoverContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureDiscoverContentsQuery,
    FeatureDiscoverContentsQueryVariables
  >(FeatureDiscoverContentsDocument, options);
}
export type FeatureDiscoverContentsQueryHookResult = ReturnType<
  typeof useFeatureDiscoverContentsQuery
>;
export type FeatureDiscoverContentsLazyQueryHookResult = ReturnType<
  typeof useFeatureDiscoverContentsLazyQuery
>;
export type FeatureDiscoverContentsQueryResult = Apollo.QueryResult<
  FeatureDiscoverContentsQuery,
  FeatureDiscoverContentsQueryVariables
>;
export const FeatureDiscoverFilterOptionsDocument = gql`
  query FeatureDiscoverFilterOptions {
    contentFilterOptions {
      disciplines {
        uuid
        name
      }
      phases {
        uuid
        name
        disciplineUuid
      }
      tags {
        uuid
        name
      }
    }
  }
`;

/**
 * __useFeatureDiscoverFilterOptionsQuery__
 *
 * To run a query within a React component, call `useFeatureDiscoverFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDiscoverFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDiscoverFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureDiscoverFilterOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureDiscoverFilterOptionsQuery,
    FeatureDiscoverFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureDiscoverFilterOptionsQuery,
    FeatureDiscoverFilterOptionsQueryVariables
  >(FeatureDiscoverFilterOptionsDocument, options);
}
export function useFeatureDiscoverFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureDiscoverFilterOptionsQuery,
    FeatureDiscoverFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureDiscoverFilterOptionsQuery,
    FeatureDiscoverFilterOptionsQueryVariables
  >(FeatureDiscoverFilterOptionsDocument, options);
}
export type FeatureDiscoverFilterOptionsQueryHookResult = ReturnType<
  typeof useFeatureDiscoverFilterOptionsQuery
>;
export type FeatureDiscoverFilterOptionsLazyQueryHookResult = ReturnType<
  typeof useFeatureDiscoverFilterOptionsLazyQuery
>;
export type FeatureDiscoverFilterOptionsQueryResult = Apollo.QueryResult<
  FeatureDiscoverFilterOptionsQuery,
  FeatureDiscoverFilterOptionsQueryVariables
>;
export const FeatureDiscoverHighlightsDocument = gql`
  query FeatureDiscoverHighlights {
    contentHighlights {
      careerContent {
        ...ContentFragment
      }
      communityContent {
        ...ContentFragment
      }
      lectureContent {
        ...ContentFragment
      }
      lectureDiscipline {
        name
        uuid
      }
    }
  }
  ${ContentFragmentFragmentDoc}
`;

/**
 * __useFeatureDiscoverHighlightsQuery__
 *
 * To run a query within a React component, call `useFeatureDiscoverHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDiscoverHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDiscoverHighlightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureDiscoverHighlightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureDiscoverHighlightsQuery,
    FeatureDiscoverHighlightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureDiscoverHighlightsQuery,
    FeatureDiscoverHighlightsQueryVariables
  >(FeatureDiscoverHighlightsDocument, options);
}
export function useFeatureDiscoverHighlightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureDiscoverHighlightsQuery,
    FeatureDiscoverHighlightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureDiscoverHighlightsQuery,
    FeatureDiscoverHighlightsQueryVariables
  >(FeatureDiscoverHighlightsDocument, options);
}
export type FeatureDiscoverHighlightsQueryHookResult = ReturnType<
  typeof useFeatureDiscoverHighlightsQuery
>;
export type FeatureDiscoverHighlightsLazyQueryHookResult = ReturnType<
  typeof useFeatureDiscoverHighlightsLazyQuery
>;
export type FeatureDiscoverHighlightsQueryResult = Apollo.QueryResult<
  FeatureDiscoverHighlightsQuery,
  FeatureDiscoverHighlightsQueryVariables
>;
export const FeatureDocumentsDocument = gql`
  query FeatureDocuments {
    student: me {
      uuid
      complianceRequirements {
        ...ComplianceRequirementFragment
      }
      fileUploads {
        ...FileUploadFragment
      }
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
  ${FileUploadFragmentFragmentDoc}
`;

/**
 * __useFeatureDocumentsQuery__
 *
 * To run a query within a React component, call `useFeatureDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureDocumentsQuery,
    FeatureDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeatureDocumentsQuery, FeatureDocumentsQueryVariables>(
    FeatureDocumentsDocument,
    options
  );
}
export function useFeatureDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureDocumentsQuery,
    FeatureDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureDocumentsQuery,
    FeatureDocumentsQueryVariables
  >(FeatureDocumentsDocument, options);
}
export type FeatureDocumentsQueryHookResult = ReturnType<
  typeof useFeatureDocumentsQuery
>;
export type FeatureDocumentsLazyQueryHookResult = ReturnType<
  typeof useFeatureDocumentsLazyQuery
>;
export type FeatureDocumentsQueryResult = Apollo.QueryResult<
  FeatureDocumentsQuery,
  FeatureDocumentsQueryVariables
>;
export const GetFeatureMilestonesDocument = gql`
  query GetFeatureMilestones {
    student: me {
      uuid
      ...StudentFragment
    }
  }
  ${StudentFragmentFragmentDoc}
`;

/**
 * __useGetFeatureMilestonesQuery__
 *
 * To run a query within a React component, call `useGetFeatureMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureMilestonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureMilestonesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeatureMilestonesQuery,
    GetFeatureMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeatureMilestonesQuery,
    GetFeatureMilestonesQueryVariables
  >(GetFeatureMilestonesDocument, options);
}
export function useGetFeatureMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureMilestonesQuery,
    GetFeatureMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeatureMilestonesQuery,
    GetFeatureMilestonesQueryVariables
  >(GetFeatureMilestonesDocument, options);
}
export type GetFeatureMilestonesQueryHookResult = ReturnType<
  typeof useGetFeatureMilestonesQuery
>;
export type GetFeatureMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetFeatureMilestonesLazyQuery
>;
export type GetFeatureMilestonesQueryResult = Apollo.QueryResult<
  GetFeatureMilestonesQuery,
  GetFeatureMilestonesQueryVariables
>;
export const GetFeatureMilestonesAvailablePacingsDocument = gql`
  query GetFeatureMilestonesAvailablePacings {
    student: me {
      uuid
      cohort {
        uuid
      }
    }
  }
`;

/**
 * __useGetFeatureMilestonesAvailablePacingsQuery__
 *
 * To run a query within a React component, call `useGetFeatureMilestonesAvailablePacingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureMilestonesAvailablePacingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureMilestonesAvailablePacingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureMilestonesAvailablePacingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeatureMilestonesAvailablePacingsQuery,
    GetFeatureMilestonesAvailablePacingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeatureMilestonesAvailablePacingsQuery,
    GetFeatureMilestonesAvailablePacingsQueryVariables
  >(GetFeatureMilestonesAvailablePacingsDocument, options);
}
export function useGetFeatureMilestonesAvailablePacingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureMilestonesAvailablePacingsQuery,
    GetFeatureMilestonesAvailablePacingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeatureMilestonesAvailablePacingsQuery,
    GetFeatureMilestonesAvailablePacingsQueryVariables
  >(GetFeatureMilestonesAvailablePacingsDocument, options);
}
export type GetFeatureMilestonesAvailablePacingsQueryHookResult = ReturnType<
  typeof useGetFeatureMilestonesAvailablePacingsQuery
>;
export type GetFeatureMilestonesAvailablePacingsLazyQueryHookResult =
  ReturnType<typeof useGetFeatureMilestonesAvailablePacingsLazyQuery>;
export type GetFeatureMilestonesAvailablePacingsQueryResult =
  Apollo.QueryResult<
    GetFeatureMilestonesAvailablePacingsQuery,
    GetFeatureMilestonesAvailablePacingsQueryVariables
  >;
export const FeatureRecommendedContentDocument = gql`
  query FeatureRecommendedContent {
    student: me {
      uuid
      ...RecommendedContentFragment
    }
  }
  ${RecommendedContentFragmentFragmentDoc}
`;

/**
 * __useFeatureRecommendedContentQuery__
 *
 * To run a query within a React component, call `useFeatureRecommendedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureRecommendedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureRecommendedContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureRecommendedContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureRecommendedContentQuery,
    FeatureRecommendedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureRecommendedContentQuery,
    FeatureRecommendedContentQueryVariables
  >(FeatureRecommendedContentDocument, options);
}
export function useFeatureRecommendedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureRecommendedContentQuery,
    FeatureRecommendedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureRecommendedContentQuery,
    FeatureRecommendedContentQueryVariables
  >(FeatureRecommendedContentDocument, options);
}
export type FeatureRecommendedContentQueryHookResult = ReturnType<
  typeof useFeatureRecommendedContentQuery
>;
export type FeatureRecommendedContentLazyQueryHookResult = ReturnType<
  typeof useFeatureRecommendedContentLazyQuery
>;
export type FeatureRecommendedContentQueryResult = Apollo.QueryResult<
  FeatureRecommendedContentQuery,
  FeatureRecommendedContentQueryVariables
>;
export const GetSavedLiveEventsDocument = gql`
  query GetSavedLiveEvents($limit: Int) {
    student: me {
      uuid
      savedLiveEvents(limit: $limit) {
        ...LiveEventFragment
      }
    }
  }
  ${LiveEventFragmentFragmentDoc}
`;

/**
 * __useGetSavedLiveEventsQuery__
 *
 * To run a query within a React component, call `useGetSavedLiveEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedLiveEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedLiveEventsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSavedLiveEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSavedLiveEventsQuery,
    GetSavedLiveEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSavedLiveEventsQuery,
    GetSavedLiveEventsQueryVariables
  >(GetSavedLiveEventsDocument, options);
}
export function useGetSavedLiveEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSavedLiveEventsQuery,
    GetSavedLiveEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSavedLiveEventsQuery,
    GetSavedLiveEventsQueryVariables
  >(GetSavedLiveEventsDocument, options);
}
export type GetSavedLiveEventsQueryHookResult = ReturnType<
  typeof useGetSavedLiveEventsQuery
>;
export type GetSavedLiveEventsLazyQueryHookResult = ReturnType<
  typeof useGetSavedLiveEventsLazyQuery
>;
export type GetSavedLiveEventsQueryResult = Apollo.QueryResult<
  GetSavedLiveEventsQuery,
  GetSavedLiveEventsQueryVariables
>;
export const GetSensitiveInfomationDocument = gql`
  query GetSensitiveInfomation {
    student: me {
      uuid
      dataAccessCode
    }
  }
`;

/**
 * __useGetSensitiveInfomationQuery__
 *
 * To run a query within a React component, call `useGetSensitiveInfomationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensitiveInfomationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensitiveInfomationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSensitiveInfomationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSensitiveInfomationQuery,
    GetSensitiveInfomationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSensitiveInfomationQuery,
    GetSensitiveInfomationQueryVariables
  >(GetSensitiveInfomationDocument, options);
}
export function useGetSensitiveInfomationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSensitiveInfomationQuery,
    GetSensitiveInfomationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSensitiveInfomationQuery,
    GetSensitiveInfomationQueryVariables
  >(GetSensitiveInfomationDocument, options);
}
export type GetSensitiveInfomationQueryHookResult = ReturnType<
  typeof useGetSensitiveInfomationQuery
>;
export type GetSensitiveInfomationLazyQueryHookResult = ReturnType<
  typeof useGetSensitiveInfomationLazyQuery
>;
export type GetSensitiveInfomationQueryResult = Apollo.QueryResult<
  GetSensitiveInfomationQuery,
  GetSensitiveInfomationQueryVariables
>;
export const FeatureStudentDocumentsDocument = gql`
  query FeatureStudentDocuments($uuid: ID!) {
    student(uuid: $uuid) {
      uuid
      firstName
      lastName
      complianceRequirements {
        ...ComplianceRequirementFragment
      }
      fileUploads {
        ...FileUploadFragment
      }
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
  ${FileUploadFragmentFragmentDoc}
`;

/**
 * __useFeatureStudentDocumentsQuery__
 *
 * To run a query within a React component, call `useFeatureStudentDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureStudentDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureStudentDocumentsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFeatureStudentDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeatureStudentDocumentsQuery,
    FeatureStudentDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FeatureStudentDocumentsQuery,
    FeatureStudentDocumentsQueryVariables
  >(FeatureStudentDocumentsDocument, options);
}
export function useFeatureStudentDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureStudentDocumentsQuery,
    FeatureStudentDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeatureStudentDocumentsQuery,
    FeatureStudentDocumentsQueryVariables
  >(FeatureStudentDocumentsDocument, options);
}
export type FeatureStudentDocumentsQueryHookResult = ReturnType<
  typeof useFeatureStudentDocumentsQuery
>;
export type FeatureStudentDocumentsLazyQueryHookResult = ReturnType<
  typeof useFeatureStudentDocumentsLazyQuery
>;
export type FeatureStudentDocumentsQueryResult = Apollo.QueryResult<
  FeatureStudentDocumentsQuery,
  FeatureStudentDocumentsQueryVariables
>;
export const GetFeatureStudentMilestonesDocument = gql`
  query GetFeatureStudentMilestones($uuid: ID!) {
    student(uuid: $uuid) {
      ...StudentFragment
    }
  }
  ${StudentFragmentFragmentDoc}
`;

/**
 * __useGetFeatureStudentMilestonesQuery__
 *
 * To run a query within a React component, call `useGetFeatureStudentMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureStudentMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureStudentMilestonesQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetFeatureStudentMilestonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeatureStudentMilestonesQuery,
    GetFeatureStudentMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeatureStudentMilestonesQuery,
    GetFeatureStudentMilestonesQueryVariables
  >(GetFeatureStudentMilestonesDocument, options);
}
export function useGetFeatureStudentMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureStudentMilestonesQuery,
    GetFeatureStudentMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeatureStudentMilestonesQuery,
    GetFeatureStudentMilestonesQueryVariables
  >(GetFeatureStudentMilestonesDocument, options);
}
export type GetFeatureStudentMilestonesQueryHookResult = ReturnType<
  typeof useGetFeatureStudentMilestonesQuery
>;
export type GetFeatureStudentMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetFeatureStudentMilestonesLazyQuery
>;
export type GetFeatureStudentMilestonesQueryResult = Apollo.QueryResult<
  GetFeatureStudentMilestonesQuery,
  GetFeatureStudentMilestonesQueryVariables
>;
export const GetFeatureStudentMilestonesAvailablePacingsDocument = gql`
  query GetFeatureStudentMilestonesAvailablePacings($uuid: ID!) {
    student(uuid: $uuid) {
      uuid
      cohort {
        uuid
      }
    }
  }
`;

/**
 * __useGetFeatureStudentMilestonesAvailablePacingsQuery__
 *
 * To run a query within a React component, call `useGetFeatureStudentMilestonesAvailablePacingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureStudentMilestonesAvailablePacingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureStudentMilestonesAvailablePacingsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetFeatureStudentMilestonesAvailablePacingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeatureStudentMilestonesAvailablePacingsQuery,
    GetFeatureStudentMilestonesAvailablePacingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeatureStudentMilestonesAvailablePacingsQuery,
    GetFeatureStudentMilestonesAvailablePacingsQueryVariables
  >(GetFeatureStudentMilestonesAvailablePacingsDocument, options);
}
export function useGetFeatureStudentMilestonesAvailablePacingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureStudentMilestonesAvailablePacingsQuery,
    GetFeatureStudentMilestonesAvailablePacingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeatureStudentMilestonesAvailablePacingsQuery,
    GetFeatureStudentMilestonesAvailablePacingsQueryVariables
  >(GetFeatureStudentMilestonesAvailablePacingsDocument, options);
}
export type GetFeatureStudentMilestonesAvailablePacingsQueryHookResult =
  ReturnType<typeof useGetFeatureStudentMilestonesAvailablePacingsQuery>;
export type GetFeatureStudentMilestonesAvailablePacingsLazyQueryHookResult =
  ReturnType<typeof useGetFeatureStudentMilestonesAvailablePacingsLazyQuery>;
export type GetFeatureStudentMilestonesAvailablePacingsQueryResult =
  Apollo.QueryResult<
    GetFeatureStudentMilestonesAvailablePacingsQuery,
    GetFeatureStudentMilestonesAvailablePacingsQueryVariables
  >;
export const GetRecommendationsDocument = gql`
  query GetRecommendations($uuid: ID!) {
    student(uuid: $uuid) {
      uuid
      firstName
      recommendations {
        ...RecommendationFragment
      }
    }
  }
  ${RecommendationFragmentFragmentDoc}
`;

/**
 * __useGetRecommendationsQuery__
 *
 * To run a query within a React component, call `useGetRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendationsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecommendationsQuery,
    GetRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecommendationsQuery,
    GetRecommendationsQueryVariables
  >(GetRecommendationsDocument, options);
}
export function useGetRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecommendationsQuery,
    GetRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecommendationsQuery,
    GetRecommendationsQueryVariables
  >(GetRecommendationsDocument, options);
}
export type GetRecommendationsQueryHookResult = ReturnType<
  typeof useGetRecommendationsQuery
>;
export type GetRecommendationsLazyQueryHookResult = ReturnType<
  typeof useGetRecommendationsLazyQuery
>;
export type GetRecommendationsQueryResult = Apollo.QueryResult<
  GetRecommendationsQuery,
  GetRecommendationsQueryVariables
>;
export const GetStudentsComplianceRequirementsDocument = gql`
  query getStudentsComplianceRequirements($uuid: ID!) {
    student(uuid: $uuid) {
      firstName
      lastName
      complianceRequirements {
        ...ComplianceRequirementFragment
      }
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
`;

/**
 * __useGetStudentsComplianceRequirementsQuery__
 *
 * To run a query within a React component, call `useGetStudentsComplianceRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsComplianceRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsComplianceRequirementsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetStudentsComplianceRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentsComplianceRequirementsQuery,
    GetStudentsComplianceRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentsComplianceRequirementsQuery,
    GetStudentsComplianceRequirementsQueryVariables
  >(GetStudentsComplianceRequirementsDocument, options);
}
export function useGetStudentsComplianceRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentsComplianceRequirementsQuery,
    GetStudentsComplianceRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentsComplianceRequirementsQuery,
    GetStudentsComplianceRequirementsQueryVariables
  >(GetStudentsComplianceRequirementsDocument, options);
}
export type GetStudentsComplianceRequirementsQueryHookResult = ReturnType<
  typeof useGetStudentsComplianceRequirementsQuery
>;
export type GetStudentsComplianceRequirementsLazyQueryHookResult = ReturnType<
  typeof useGetStudentsComplianceRequirementsLazyQuery
>;
export type GetStudentsComplianceRequirementsQueryResult = Apollo.QueryResult<
  GetStudentsComplianceRequirementsQuery,
  GetStudentsComplianceRequirementsQueryVariables
>;
export const GetAvailableLiveEventsDocument = gql`
  query GetAvailableLiveEvents($filters: LiveEventFilterInput!, $limit: Int) {
    student: me {
      uuid
      availableLiveEvents(filters: $filters, limit: $limit) {
        filters {
          disciplineFilter {
            className
            options {
              name
              uuid
            }
          }
          phaseFilter {
            className
            options {
              name
              uuid
            }
          }
          liveEventTypeFilter {
            className
            options {
              name
              uuid
            }
          }
        }
        liveEvents {
          ...LiveEventFragment
        }
      }
    }
  }
  ${LiveEventFragmentFragmentDoc}
`;

/**
 * __useGetAvailableLiveEventsQuery__
 *
 * To run a query within a React component, call `useGetAvailableLiveEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableLiveEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableLiveEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAvailableLiveEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableLiveEventsQuery,
    GetAvailableLiveEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableLiveEventsQuery,
    GetAvailableLiveEventsQueryVariables
  >(GetAvailableLiveEventsDocument, options);
}
export function useGetAvailableLiveEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableLiveEventsQuery,
    GetAvailableLiveEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableLiveEventsQuery,
    GetAvailableLiveEventsQueryVariables
  >(GetAvailableLiveEventsDocument, options);
}
export type GetAvailableLiveEventsQueryHookResult = ReturnType<
  typeof useGetAvailableLiveEventsQuery
>;
export type GetAvailableLiveEventsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableLiveEventsLazyQuery
>;
export type GetAvailableLiveEventsQueryResult = Apollo.QueryResult<
  GetAvailableLiveEventsQuery,
  GetAvailableLiveEventsQueryVariables
>;
export const GetAdmissionDateDocument = gql`
  query getAdmissionDate {
    student: me {
      admissionsInterviewDate
    }
  }
`;

/**
 * __useGetAdmissionDateQuery__
 *
 * To run a query within a React component, call `useGetAdmissionDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdmissionDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdmissionDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdmissionDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdmissionDateQuery,
    GetAdmissionDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdmissionDateQuery, GetAdmissionDateQueryVariables>(
    GetAdmissionDateDocument,
    options
  );
}
export function useGetAdmissionDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdmissionDateQuery,
    GetAdmissionDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdmissionDateQuery,
    GetAdmissionDateQueryVariables
  >(GetAdmissionDateDocument, options);
}
export type GetAdmissionDateQueryHookResult = ReturnType<
  typeof useGetAdmissionDateQuery
>;
export type GetAdmissionDateLazyQueryHookResult = ReturnType<
  typeof useGetAdmissionDateLazyQuery
>;
export type GetAdmissionDateQueryResult = Apollo.QueryResult<
  GetAdmissionDateQuery,
  GetAdmissionDateQueryVariables
>;
export const GetAvatarUploadUrlDocument = gql`
  query GetAvatarUploadUrl($filename: String!) {
    getAvatarUploadUrl(filename: $filename) {
      url
      urlFields {
        acl
        key
        policy
        xAmzAlgorithm
        xAmzCredential
        xAmzDate
        xAmzSignature
      }
    }
  }
`;

/**
 * __useGetAvatarUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetAvatarUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvatarUploadUrlQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetAvatarUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvatarUploadUrlQuery,
    GetAvatarUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvatarUploadUrlQuery,
    GetAvatarUploadUrlQueryVariables
  >(GetAvatarUploadUrlDocument, options);
}
export function useGetAvatarUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvatarUploadUrlQuery,
    GetAvatarUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvatarUploadUrlQuery,
    GetAvatarUploadUrlQueryVariables
  >(GetAvatarUploadUrlDocument, options);
}
export type GetAvatarUploadUrlQueryHookResult = ReturnType<
  typeof useGetAvatarUploadUrlQuery
>;
export type GetAvatarUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetAvatarUploadUrlLazyQuery
>;
export type GetAvatarUploadUrlQueryResult = Apollo.QueryResult<
  GetAvatarUploadUrlQuery,
  GetAvatarUploadUrlQueryVariables
>;
export const GetCanvasMilestonesDocument = gql`
  query GetCanvasMilestones {
    student: me {
      ...MilestonesQuery
    }
  }
  ${MilestonesQueryFragmentDoc}
`;

/**
 * __useGetCanvasMilestonesQuery__
 *
 * To run a query within a React component, call `useGetCanvasMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCanvasMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCanvasMilestonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCanvasMilestonesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCanvasMilestonesQuery,
    GetCanvasMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCanvasMilestonesQuery,
    GetCanvasMilestonesQueryVariables
  >(GetCanvasMilestonesDocument, options);
}
export function useGetCanvasMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCanvasMilestonesQuery,
    GetCanvasMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCanvasMilestonesQuery,
    GetCanvasMilestonesQueryVariables
  >(GetCanvasMilestonesDocument, options);
}
export type GetCanvasMilestonesQueryHookResult = ReturnType<
  typeof useGetCanvasMilestonesQuery
>;
export type GetCanvasMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetCanvasMilestonesLazyQuery
>;
export type GetCanvasMilestonesQueryResult = Apollo.QueryResult<
  GetCanvasMilestonesQuery,
  GetCanvasMilestonesQueryVariables
>;
export const GetCanvasMilestonesForStudentDocument = gql`
  query GetCanvasMilestonesForStudent($uuid: ID!) {
    student(uuid: $uuid) {
      ...MilestonesQuery
    }
  }
  ${MilestonesQueryFragmentDoc}
`;

/**
 * __useGetCanvasMilestonesForStudentQuery__
 *
 * To run a query within a React component, call `useGetCanvasMilestonesForStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCanvasMilestonesForStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCanvasMilestonesForStudentQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetCanvasMilestonesForStudentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCanvasMilestonesForStudentQuery,
    GetCanvasMilestonesForStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCanvasMilestonesForStudentQuery,
    GetCanvasMilestonesForStudentQueryVariables
  >(GetCanvasMilestonesForStudentDocument, options);
}
export function useGetCanvasMilestonesForStudentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCanvasMilestonesForStudentQuery,
    GetCanvasMilestonesForStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCanvasMilestonesForStudentQuery,
    GetCanvasMilestonesForStudentQueryVariables
  >(GetCanvasMilestonesForStudentDocument, options);
}
export type GetCanvasMilestonesForStudentQueryHookResult = ReturnType<
  typeof useGetCanvasMilestonesForStudentQuery
>;
export type GetCanvasMilestonesForStudentLazyQueryHookResult = ReturnType<
  typeof useGetCanvasMilestonesForStudentLazyQuery
>;
export type GetCanvasMilestonesForStudentQueryResult = Apollo.QueryResult<
  GetCanvasMilestonesForStudentQuery,
  GetCanvasMilestonesForStudentQueryVariables
>;
export const GetCohortDocument = gql`
  query GetCohort($uuid: ID!) {
    cohort(uuid: $uuid) {
      uuid
      name
      startDate
      endDate
      students {
        uuid
        firstName
        lastName
        avatarUrl
        complianceRequirements {
          ...ComplianceRequirementFragment
        }
        fileUploads {
          ...FileUploadFragment
        }
      }
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
  ${FileUploadFragmentFragmentDoc}
`;

/**
 * __useGetCohortQuery__
 *
 * To run a query within a React component, call `useGetCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetCohortQuery(
  baseOptions: Apollo.QueryHookOptions<GetCohortQuery, GetCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCohortQuery, GetCohortQueryVariables>(
    GetCohortDocument,
    options
  );
}
export function useGetCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortQuery,
    GetCohortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCohortQuery, GetCohortQueryVariables>(
    GetCohortDocument,
    options
  );
}
export type GetCohortQueryHookResult = ReturnType<typeof useGetCohortQuery>;
export type GetCohortLazyQueryHookResult = ReturnType<
  typeof useGetCohortLazyQuery
>;
export type GetCohortQueryResult = Apollo.QueryResult<
  GetCohortQuery,
  GetCohortQueryVariables
>;
export const GetCohortsDocument = gql`
  query GetCohorts {
    cohorts {
      uuid
      name
      startDate
    }
  }
`;

/**
 * __useGetCohortsQuery__
 *
 * To run a query within a React component, call `useGetCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCohortsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCohortsQuery,
    GetCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCohortsQuery, GetCohortsQueryVariables>(
    GetCohortsDocument,
    options
  );
}
export function useGetCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortsQuery,
    GetCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCohortsQuery, GetCohortsQueryVariables>(
    GetCohortsDocument,
    options
  );
}
export type GetCohortsQueryHookResult = ReturnType<typeof useGetCohortsQuery>;
export type GetCohortsLazyQueryHookResult = ReturnType<
  typeof useGetCohortsLazyQuery
>;
export type GetCohortsQueryResult = Apollo.QueryResult<
  GetCohortsQuery,
  GetCohortsQueryVariables
>;
export const GetComplianceRequirementEnvelopeUrlDocument = gql`
  query GetComplianceRequirementEnvelopeUrl($uuid: ID!) {
    complianceRequirementEnvelopeUrl(uuid: $uuid)
  }
`;

/**
 * __useGetComplianceRequirementEnvelopeUrlQuery__
 *
 * To run a query within a React component, call `useGetComplianceRequirementEnvelopeUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceRequirementEnvelopeUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceRequirementEnvelopeUrlQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetComplianceRequirementEnvelopeUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceRequirementEnvelopeUrlQuery,
    GetComplianceRequirementEnvelopeUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceRequirementEnvelopeUrlQuery,
    GetComplianceRequirementEnvelopeUrlQueryVariables
  >(GetComplianceRequirementEnvelopeUrlDocument, options);
}
export function useGetComplianceRequirementEnvelopeUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceRequirementEnvelopeUrlQuery,
    GetComplianceRequirementEnvelopeUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceRequirementEnvelopeUrlQuery,
    GetComplianceRequirementEnvelopeUrlQueryVariables
  >(GetComplianceRequirementEnvelopeUrlDocument, options);
}
export type GetComplianceRequirementEnvelopeUrlQueryHookResult = ReturnType<
  typeof useGetComplianceRequirementEnvelopeUrlQuery
>;
export type GetComplianceRequirementEnvelopeUrlLazyQueryHookResult = ReturnType<
  typeof useGetComplianceRequirementEnvelopeUrlLazyQuery
>;
export type GetComplianceRequirementEnvelopeUrlQueryResult = Apollo.QueryResult<
  GetComplianceRequirementEnvelopeUrlQuery,
  GetComplianceRequirementEnvelopeUrlQueryVariables
>;
export const GetCtasDocument = gql`
  query GetCtas {
    student: me {
      callsToAction {
        id
        text
        subText
        buttonText
        url
      }
    }
  }
`;

/**
 * __useGetCtasQuery__
 *
 * To run a query within a React component, call `useGetCtasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCtasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCtasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCtasQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCtasQuery, GetCtasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCtasQuery, GetCtasQueryVariables>(
    GetCtasDocument,
    options
  );
}
export function useGetCtasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCtasQuery, GetCtasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCtasQuery, GetCtasQueryVariables>(
    GetCtasDocument,
    options
  );
}
export type GetCtasQueryHookResult = ReturnType<typeof useGetCtasQuery>;
export type GetCtasLazyQueryHookResult = ReturnType<typeof useGetCtasLazyQuery>;
export type GetCtasQueryResult = Apollo.QueryResult<
  GetCtasQuery,
  GetCtasQueryVariables
>;
export const GetDocumentSetDocument = gql`
  query GetDocumentSet($uuid: ID!) {
    documentSet(uuid: $uuid) {
      ...documentSetFragment
      cohorts {
        name
        uuid
      }
      recipientGroups {
        name
        uuid
      }
      fileUploadTypes {
        name
        uuid
        createdAt
        visibleToStudent
        studentCount
      }
      retiredAt
    }
  }
  ${DocumentSetFragmentFragmentDoc}
`;

/**
 * __useGetDocumentSetQuery__
 *
 * To run a query within a React component, call `useGetDocumentSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentSetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetDocumentSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentSetQuery,
    GetDocumentSetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocumentSetQuery, GetDocumentSetQueryVariables>(
    GetDocumentSetDocument,
    options
  );
}
export function useGetDocumentSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentSetQuery,
    GetDocumentSetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDocumentSetQuery, GetDocumentSetQueryVariables>(
    GetDocumentSetDocument,
    options
  );
}
export type GetDocumentSetQueryHookResult = ReturnType<
  typeof useGetDocumentSetQuery
>;
export type GetDocumentSetLazyQueryHookResult = ReturnType<
  typeof useGetDocumentSetLazyQuery
>;
export type GetDocumentSetQueryResult = Apollo.QueryResult<
  GetDocumentSetQuery,
  GetDocumentSetQueryVariables
>;
export const GetDocumentSetsDocument = gql`
  query GetDocumentSets {
    documentSets {
      ...documentSetFragment
      fileUploadTypes {
        name
        uuid
        createdAt
        visibleToStudent
      }
    }
  }
  ${DocumentSetFragmentFragmentDoc}
`;

/**
 * __useGetDocumentSetsQuery__
 *
 * To run a query within a React component, call `useGetDocumentSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentSetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDocumentSetsQuery,
    GetDocumentSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocumentSetsQuery, GetDocumentSetsQueryVariables>(
    GetDocumentSetsDocument,
    options
  );
}
export function useGetDocumentSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentSetsQuery,
    GetDocumentSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocumentSetsQuery,
    GetDocumentSetsQueryVariables
  >(GetDocumentSetsDocument, options);
}
export type GetDocumentSetsQueryHookResult = ReturnType<
  typeof useGetDocumentSetsQuery
>;
export type GetDocumentSetsLazyQueryHookResult = ReturnType<
  typeof useGetDocumentSetsLazyQuery
>;
export type GetDocumentSetsQueryResult = Apollo.QueryResult<
  GetDocumentSetsQuery,
  GetDocumentSetsQueryVariables
>;
export const GetDocumentSetsByCohortDocument = gql`
  query GetDocumentSetsByCohort($cohortId: ID!) {
    documentSets(cohortId: $cohortId) {
      ...documentSetFragment
    }
  }
  ${DocumentSetFragmentFragmentDoc}
`;

/**
 * __useGetDocumentSetsByCohortQuery__
 *
 * To run a query within a React component, call `useGetDocumentSetsByCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentSetsByCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentSetsByCohortQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetDocumentSetsByCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentSetsByCohortQuery,
    GetDocumentSetsByCohortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDocumentSetsByCohortQuery,
    GetDocumentSetsByCohortQueryVariables
  >(GetDocumentSetsByCohortDocument, options);
}
export function useGetDocumentSetsByCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentSetsByCohortQuery,
    GetDocumentSetsByCohortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocumentSetsByCohortQuery,
    GetDocumentSetsByCohortQueryVariables
  >(GetDocumentSetsByCohortDocument, options);
}
export type GetDocumentSetsByCohortQueryHookResult = ReturnType<
  typeof useGetDocumentSetsByCohortQuery
>;
export type GetDocumentSetsByCohortLazyQueryHookResult = ReturnType<
  typeof useGetDocumentSetsByCohortLazyQuery
>;
export type GetDocumentSetsByCohortQueryResult = Apollo.QueryResult<
  GetDocumentSetsByCohortQuery,
  GetDocumentSetsByCohortQueryVariables
>;
export const GetDocusignTemplatesDocument = gql`
  query GetDocusignTemplates {
    docusignTemplates {
      ...docusignTemplateFragment
    }
  }
  ${DocusignTemplateFragmentFragmentDoc}
`;

/**
 * __useGetDocusignTemplatesQuery__
 *
 * To run a query within a React component, call `useGetDocusignTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocusignTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocusignTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocusignTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDocusignTemplatesQuery,
    GetDocusignTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDocusignTemplatesQuery,
    GetDocusignTemplatesQueryVariables
  >(GetDocusignTemplatesDocument, options);
}
export function useGetDocusignTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocusignTemplatesQuery,
    GetDocusignTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocusignTemplatesQuery,
    GetDocusignTemplatesQueryVariables
  >(GetDocusignTemplatesDocument, options);
}
export type GetDocusignTemplatesQueryHookResult = ReturnType<
  typeof useGetDocusignTemplatesQuery
>;
export type GetDocusignTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetDocusignTemplatesLazyQuery
>;
export type GetDocusignTemplatesQueryResult = Apollo.QueryResult<
  GetDocusignTemplatesQuery,
  GetDocusignTemplatesQueryVariables
>;
export const GetEducatorDocument = gql`
  query GetEducator($uuid: ID!) {
    educator(uuid: $uuid) {
      ...EducatorFragment
    }
  }
  ${EducatorFragmentFragmentDoc}
`;

/**
 * __useGetEducatorQuery__
 *
 * To run a query within a React component, call `useGetEducatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducatorQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetEducatorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEducatorQuery,
    GetEducatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEducatorQuery, GetEducatorQueryVariables>(
    GetEducatorDocument,
    options
  );
}
export function useGetEducatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEducatorQuery,
    GetEducatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEducatorQuery, GetEducatorQueryVariables>(
    GetEducatorDocument,
    options
  );
}
export type GetEducatorQueryHookResult = ReturnType<typeof useGetEducatorQuery>;
export type GetEducatorLazyQueryHookResult = ReturnType<
  typeof useGetEducatorLazyQuery
>;
export type GetEducatorQueryResult = Apollo.QueryResult<
  GetEducatorQuery,
  GetEducatorQueryVariables
>;
export const GetEducatorRecentNotesDocument = gql`
  query GetEducatorRecentNotes($uuid: ID!) {
    educator(uuid: $uuid) {
      uuid
      students {
        ...StudentFragment
        mostRecentNote {
          id
          aptitude
          attitude
          categoryId
          createdAt
          contentText
        }
      }
    }
  }
  ${StudentFragmentFragmentDoc}
`;

/**
 * __useGetEducatorRecentNotesQuery__
 *
 * To run a query within a React component, call `useGetEducatorRecentNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducatorRecentNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducatorRecentNotesQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetEducatorRecentNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEducatorRecentNotesQuery,
    GetEducatorRecentNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEducatorRecentNotesQuery,
    GetEducatorRecentNotesQueryVariables
  >(GetEducatorRecentNotesDocument, options);
}
export function useGetEducatorRecentNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEducatorRecentNotesQuery,
    GetEducatorRecentNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEducatorRecentNotesQuery,
    GetEducatorRecentNotesQueryVariables
  >(GetEducatorRecentNotesDocument, options);
}
export type GetEducatorRecentNotesQueryHookResult = ReturnType<
  typeof useGetEducatorRecentNotesQuery
>;
export type GetEducatorRecentNotesLazyQueryHookResult = ReturnType<
  typeof useGetEducatorRecentNotesLazyQuery
>;
export type GetEducatorRecentNotesQueryResult = Apollo.QueryResult<
  GetEducatorRecentNotesQuery,
  GetEducatorRecentNotesQueryVariables
>;
export const GetFileUploadTypesDocument = gql`
  query GetFileUploadTypes {
    fileUploadTypes {
      name
      uuid
      visibleToStudent
      associatedCohorts {
        name
        uuid
      }
      associatedRecipientGroups {
        ...recipientGroupFramgment
      }
      studentCount
    }
  }
  ${RecipientGroupFramgmentFragmentDoc}
`;

/**
 * __useGetFileUploadTypesQuery__
 *
 * To run a query within a React component, call `useGetFileUploadTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUploadTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFileUploadTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFileUploadTypesQuery,
    GetFileUploadTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFileUploadTypesQuery,
    GetFileUploadTypesQueryVariables
  >(GetFileUploadTypesDocument, options);
}
export function useGetFileUploadTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFileUploadTypesQuery,
    GetFileUploadTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFileUploadTypesQuery,
    GetFileUploadTypesQueryVariables
  >(GetFileUploadTypesDocument, options);
}
export type GetFileUploadTypesQueryHookResult = ReturnType<
  typeof useGetFileUploadTypesQuery
>;
export type GetFileUploadTypesLazyQueryHookResult = ReturnType<
  typeof useGetFileUploadTypesLazyQuery
>;
export type GetFileUploadTypesQueryResult = Apollo.QueryResult<
  GetFileUploadTypesQuery,
  GetFileUploadTypesQueryVariables
>;
export const GetFileUploadUrlDocument = gql`
  query GetFileUploadUrl($filename: String!) {
    getFileUploadUrl(filename: $filename) {
      url
      urlFields {
        acl
        key
        policy
        xAmzAlgorithm
        xAmzCredential
        xAmzDate
        xAmzSignature
      }
    }
  }
`;

/**
 * __useGetFileUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetFileUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUploadUrlQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetFileUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFileUploadUrlQuery,
    GetFileUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileUploadUrlQuery, GetFileUploadUrlQueryVariables>(
    GetFileUploadUrlDocument,
    options
  );
}
export function useGetFileUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFileUploadUrlQuery,
    GetFileUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFileUploadUrlQuery,
    GetFileUploadUrlQueryVariables
  >(GetFileUploadUrlDocument, options);
}
export type GetFileUploadUrlQueryHookResult = ReturnType<
  typeof useGetFileUploadUrlQuery
>;
export type GetFileUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetFileUploadUrlLazyQuery
>;
export type GetFileUploadUrlQueryResult = Apollo.QueryResult<
  GetFileUploadUrlQuery,
  GetFileUploadUrlQueryVariables
>;
export const GetHeaderDataDocument = gql`
  query GetHeaderData {
    student: me {
      avatarUrl
      cohort {
        courseOffering {
          pacingOption {
            name
          }
        }
        startDate
        endDate
        uuid
      }
      educator {
        uuid
        firstName
        lastName
        availabilityScheduleUrl
      }
      educators {
        uuid
        firstName
        lastName
        availabilityScheduleUrl
      }
      firstName
      institution {
        id
        productOfferings {
          nodes {
            id
            slug
          }
        }
      }
      chatLink
      lastName
      uuid
      programStatus
    }
  }
`;

/**
 * __useGetHeaderDataQuery__
 *
 * To run a query within a React component, call `useGetHeaderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHeaderDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHeaderDataQuery,
    GetHeaderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHeaderDataQuery, GetHeaderDataQueryVariables>(
    GetHeaderDataDocument,
    options
  );
}
export function useGetHeaderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHeaderDataQuery,
    GetHeaderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHeaderDataQuery, GetHeaderDataQueryVariables>(
    GetHeaderDataDocument,
    options
  );
}
export type GetHeaderDataQueryHookResult = ReturnType<
  typeof useGetHeaderDataQuery
>;
export type GetHeaderDataLazyQueryHookResult = ReturnType<
  typeof useGetHeaderDataLazyQuery
>;
export type GetHeaderDataQueryResult = Apollo.QueryResult<
  GetHeaderDataQuery,
  GetHeaderDataQueryVariables
>;
export const GetHomePageDataDocument = gql`
  query GetHomePageData {
    student: me {
      ...StudentFragment
    }
  }
  ${StudentFragmentFragmentDoc}
`;

/**
 * __useGetHomePageDataQuery__
 *
 * To run a query within a React component, call `useGetHomePageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomePageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomePageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomePageDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHomePageDataQuery,
    GetHomePageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHomePageDataQuery, GetHomePageDataQueryVariables>(
    GetHomePageDataDocument,
    options
  );
}
export function useGetHomePageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomePageDataQuery,
    GetHomePageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHomePageDataQuery,
    GetHomePageDataQueryVariables
  >(GetHomePageDataDocument, options);
}
export type GetHomePageDataQueryHookResult = ReturnType<
  typeof useGetHomePageDataQuery
>;
export type GetHomePageDataLazyQueryHookResult = ReturnType<
  typeof useGetHomePageDataLazyQuery
>;
export type GetHomePageDataQueryResult = Apollo.QueryResult<
  GetHomePageDataQuery,
  GetHomePageDataQueryVariables
>;
export const GetHuntrMemberDocument = gql`
  query GetHuntrMember {
    huntrMember {
      externalMemberId
      createdAt
    }
  }
`;

/**
 * __useGetHuntrMemberQuery__
 *
 * To run a query within a React component, call `useGetHuntrMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHuntrMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHuntrMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHuntrMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHuntrMemberQuery,
    GetHuntrMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHuntrMemberQuery, GetHuntrMemberQueryVariables>(
    GetHuntrMemberDocument,
    options
  );
}
export function useGetHuntrMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHuntrMemberQuery,
    GetHuntrMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHuntrMemberQuery, GetHuntrMemberQueryVariables>(
    GetHuntrMemberDocument,
    options
  );
}
export type GetHuntrMemberQueryHookResult = ReturnType<
  typeof useGetHuntrMemberQuery
>;
export type GetHuntrMemberLazyQueryHookResult = ReturnType<
  typeof useGetHuntrMemberLazyQuery
>;
export type GetHuntrMemberQueryResult = Apollo.QueryResult<
  GetHuntrMemberQuery,
  GetHuntrMemberQueryVariables
>;
export const GetLiveEventDocument = gql`
  query getLiveEvent($slug: String!) {
    getLiveEvent(slug: $slug) {
      ...LiveEventFragment
    }
  }
  ${LiveEventFragmentFragmentDoc}
`;

/**
 * __useGetLiveEventQuery__
 *
 * To run a query within a React component, call `useGetLiveEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveEventQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetLiveEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiveEventQuery,
    GetLiveEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLiveEventQuery, GetLiveEventQueryVariables>(
    GetLiveEventDocument,
    options
  );
}
export function useGetLiveEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiveEventQuery,
    GetLiveEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLiveEventQuery, GetLiveEventQueryVariables>(
    GetLiveEventDocument,
    options
  );
}
export type GetLiveEventQueryHookResult = ReturnType<
  typeof useGetLiveEventQuery
>;
export type GetLiveEventLazyQueryHookResult = ReturnType<
  typeof useGetLiveEventLazyQuery
>;
export type GetLiveEventQueryResult = Apollo.QueryResult<
  GetLiveEventQuery,
  GetLiveEventQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    student: me {
      ...StudentFragment
    }
  }
  ${StudentFragmentFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetMeGuideDocument = gql`
  query GetMeGuide {
    me {
      uuid
      firstName
      lastName
      avatarUrl
    }
  }
`;

/**
 * __useGetMeGuideQuery__
 *
 * To run a query within a React component, call `useGetMeGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeGuideQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeGuideQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMeGuideQuery,
    GetMeGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeGuideQuery, GetMeGuideQueryVariables>(
    GetMeGuideDocument,
    options
  );
}
export function useGetMeGuideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeGuideQuery,
    GetMeGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeGuideQuery, GetMeGuideQueryVariables>(
    GetMeGuideDocument,
    options
  );
}
export type GetMeGuideQueryHookResult = ReturnType<typeof useGetMeGuideQuery>;
export type GetMeGuideLazyQueryHookResult = ReturnType<
  typeof useGetMeGuideLazyQuery
>;
export type GetMeGuideQueryResult = Apollo.QueryResult<
  GetMeGuideQuery,
  GetMeGuideQueryVariables
>;
export const GetMilestonesDocument = gql`
  query GetMilestones {
    student: me {
      ...StudentFragment
    }
  }
  ${StudentFragmentFragmentDoc}
`;

/**
 * __useGetMilestonesQuery__
 *
 * To run a query within a React component, call `useGetMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilestonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMilestonesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMilestonesQuery,
    GetMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMilestonesQuery, GetMilestonesQueryVariables>(
    GetMilestonesDocument,
    options
  );
}
export function useGetMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMilestonesQuery,
    GetMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMilestonesQuery, GetMilestonesQueryVariables>(
    GetMilestonesDocument,
    options
  );
}
export type GetMilestonesQueryHookResult = ReturnType<
  typeof useGetMilestonesQuery
>;
export type GetMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetMilestonesLazyQuery
>;
export type GetMilestonesQueryResult = Apollo.QueryResult<
  GetMilestonesQuery,
  GetMilestonesQueryVariables
>;
export const GetMyEducatorsDocument = gql`
  query GetMyEducators {
    student: me {
      uuid
      educators {
        ...EducatorFragment
      }
    }
  }
  ${EducatorFragmentFragmentDoc}
`;

/**
 * __useGetMyEducatorsQuery__
 *
 * To run a query within a React component, call `useGetMyEducatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEducatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEducatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEducatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyEducatorsQuery,
    GetMyEducatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyEducatorsQuery, GetMyEducatorsQueryVariables>(
    GetMyEducatorsDocument,
    options
  );
}
export function useGetMyEducatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyEducatorsQuery,
    GetMyEducatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyEducatorsQuery, GetMyEducatorsQueryVariables>(
    GetMyEducatorsDocument,
    options
  );
}
export type GetMyEducatorsQueryHookResult = ReturnType<
  typeof useGetMyEducatorsQuery
>;
export type GetMyEducatorsLazyQueryHookResult = ReturnType<
  typeof useGetMyEducatorsLazyQuery
>;
export type GetMyEducatorsQueryResult = Apollo.QueryResult<
  GetMyEducatorsQuery,
  GetMyEducatorsQueryVariables
>;
export const GetMyEducatorsAvailabilityScheduleUrlDocument = gql`
  query GetMyEducatorsAvailabilityScheduleUrl {
    student: me {
      educator {
        availabilityScheduleUrl
      }
    }
  }
`;

/**
 * __useGetMyEducatorsAvailabilityScheduleUrlQuery__
 *
 * To run a query within a React component, call `useGetMyEducatorsAvailabilityScheduleUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEducatorsAvailabilityScheduleUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEducatorsAvailabilityScheduleUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEducatorsAvailabilityScheduleUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyEducatorsAvailabilityScheduleUrlQuery,
    GetMyEducatorsAvailabilityScheduleUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyEducatorsAvailabilityScheduleUrlQuery,
    GetMyEducatorsAvailabilityScheduleUrlQueryVariables
  >(GetMyEducatorsAvailabilityScheduleUrlDocument, options);
}
export function useGetMyEducatorsAvailabilityScheduleUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyEducatorsAvailabilityScheduleUrlQuery,
    GetMyEducatorsAvailabilityScheduleUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyEducatorsAvailabilityScheduleUrlQuery,
    GetMyEducatorsAvailabilityScheduleUrlQueryVariables
  >(GetMyEducatorsAvailabilityScheduleUrlDocument, options);
}
export type GetMyEducatorsAvailabilityScheduleUrlQueryHookResult = ReturnType<
  typeof useGetMyEducatorsAvailabilityScheduleUrlQuery
>;
export type GetMyEducatorsAvailabilityScheduleUrlLazyQueryHookResult =
  ReturnType<typeof useGetMyEducatorsAvailabilityScheduleUrlLazyQuery>;
export type GetMyEducatorsAvailabilityScheduleUrlQueryResult =
  Apollo.QueryResult<
    GetMyEducatorsAvailabilityScheduleUrlQuery,
    GetMyEducatorsAvailabilityScheduleUrlQueryVariables
  >;
export const GetNoteDocument = gql`
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      aptitude
      attitude
      contentText
      contentHtml
      category {
        ...CategoryFragment
      }
      accountabilitySession {
        attended
        date
      }
    }
  }
  ${CategoryFragmentFragmentDoc}
`;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNoteQuery(
  baseOptions: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    options
  );
}
export function useGetNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    options
  );
}
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<
  GetNoteQuery,
  GetNoteQueryVariables
>;
export const GetNotesCategoriesDocument = gql`
  query GetNotesCategories {
    allCategories {
      ...CategoryFragment
    }
  }
  ${CategoryFragmentFragmentDoc}
`;

/**
 * __useGetNotesCategoriesQuery__
 *
 * To run a query within a React component, call `useGetNotesCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotesCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotesCategoriesQuery,
    GetNotesCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotesCategoriesQuery,
    GetNotesCategoriesQueryVariables
  >(GetNotesCategoriesDocument, options);
}
export function useGetNotesCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotesCategoriesQuery,
    GetNotesCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotesCategoriesQuery,
    GetNotesCategoriesQueryVariables
  >(GetNotesCategoriesDocument, options);
}
export type GetNotesCategoriesQueryHookResult = ReturnType<
  typeof useGetNotesCategoriesQuery
>;
export type GetNotesCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetNotesCategoriesLazyQuery
>;
export type GetNotesCategoriesQueryResult = Apollo.QueryResult<
  GetNotesCategoriesQuery,
  GetNotesCategoriesQueryVariables
>;
export const GetPaceSelectorDataDocument = gql`
  query GetPaceSelectorData($uuid: ID!) {
    student(uuid: $uuid) {
      uuid
      cohort {
        uuid
      }
    }
  }
`;

/**
 * __useGetPaceSelectorDataQuery__
 *
 * To run a query within a React component, call `useGetPaceSelectorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaceSelectorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaceSelectorDataQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetPaceSelectorDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaceSelectorDataQuery,
    GetPaceSelectorDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaceSelectorDataQuery,
    GetPaceSelectorDataQueryVariables
  >(GetPaceSelectorDataDocument, options);
}
export function useGetPaceSelectorDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaceSelectorDataQuery,
    GetPaceSelectorDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaceSelectorDataQuery,
    GetPaceSelectorDataQueryVariables
  >(GetPaceSelectorDataDocument, options);
}
export type GetPaceSelectorDataQueryHookResult = ReturnType<
  typeof useGetPaceSelectorDataQuery
>;
export type GetPaceSelectorDataLazyQueryHookResult = ReturnType<
  typeof useGetPaceSelectorDataLazyQuery
>;
export type GetPaceSelectorDataQueryResult = Apollo.QueryResult<
  GetPaceSelectorDataQuery,
  GetPaceSelectorDataQueryVariables
>;
export const GetProgressDocument = gql`
  query GetProgress {
    student: me {
      cohort {
        courseOffering {
          name
        }
        disciplineName
        startDate
        endDate
      }
      graduated {
        graduatedAt
      }
      firstName
      programStatus
      uuid
      startedPrework
    }
  }
`;

/**
 * __useGetProgressQuery__
 *
 * To run a query within a React component, call `useGetProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProgressQuery,
    GetProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProgressQuery, GetProgressQueryVariables>(
    GetProgressDocument,
    options
  );
}
export function useGetProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProgressQuery,
    GetProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProgressQuery, GetProgressQueryVariables>(
    GetProgressDocument,
    options
  );
}
export type GetProgressQueryHookResult = ReturnType<typeof useGetProgressQuery>;
export type GetProgressLazyQueryHookResult = ReturnType<
  typeof useGetProgressLazyQuery
>;
export type GetProgressQueryResult = Apollo.QueryResult<
  GetProgressQuery,
  GetProgressQueryVariables
>;
export const GetRecipientGroupDocument = gql`
  query GetRecipientGroup($uuid: ID!) {
    recipientGroup(uuid: $uuid) {
      uuid
      name
      documentSets {
        name
        uuid
      }
      recipientsDeprecated {
        uuid
        firstName
        lastName
        avatarUrl
        complianceRequirements {
          ...ComplianceRequirementFragment
        }
        fileUploads {
          ...FileUploadFragment
        }
      }
    }
  }
  ${ComplianceRequirementFragmentFragmentDoc}
  ${FileUploadFragmentFragmentDoc}
`;

/**
 * __useGetRecipientGroupQuery__
 *
 * To run a query within a React component, call `useGetRecipientGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipientGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipientGroupQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetRecipientGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecipientGroupQuery,
    GetRecipientGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecipientGroupQuery,
    GetRecipientGroupQueryVariables
  >(GetRecipientGroupDocument, options);
}
export function useGetRecipientGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipientGroupQuery,
    GetRecipientGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecipientGroupQuery,
    GetRecipientGroupQueryVariables
  >(GetRecipientGroupDocument, options);
}
export type GetRecipientGroupQueryHookResult = ReturnType<
  typeof useGetRecipientGroupQuery
>;
export type GetRecipientGroupLazyQueryHookResult = ReturnType<
  typeof useGetRecipientGroupLazyQuery
>;
export type GetRecipientGroupQueryResult = Apollo.QueryResult<
  GetRecipientGroupQuery,
  GetRecipientGroupQueryVariables
>;
export const GetRecipientGroupsDocument = gql`
  query GetRecipientGroups {
    recipientGroups {
      uuid
      name
      createdAt
      documentSets {
        uuid
        name
      }
    }
  }
`;

/**
 * __useGetRecipientGroupsQuery__
 *
 * To run a query within a React component, call `useGetRecipientGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipientGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipientGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecipientGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecipientGroupsQuery,
    GetRecipientGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecipientGroupsQuery,
    GetRecipientGroupsQueryVariables
  >(GetRecipientGroupsDocument, options);
}
export function useGetRecipientGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipientGroupsQuery,
    GetRecipientGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecipientGroupsQuery,
    GetRecipientGroupsQueryVariables
  >(GetRecipientGroupsDocument, options);
}
export type GetRecipientGroupsQueryHookResult = ReturnType<
  typeof useGetRecipientGroupsQuery
>;
export type GetRecipientGroupsLazyQueryHookResult = ReturnType<
  typeof useGetRecipientGroupsLazyQuery
>;
export type GetRecipientGroupsQueryResult = Apollo.QueryResult<
  GetRecipientGroupsQuery,
  GetRecipientGroupsQueryVariables
>;
export const GetResumeUrlDocument = gql`
  query getResumeUrl {
    student: me {
      resumeUrl
    }
  }
`;

/**
 * __useGetResumeUrlQuery__
 *
 * To run a query within a React component, call `useGetResumeUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResumeUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResumeUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResumeUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetResumeUrlQuery,
    GetResumeUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResumeUrlQuery, GetResumeUrlQueryVariables>(
    GetResumeUrlDocument,
    options
  );
}
export function useGetResumeUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResumeUrlQuery,
    GetResumeUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetResumeUrlQuery, GetResumeUrlQueryVariables>(
    GetResumeUrlDocument,
    options
  );
}
export type GetResumeUrlQueryHookResult = ReturnType<
  typeof useGetResumeUrlQuery
>;
export type GetResumeUrlLazyQueryHookResult = ReturnType<
  typeof useGetResumeUrlLazyQuery
>;
export type GetResumeUrlQueryResult = Apollo.QueryResult<
  GetResumeUrlQuery,
  GetResumeUrlQueryVariables
>;
export const GetStudentDocument = gql`
  query GetStudent($uuid: ID!) {
    student(uuid: $uuid) {
      ...StudentFragment
      attendanceRecord {
        present
        absent
      }
      advisorCheckInAttendanceRecord {
        absent
        present
      }
      coachAccountabilityAttendanceRecord {
        absent
        present
      }
      institution {
        id
        name
      }
      coachingStatus {
        name
      }
      coachingTier {
        name
      }
      coachingStartDate
      coachingEndDate
    }
  }
  ${StudentFragmentFragmentDoc}
`;

/**
 * __useGetStudentQuery__
 *
 * To run a query within a React component, call `useGetStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetStudentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentQuery,
    GetStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentQuery, GetStudentQueryVariables>(
    GetStudentDocument,
    options
  );
}
export function useGetStudentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentQuery,
    GetStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentQuery, GetStudentQueryVariables>(
    GetStudentDocument,
    options
  );
}
export type GetStudentQueryHookResult = ReturnType<typeof useGetStudentQuery>;
export type GetStudentLazyQueryHookResult = ReturnType<
  typeof useGetStudentLazyQuery
>;
export type GetStudentQueryResult = Apollo.QueryResult<
  GetStudentQuery,
  GetStudentQueryVariables
>;
export const GetStudentDisciplineDocument = gql`
  query GetStudentDiscipline {
    me {
      cohort {
        disciplineUuid
      }
    }
  }
`;

/**
 * __useGetStudentDisciplineQuery__
 *
 * To run a query within a React component, call `useGetStudentDisciplineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentDisciplineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentDisciplineQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentDisciplineQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStudentDisciplineQuery,
    GetStudentDisciplineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentDisciplineQuery,
    GetStudentDisciplineQueryVariables
  >(GetStudentDisciplineDocument, options);
}
export function useGetStudentDisciplineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentDisciplineQuery,
    GetStudentDisciplineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentDisciplineQuery,
    GetStudentDisciplineQueryVariables
  >(GetStudentDisciplineDocument, options);
}
export type GetStudentDisciplineQueryHookResult = ReturnType<
  typeof useGetStudentDisciplineQuery
>;
export type GetStudentDisciplineLazyQueryHookResult = ReturnType<
  typeof useGetStudentDisciplineLazyQuery
>;
export type GetStudentDisciplineQueryResult = Apollo.QueryResult<
  GetStudentDisciplineQuery,
  GetStudentDisciplineQueryVariables
>;
export const GetStudentHuntrMemberDocument = gql`
  query GetStudentHuntrMember($learnUuid: String!) {
    studentHuntrMember(learnUuid: $learnUuid) {
      externalMemberId
    }
  }
`;

/**
 * __useGetStudentHuntrMemberQuery__
 *
 * To run a query within a React component, call `useGetStudentHuntrMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentHuntrMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentHuntrMemberQuery({
 *   variables: {
 *      learnUuid: // value for 'learnUuid'
 *   },
 * });
 */
export function useGetStudentHuntrMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentHuntrMemberQuery,
    GetStudentHuntrMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentHuntrMemberQuery,
    GetStudentHuntrMemberQueryVariables
  >(GetStudentHuntrMemberDocument, options);
}
export function useGetStudentHuntrMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentHuntrMemberQuery,
    GetStudentHuntrMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentHuntrMemberQuery,
    GetStudentHuntrMemberQueryVariables
  >(GetStudentHuntrMemberDocument, options);
}
export type GetStudentHuntrMemberQueryHookResult = ReturnType<
  typeof useGetStudentHuntrMemberQuery
>;
export type GetStudentHuntrMemberLazyQueryHookResult = ReturnType<
  typeof useGetStudentHuntrMemberLazyQuery
>;
export type GetStudentHuntrMemberQueryResult = Apollo.QueryResult<
  GetStudentHuntrMemberQuery,
  GetStudentHuntrMemberQueryVariables
>;
export const GetStudentLightModeSettingDocument = gql`
  query GetStudentLightModeSetting {
    student: me {
      uuid
      profile {
        lightMode
      }
    }
  }
`;

/**
 * __useGetStudentLightModeSettingQuery__
 *
 * To run a query within a React component, call `useGetStudentLightModeSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentLightModeSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentLightModeSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentLightModeSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStudentLightModeSettingQuery,
    GetStudentLightModeSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentLightModeSettingQuery,
    GetStudentLightModeSettingQueryVariables
  >(GetStudentLightModeSettingDocument, options);
}
export function useGetStudentLightModeSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentLightModeSettingQuery,
    GetStudentLightModeSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentLightModeSettingQuery,
    GetStudentLightModeSettingQueryVariables
  >(GetStudentLightModeSettingDocument, options);
}
export type GetStudentLightModeSettingQueryHookResult = ReturnType<
  typeof useGetStudentLightModeSettingQuery
>;
export type GetStudentLightModeSettingLazyQueryHookResult = ReturnType<
  typeof useGetStudentLightModeSettingLazyQuery
>;
export type GetStudentLightModeSettingQueryResult = Apollo.QueryResult<
  GetStudentLightModeSettingQuery,
  GetStudentLightModeSettingQueryVariables
>;
export const GetStudentMilestonesDocument = gql`
  query GetStudentMilestones($uuid: ID!) {
    student(uuid: $uuid) {
      uuid
    }
  }
`;

/**
 * __useGetStudentMilestonesQuery__
 *
 * To run a query within a React component, call `useGetStudentMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentMilestonesQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetStudentMilestonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentMilestonesQuery,
    GetStudentMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentMilestonesQuery,
    GetStudentMilestonesQueryVariables
  >(GetStudentMilestonesDocument, options);
}
export function useGetStudentMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentMilestonesQuery,
    GetStudentMilestonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentMilestonesQuery,
    GetStudentMilestonesQueryVariables
  >(GetStudentMilestonesDocument, options);
}
export type GetStudentMilestonesQueryHookResult = ReturnType<
  typeof useGetStudentMilestonesQuery
>;
export type GetStudentMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetStudentMilestonesLazyQuery
>;
export type GetStudentMilestonesQueryResult = Apollo.QueryResult<
  GetStudentMilestonesQuery,
  GetStudentMilestonesQueryVariables
>;
export const GetStudentNotesDocument = gql`
  query GetStudentNotes($uuid: ID!) {
    student(uuid: $uuid) {
      uuid
      notes {
        ...NoteFragment
        contentText
        category {
          ...CategoryFragment
        }
      }
      legacyNotes {
        ...LegacyNoteFragment
      }
    }
  }
  ${NoteFragmentFragmentDoc}
  ${CategoryFragmentFragmentDoc}
  ${LegacyNoteFragmentFragmentDoc}
`;

/**
 * __useGetStudentNotesQuery__
 *
 * To run a query within a React component, call `useGetStudentNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentNotesQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetStudentNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentNotesQuery,
    GetStudentNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentNotesQuery, GetStudentNotesQueryVariables>(
    GetStudentNotesDocument,
    options
  );
}
export function useGetStudentNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentNotesQuery,
    GetStudentNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentNotesQuery,
    GetStudentNotesQueryVariables
  >(GetStudentNotesDocument, options);
}
export type GetStudentNotesQueryHookResult = ReturnType<
  typeof useGetStudentNotesQuery
>;
export type GetStudentNotesLazyQueryHookResult = ReturnType<
  typeof useGetStudentNotesLazyQuery
>;
export type GetStudentNotesQueryResult = Apollo.QueryResult<
  GetStudentNotesQuery,
  GetStudentNotesQueryVariables
>;
export const GetDocusignTemplateKindsDocument = gql`
  query GetDocusignTemplateKinds {
    docusignTemplateKinds {
      uuid
      name
      isReviewOnly
    }
  }
`;

/**
 * __useGetDocusignTemplateKindsQuery__
 *
 * To run a query within a React component, call `useGetDocusignTemplateKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocusignTemplateKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocusignTemplateKindsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocusignTemplateKindsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDocusignTemplateKindsQuery,
    GetDocusignTemplateKindsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDocusignTemplateKindsQuery,
    GetDocusignTemplateKindsQueryVariables
  >(GetDocusignTemplateKindsDocument, options);
}
export function useGetDocusignTemplateKindsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocusignTemplateKindsQuery,
    GetDocusignTemplateKindsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocusignTemplateKindsQuery,
    GetDocusignTemplateKindsQueryVariables
  >(GetDocusignTemplateKindsDocument, options);
}
export type GetDocusignTemplateKindsQueryHookResult = ReturnType<
  typeof useGetDocusignTemplateKindsQuery
>;
export type GetDocusignTemplateKindsLazyQueryHookResult = ReturnType<
  typeof useGetDocusignTemplateKindsLazyQuery
>;
export type GetDocusignTemplateKindsQueryResult = Apollo.QueryResult<
  GetDocusignTemplateKindsQuery,
  GetDocusignTemplateKindsQueryVariables
>;
