import { useState } from 'react';

import {
  useGetHeaderDataQuery,
  useGetHuntrMemberQuery,
} from '@fis/data-graphql';
import { lastEducatorWithAvailabilitySchedule } from '@fis/util-availability-schedule-url';
import { UiHeader } from '@fis/ui-header';

import { AppLogo } from './app-logo';

const huntrUrl = 'https://huntr.co/login';

export const FeatureHeader = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const handleHamburgerToggle = () => setShowMobileMenu(!showMobileMenu);
  const modeAlreadySet =
    window.localStorage.getItem('light_mode') ||
    window.localStorage.getItem('dark_mode');

  const { data, error } = useGetHeaderDataQuery({
    fetchPolicy: 'cache-first',
  });

  const { data: huntrMemberData, error: huntrMemberError } =
    useGetHuntrMemberQuery({
      fetchPolicy: 'cache-first',
    });

  const me = data?.student;
  const educator = lastEducatorWithAvailabilitySchedule(me?.educators);

  const huntr = huntrMemberData?.huntrMember && !huntrMemberError;
  const programStatusActiveOrAlumni = ['active', 'alumni'].includes(
    me?.programStatus
  );
  const productOfferings = me?.institution?.productOfferings?.nodes || [];

  const discordEnabled = productOfferings.some(
    ({ slug }) => slug === 'discord'
  );

  const chatUrl = me?.chatLink;

  const defaultLightMode = productOfferings.some(
    ({ slug }) => slug === 'light-mode-default'
  );

  if (!modeAlreadySet) {
    if (defaultLightMode) {
      window.localStorage.setItem('light_mode', 'lightMode');
      document.body.className = 'lightMode';
    } else {
      window.localStorage.setItem('dark_mode', 'darkMode');
    }
  }

  const getPrimaryNavLinks = () => {
    const links = [
      {
        title: 'Navigate to Home',
        path: '/',
        label: 'Home',
      },
      {
        title: 'Navigate to Event Schedule',
        path: '/events',
        label: 'Events',
      },
      {
        title: 'Navigate to Resource Library',
        path: '/resource-library',
        label: 'Library',
      },
    ];

    links.push({
      title: 'Navigate to Milestones',
      path: '/milestones',
      label: 'Milestones',
    });

    discordEnabled &&
      chatUrl &&
      links.push({
        title: 'Discord',
        path: chatUrl,
        label: 'Discord',
      });

    programStatusActiveOrAlumni &&
      huntr &&
      links.push({
        title: 'Huntr',
        path: huntrUrl,
        label: 'Huntr',
      });

    return links;
  };

  const getSecondaryNavLinks = () => {
    const links = [
      {
        title: 'Account',
        linkType: 'account',
        path: `/account`,
        label: 'account',
      },
    ];

    me &&
      !error &&
      educator?.availabilityScheduleUrl &&
      links.push({
        title: 'Schedule 1-1',
        linkType: 'schedule',
        path: `${educator.availabilityScheduleUrl}`,
        label: 'Schedule 1-1',
      });

    return links;
  };

  return (
    <UiHeader
      appLogo={<AppLogo />}
      user={me}
      primaryNavLinks={getPrimaryNavLinks()}
      secondaryNavLinks={getSecondaryNavLinks()}
      toggleOverlay={handleHamburgerToggle}
      showMobileMenu={showMobileMenu}
    />
  );
};
