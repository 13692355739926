import React from 'react';
import type * as Polymorphic from '@radix-ui/react-polymorphic';

import { styled } from '@fis/util-theme';
import ResponsiveGrid from '@fis/ui-responsive-grid';

/* -------------------------------------------------------------------------------------------------
 * Container
 * -----------------------------------------------------------------------------------------------*/

const Container = styled(ResponsiveGrid, {
  alignContent: 'start',
  display: 'grid',
  gap: '$12',
  paddingLeft: '$10',
  paddingRight: '$10',
  paddingBottom: '6rem',

  variants: {
    noGap: {
      true: {
        gap: '$0',
      },
    },
    contain: {
      true: {
        margin: '0 auto',
        maxWidth: '1080px',
        paddingLeft: '$9',
        paddingRight: '$9 !important',
        width: '100%',
      },
    },
    xl: {
      true: {
        margin: '0 auto',
        maxWidth: '1440px',
        paddingTop: '$12',
        width: '100%',
      },
    },
  },

  '@md': {
    paddingLeft: '$7',
    paddingRight: '$7',
  },
});

Container.displayName = 'Container';

/* -------------------------------------------------------------------------------------------------
 * Main
 * -----------------------------------------------------------------------------------------------*/

export const Main = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <Container as="main" id="main-content" ref={ref} {...rest}>
      {children}
    </Container>
  );
}) as Polymorphic.ForwardRefComponent<
  'main',
  Polymorphic.OwnProps<typeof Container>
>;

Main.displayName = 'Main';
