type InputType = string | null | undefined;

// formatAcronym(['Software', 'Engineering'], { limit: 1 });
export const formatAcronym = (input: Array<InputType>) => {
  if (!input) return null;

  return input
    .filter((v) => typeof v === 'string' && v.trim().length > 0)
    .map((v) => (v as string).trim()[0])
    .join('')
    .toUpperCase();
};

// formatInitials(['John', 'Doe']);
export const formatInitials = (firstName: InputType, lastName: InputType) => {
  return formatAcronym([firstName, lastName]);
};

// formatAcronyms(['Software Engineering, 'Data Science']) => ['SE', 'DS']
export const formatAcronyms = (input: Array<InputType>) => {
  if (!input || input.length === 0) return null;

  return input
    .filter((v) => typeof v === 'string' && v.trim().length > 0)
    .map((v) => formatAcronym((v as string).split(' ')));
};

// abbreviates Phases - ['Phase 1 - Javascript'] => 'phase 1'
const regexPhase = (input: InputType) => {
  if (!input) return null;

  return input.match(/[a-zA-Z]*.[0-9]+/i)!.join('');
};
export const formatPhases = (input: Array<InputType>) => {
  if (!input || input.length === 0) return null;

  return input
    .filter((v) => typeof v === 'string' && v.trim().length > 0)
    .map((v) => regexPhase(v as string))
    .join(', ');
};
