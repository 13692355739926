import { useState } from 'react';
import { CaretUp, CaretDown } from 'phosphor-react';

import { Avatar } from '@fis/ui-avatar';
import { styled } from '@fis/util-theme';
import * as Dropdown from '@radix-ui/react-dropdown-menu';

import { SecondaryNavLink } from '../types';
import { HeaderDropdown } from './ui-header-dropdown';

const ProfileMenuButton = styled(Dropdown.Trigger, {
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$2',
  transitionDuration: '150ms',
  cursor: 'pointer',
  background: '$tintDark8',
  padding: '$2 $5 $2 $2',
  gap: '$3',
  color: 'white',
  minWidth: 'max-content',
  maxHeight: 'max-content',
  fontFamily: '$firaSans',

  ['.lightMode &']: {
    color: '$gray800',
    background: '$tintLight8',
  },

  '&:focus': {
    background: '$tintDark16',
    boxShadow: '$focusRing',
    outline: 'none',
  },

  // sets focus style for webkit browsers
  '@supports selector(:focus-visible)': {
    // negates :focus rule for browsers that do not support :focus-visible selector
    '&:focus': {
      background: '$tintDark8',
      boxShadow: 'none',
    },

    '&:focus-visible': {
      background: '$tintDark16',
      boxShadow: '$focusRing',
      outline: 'none',

      ['.lightMode &']: {
        background: '$tintLight16',
      },
    },
  },

  '&:hover': {
    background: '$tintDark16',

    ['.lightMode &']: {
      background: '$tintLight16',
    },
  },

  variants: {
    menuVisible: {
      true: {
        background: 'white',
      },
    },
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

const Greeting = styled('span', {
  minWidth: 'max-content',
  fontSize: '$20',
});

type ProfileMenuProps = {
  user: {
    firstName?: string;
    lastName?: string;
    avatarUrl?: string;
  };
  dropdownMenuItems: SecondaryNavLink[];
};

export const ProfileMenu = (props: ProfileMenuProps) => {
  const { user, dropdownMenuItems } = props;

  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <Dropdown.Root>
      <ProfileMenuButton
        data-testid="profile-menu-button"
        onClick={() => toggleMenuVisibility()}
        menuVisible={menuVisible}
        hidden={{ '@initial': false, '@md': true }}
      >
        <Avatar
          size="small"
          avatarImageUrl={user?.avatarUrl}
          userFirstName={user?.firstName}
          userLastName={user?.lastName}
        />
        <Greeting>Hi, {user?.firstName}!</Greeting>
        {menuVisible ? <CaretUp weight="bold" /> : <CaretDown weight="bold" />}
      </ProfileMenuButton>
      <HeaderDropdown items={dropdownMenuItems} />
    </Dropdown.Root>
  );
};
