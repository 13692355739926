import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { styled, keyframes, theme } from '@fis/util-theme';

/* -----------------------------------------------------------
 * Usage:
 * ---------------------------------------------------------*/

// This is an extension of @radix-ui/react-dropdown-menu.
// More details on available options can be found here:

// https://radix-ui.com/primitives/docs/components/dropdown-menu

// (Note: Not all original components have been extended.)

// Example:

// <Dropdown.Root onOpenChange={handleOpenChange}>
//   <Dropdown.Trigger>[Trigger]</Dropdown.Trigger>
//
//   <Dropdown.Content align="end">
//     <Dropdown.Label>Loading...</Dropdown.Label>
//     <Dropdown.Item onSelect={() => {}}></Dropdown.Item>
//     <Dropdown.Item onSelect={() => {}}></Dropdown.Item>
//   </Dropdown.Content>
// </Dropdown.Root>

/* -----------------------------------------------------------
 * DropdownTrigger
 * ---------------------------------------------------------*/

const DropdownTrigger = styled(DropdownMenu.Trigger, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$tintDark8',
  border: 'none',
  borderRadius: '$2',
  color: '$gray300',
  gap: '$4',
  fontFamily: '$firaSans',
  fontSize: '$20',
  maxHeight: '35px', // ?
  minWidth: '170px',
  padding: '$3 $3',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  transitionDuration: '150ms',

  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '&:focus': {
    background: '$tintDark16',
    boxShadow: '$focusRing',
    color: 'white',
    outline: 'none',
  },

  // sets focus style for webkit browsers
  '@supports selector(:focus-visible)': {
    // negates :focus rule for browsers that do not support :focus-visible selector
    '&:focus': {
      background: '$tintDark8',
      boxShadow: 'none',
      color: '$gray300',
      outline: 'none',
    },

    '&:focus-visible': {
      background: '$tintDark16',
      boxShadow: '$focusRing',
      color: 'white',
      outline: 'none',
    },
  },

  '&:hover': {
    backgroundColor: '$tintDark16',
    color: 'white',
  },

  '&:active': {
    background: '$gray700',
  },

  '.lightMode &': {
    backgroundColor: '$tintLight8',
    color: '$gray700',

    '&:focus': {
      background: '$tintLight16',
      boxShadow: '$focusRing',
      color: '$lightblue700',
      outline: 'none',
    },

    // sets focus style for webkit browsers
    '@supports selector(:focus-visible)': {
      // negates :focus rule for browsers that do not support :focus-visible selector
      '&:focus': {
        background: '$tintLight8',
        boxShadow: 'none',
        color: '$lightblue700',
        outline: 'none',
      },

      '&:focus-visible': {
        background: '$tintLight16',
        boxShadow: '$focusRing',
        color: '$lightblue700',
        outline: 'none',
      },
    },

    '&:hover': {
      backgroundColor: '$tintLight16',
      color: '$lightblue700',
    },

    '&:active': {
      background: '$tintLight8',
    },
  },

  variants: {
    background: {
      dark: {
        backgroundColor: '$gray900',
        color: '$gray300',
      },
    },
    overflow: {
      full: {
        span: {
          overflow: 'visible',
        },
      },
    },
    selected: {
      true: {
        color: '$lightblue100',
        backgroundColor: '$lightblue900',
        boxShadow: `0 0 0 1px ${theme.colors.lightblue500}`,

        '&:focus': {
          boxShadow: '$focusRing',
          outline: 'none',
          backgroundColor: '$lightblue900',
        },

        '.lightMode &': {
          color: '$lightblue50',
          backgroundColor: '$lightblue700',
          boxShadow: `0 0 0 1px ${theme.colors.lightblue400}`,

          '&:focus': {
            boxShadow: '$focusRing',
            outline: 'none',
            backgroundColor: '$lightblue700',
          },
        },
      },
    },
  },
});

DropdownTrigger.displayName = 'Dropdown.Trigger';

/* -----------------------------------------------------------
 * DropdownContent
 * ---------------------------------------------------------*/

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const DropdownContent = styled(DropdownMenu.Content, {
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  backgroundColor: '$gray800',
  border: '1px solid $gray700',
  borderRadius: '$2',
  boxShadow: '0px 5px 15px -5px hsla(206,22%,7%,.15)',
  fontFamily: '$firaSans',
  marginTop: '$4',
  minWidth: '8.125rem',
  padding: '$2',

  '.lightMode &': {
    background: '$gray50',
    border: '1px solid $gray200',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    color: '$gray600',
  },

  '&[data-side="top"]': {
    animationName: slideUp,
  },

  '&[data-side="bottom"]': {
    animationName: slideDown,
  },
});

DropdownContent.displayName = 'Dropdown.Content';

/* -----------------------------------------------------------
 * DropdownItem
 * ---------------------------------------------------------*/

const DropdownCheckboxItem = styled(DropdownMenu.CheckboxItem, {
  borderRadius: '$1',
  cursor: 'pointer',
  fontSize: '$20',
  padding: '$3 $6',
});

DropdownCheckboxItem.displayName = 'Dropdown.CheckboxItem';

/* -----------------------------------------------------------
 * DropdownItem
 * ---------------------------------------------------------*/

const DropdownItem = styled(DropdownMenu.Item, {
  borderRadius: '$1',
  cursor: 'pointer',
  fontSize: '$20',
  padding: '$2 $3',
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
  minHeight: '$11',
  transitionDuration: '150ms',
  color: '$lightblue200',

  'option:checked': {
    background: 'red linear-gradient(0deg, red 0%, red 100%)',
  },

  '&:focus': {
    background: '$tintDark8',
    boxShadow: '$focusRing',
    outline: 'none',
  },

  // sets focus style for webkit browsers
  '@supports selector(:focus-visible)': {
    // negates :focus rule for browsers that do not support :focus-visible selector
    '&:focus': {
      background: 'transparent',
      boxShadow: 'none',
    },

    '&:focus-visible': {
      background: '$tintDark8',
      boxShadow: '$focusRing',
      outline: 'none',
    },
  },

  '&:hover': {
    background: '$tintDark8',
    boxShadow: 'none',
    color: '$lightblue50',
  },

  '.lightMode &': {
    color: '$gray600',

    '&:focus': {
      background: '$tintLight8',
      boxShadow: '$focusRing',
      outline: 'none',
    },

    // sets focus style for webkit browsers
    '@supports selector(:focus-visible)': {
      // negates :focus rule for browsers that do not support :focus-visible selector
      '&:focus': {
        background: 'transparent',
        boxShadow: 'none',
      },

      '&:focus-visible': {
        background: '$tintLight8',
        boxShadow: '$focusRing',
        outline: 'none',
      },
    },

    '&:hover': {
      background: '$tintLight8',
      boxShadow: 'none',
      color: '$lightblue700',
    },
  },
});

DropdownItem.displayName = 'Dropdown.Item';

/* -----------------------------------------------------------
 * DropdownLabel
 * ---------------------------------------------------------*/

const DropdownLabel = styled(DropdownMenu.Label, {
  fontSize: '$20',
  padding: '$3 $6',

  variants: {
    info: {
      true: {
        color: '$gray400',
      },
    },
  },
});

DropdownLabel.displayName = 'Dropdown.Label';

/* -----------------------------------------------------------
 * Dropdown
 * ---------------------------------------------------------*/

const Dropdown = DropdownMenu.Root;

Dropdown.displayName = 'Dropdown.Root';

/* -----------------------------------------------------------
 * DropdownAction
 * ---------------------------------------------------------*/

const DropdownAction = styled(DropdownMenu.Item, {
  borderRadius: '$1',
  color: '$gray400',
  cursor: 'pointer',
  fontSize: '$20',
  padding: '$3 $6',
  textAlign: 'center',

  '&:hover': {
    backgroundColor: '$gray900',
    outline: 'none',
  },
});

Dropdown.displayName = 'Dropdown.Action';

/* -----------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------*/

const Root = Dropdown;
const Content = DropdownContent;
const Item = DropdownItem;
const Label = DropdownLabel;
const Trigger = DropdownTrigger;
const Action = DropdownAction;
const CheckboxItem = DropdownCheckboxItem;

export {
  Dropdown,
  DropdownAction,
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownTrigger,
  DropdownCheckboxItem,
  //
  Root,
  Action,
  Content,
  Item,
  Label,
  Trigger,
  CheckboxItem,
};
