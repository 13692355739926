import { styled } from '@fis/util-theme';

import { PrimaryNavLink } from '../types';
import { NavLink } from './ui-nav-link';
import { useRouter } from 'next/router';

const NavLinkGroup = styled('div', {
  display: 'flex',
  gap: '$3',
  marginRight: 'auto',

  '@md': {
    display: 'none',
  },
});

const Gap = styled('div', {
  marginRight: '$3',
});

const PrimaryNavLinks = ({
  primaryNavLinks,
}: {
  primaryNavLinks: PrimaryNavLink[];
}) => {
  const { pathname } = useRouter();
  const seperatedIndex = primaryNavLinks.findIndex((l) =>
    l?.path?.startsWith('http')
  );

  return (
    <NavLinkGroup aria-label="Primary navigation links">
      {primaryNavLinks.map((primaryLink, index) => {
        return [
          index === seperatedIndex ? <Gap key="seperated-gap" /> : null,
          <NavLink
            key={primaryLink.label}
            path={primaryLink.path}
            title={primaryLink.title}
            label={primaryLink.label}
            external={primaryLink?.path?.startsWith('http')}
            isActive={pathname === primaryLink.path}
          />,
        ];
      })}
    </NavLinkGroup>
  );
};

export { PrimaryNavLinks };
