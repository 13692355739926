import { useState } from 'react';
import * as Dropdown from '@fis/ui-dropdown';
import { styled } from '@fis/util-theme';
import { handleLogout, LogOutMenuItem } from './ui-logout-menu-item';

import {
  ArrowRight,
  Briefcase,
  CalendarBlank, // Switch to CalendarPlus with Phosphor update
  User,
} from 'phosphor-react';

type HeaderDropdownProps = {
  visibility?: boolean;
  items: HeaderItemProps[];
};

type HeaderItemProps = {
  path?: string;
  title: string;
  label: string;
  linkType: string;
  handleLogout?: () => void;
  logout?: boolean;
  // ref?: ForwardedRef<HTMLAnchorElement>;
};

export const StyledSubMenuItem = styled('a', {
  display: 'flex',
  cursor: 'pointer',
  gap: '$5',
  alignItems: 'center',
  fontFamily: '$firaSans',
  fontSize: '$20',
  fontWeight: '$regular',
  color: '$gray300',
  textDecoration: 'none',
  padding: '$3 $2',
  width: '100%',
  transitionDuration: '150ms',
  borderRadius: '$1',

  '& svg': {
    display: 'flex',
    alignItems: 'center',
  },

  '.lightMode &': {
    color: '$gray600',
  },
});

// const HeaderLogout = React.forwardRef<HTMLAnchorElement, HeaderItemProps>(
//   // eslint-disable-next-line react/prop-types
//   ({ children, ...props }, forwardedRef) => (
//     <HeaderDropdownItem {...props} ref={forwardedRef} />
//   )
// );

const menuIcon = (linkType: string) => {
  switch (linkType) {
    case 'account':
      return <User size={20} weight="regular" />;
    case 'schedule':
      return <CalendarBlank size={20} weight="regular" />;
    case 'career':
      return <Briefcase size={20} weight="regular" />;
    default:
      return '';
  }
};

export const SubMenuItem = (props: HeaderItemProps) => {
  const { path, title, label, linkType } = props;

  return (
    <StyledSubMenuItem
      href={path}
      rel="noreferrer"
      data-testid={`item-path-${title}`}
      aria-label={label}
    >
      {menuIcon(linkType)}
      {title}
      {linkType === 'career' ? <ArrowRight size={20} /> : null}
    </StyledSubMenuItem>
  );
};

export const HeaderDropdownItem = (props: HeaderItemProps) => {
  const { path } = props;

  const handlePath = () => (path !== '/account' ? '_blank' : '_self');

  return (
    <Dropdown.Item onSelect={() => window.open(path, handlePath())}>
      <SubMenuItem {...props} />
    </Dropdown.Item>
  );
};

export const HeaderDropdown = ({ items }: HeaderDropdownProps) => {
  const [visible, setVisibility] = useState(false);

  const toggleVisibility = () => setVisibility(!visible);

  return (
    <Dropdown.Content
      onClick={toggleVisibility}
      data-testid="nav-dropdown"
      align="end"
    >
      {items
        ? items.map((item) => {
            return (
              <HeaderDropdownItem
                key={`submenu-item-${item.title}`}
                {...item}
              />
            );
          })
        : null}
      <Dropdown.Item onSelect={() => handleLogout()}>
        <LogOutMenuItem />
      </Dropdown.Item>
    </Dropdown.Content>
  );
};
