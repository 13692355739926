import React from 'react';
import Head from 'next/head';
import Link from 'next/link';

import { activeImpersonation } from '@fis/data-current-user';
import { styled } from '@fis/util-theme';
import ResponsiveGrid from '@fis/ui-responsive-grid';

/* -------------------------------------------------------------------------------------------------
 * SkipToContent TODO: Move?
 * -----------------------------------------------------------------------------------------------*/

const SkipToContent = styled('a', {
  background: '$white500',
  height: '2rem',
  left: '50%',
  padding: '$3',
  position: 'absolute',
  transform: 'translateY(-100%)',
  transition: 'transform 0.3s',

  '&:focus': {
    transform: 'translateY(0%)',
  },
});

/* -------------------------------------------------------------------------------------------------
 * Container
 * -----------------------------------------------------------------------------------------------*/

const Container = styled(ResponsiveGrid, {
  display: 'grid',
  gap: '$12',
  gridTemplateRows: 'auto 1fr auto',
  minHeight: '100vh',

  '&.lightMode': {
    background: 'white',
  },

  variants: {
    activeImpersonation: {
      true: {
        gridTemplateRows: 'auto auto 1fr auto',
      },
    },
  },
});

Container.displayName = 'Container';

/* -------------------------------------------------------------------------------------------------
 * Impersonation Banner
 * -----------------------------------------------------------------------------------------------*/

const ImpersonationBanner = styled('button', {
  backgroundColor: '$yellow500',
  border: 'none',
  color: '$gray900',
  cursor: 'pointer',
  fontFamily: '$firaCode',
  fontSize: '$20',
  padding: '$4 0',
  textAlign: 'center',
  width: '100%',
});

ImpersonationBanner.displayName = 'Container';

/* -------------------------------------------------------------------------------------------------
 * Layout
 * -----------------------------------------------------------------------------------------------*/

type Props = React.ComponentPropsWithRef<typeof Container> & {
  pageTitle: string;
};

export const Layout = React.forwardRef<HTMLDivElement, Props>(
  ({ children, pageTitle, ...rest }, ref) => {
    if (React.Children.toArray(children).length < 2) {
      throw new Error('Layout requires two children: header and main.');
    }

    const hasActiveImpersonation = activeImpersonation();

    return (
      <Container
        activeImpersonation={hasActiveImpersonation}
        ref={ref}
        {...rest}
      >
        <Head>
          <title>{pageTitle}</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <SkipToContent href="#main-content">Skip to content</SkipToContent>

        {hasActiveImpersonation ? (
          <Link href="/api/auth/impersonation/disable" prefetch={false}>
            <ImpersonationBanner>
              Impersonation mode. Click to disable.
            </ImpersonationBanner>
          </Link>
        ) : null}

        {children}
      </Container>
    );
  }
);

Layout.displayName = 'Layout';
