import Link from 'next/link';
import { ArrowLineUpRight } from 'phosphor-react';

import { styled } from '@fis/util-theme';

import { PrimaryNavLink } from '../types';

const HeaderNavLink = styled('button', {
  borderRadius: '$2',
  background: '$tintDark8',
  color: '$gray300',
  display: 'flex',
  fontSize: '$20',
  gap: '$3',
  padding: '$3 $5',
  textAlign: 'center',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  transitionDuration: '150ms',
  cursor: 'pointer',

  ['.lightMode &']: {
    color: '$gray600',
    background: '$tintLight8',
  },

  '@md': {
    justifyContent: 'center',
  },

  '&:focus': {
    boxShadow: '$focusRing',
    background: '$tintDark8',
    outline: 'none',

    ['.lightMode &']: {
      background: '$tintLight8',
    },
  },

  // sets focus style for webkit browsers
  '@supports selector(:focus-visible)': {
    // negates :focus rule for browsers that do not support :focus-visible selector
    '&:focus': {
      boxShadow: 'none',
    },

    '&:focus-visible': {
      background: '$tintDark16',
      boxShadow: '$focusRing',
      outline: 'none',

      ['.lightMode &']: {
        background: '$tintLight16',
      },
    },
  },

  '&:hover': {
    background: '$tintDark16',
    color: '$lightblue200',

    ['.lightMode &']: {
      color: '$blue600',
      background: '$tintLight16',
    },
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$blue800',
        color: '$lightblue100',

        '&:hover': {
          backgroundColor: '$blue800',
          color: '$lightblue100',
        },

        ['.lightMode &']: {
          backgroundColor: '$tintLight16',
          color: '$blue700',

          '&:hover': {
            backgroundColor: '$tintLight16',
            color: '$blue700',
          },
        },
      },
    },
  },
});

export function NavLink(props: PrimaryNavLink & { isActive?: boolean }) {
  const { isActive, label, path, title, external } = props;

  return external ? (
    <HeaderNavLink
      active={false}
      aria-current={null}
      title={title}
      as="a"
      href={path}
      target="_blank"
      rel="noreferrer"
    >
      {label}
      <ArrowLineUpRight />
    </HeaderNavLink>
  ) : (
    <Link href={path} passHref>
      <HeaderNavLink
        active={isActive}
        aria-current={isActive ? 'page' : null}
        title={title}
        as="a"
      >
        {label}
      </HeaderNavLink>
    </Link>
  );
}

export default NavLink;
